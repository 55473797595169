













































import { defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaBlogItem extends StoryblokComponent<'video-item'> {
  title: string
  categories: StoryblokComponent<any>[]
  author: StoryblokComponent<any>
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaBlogItem>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const nuxtContext = useContext()
    const classes = concatClasses(props.blok)
    const content = props.blok.content

    const getRealPath = (slug) => {
      if (nuxtContext.$config.storyblokRealPath) {
        return nuxtContext.store.getters['links/findBySlug'](slug)
      }
      return slug
    }

    return {
      classes,
      dashify,
      getRealPath,
      content
    }
  }
})
