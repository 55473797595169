































import { defineComponent, PropType, ref, useContext } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaColorSwitch extends StoryblokComponent<'color-switch'> {
  custom_classes: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaColorSwitch>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const currentColor = ref(nuxtContext.$colorMode.value)

    const switchColor = () => {
      nuxtContext.$colorMode.preference = nuxtContext.$colorMode.preference === 'dark' ? 'light' : 'dark'
      currentColor.value = nuxtContext.$colorMode.preference
    }
    if (nuxtContext.$colorMode.unknown) {
      currentColor.value = 'dark'
    }

    return {
      classes,
      dashify,
      currentColor,
      switchColor
    }
  }
})
