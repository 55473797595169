























































































































































































































































































































































































































































































































































































































































































































































import { defineComponent } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'

export default defineComponent({
  name: 'GaLandingpage',
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const { dashify } = helper()
    const backgroundUrl =
      'https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1267&amp;q=80'
    return {
      dashify,
      backgroundUrl
    }
  }
})
