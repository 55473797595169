












































import {
  defineComponent,
  PropType,
  ref,
  useContext,
  inject,
  reactive,
  onBeforeMount,
  useRoute,
  onMounted,
  useRouter
} from '@nuxtjs/composition-api'
import { formatDate } from '@/utils/date'
import useAppointments from '@/composables/useAppointments'
import AppointmentFilter from '@/components/calendar/Filter'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface Overview extends StoryblokComponent<'CoachingOverview'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
}

export default defineComponent({
  components: {
    AppointmentFilter
  },
  props: {
    blok: {
      type: Object as PropType<Overview>,
      default: () => {}
    }
  },
  setup() {
    const { $dayjs, $bus } = useContext()
    const { isCoach, rooms } = useAppointments()
    const userData = inject('userData')
    const calendar_id = userData.value?.user_coach_profile?.calendar_id
    const timezone = userData.value?.timezone
    const attributes = reactive([{ key: 'today', highlight: true }])
    const selectedDay = ref(null)
    const route = useRoute()
    const router = useRouter()

    const range = reactive({
      start: $dayjs().format(),
      end: $dayjs().add(30, 'day').format()
    })

    const modelConfig = {
      start: {
        timeAdjust: '00:00:00'
      },
      end: {
        timeAdjust: '23:59:59'
      }
    }

    const date = reactive({
      minDate: $dayjs().unix(),
      maxDate: $dayjs().add(1, 'month').unix()
    })

    onBeforeMount(() => {
      $bus.emit('refetchStore', 'appointments')
      $bus.emit('refetchStore', 'appointmentRooms')
    })

    onMounted(() => {
      router.push({
        path: route.value.path,
        query: {
          coach: calendar_id,
          minDate: $dayjs().unix(),
          maxDate: $dayjs().add(1, 'month').unix()
        }
      })
    })

    function dayClicked(event) {
      const { start, end } = event
      date.minDate = $dayjs(start).unix()
      date.maxDate = $dayjs(end).unix()
      attributes[0].highlight = false
      router.push({
        path: route.value.path,
        query: {
          coach: calendar_id,
          type: route.value.query.type,
          language: route.value.query.language,
          minDate: date.minDate,
          maxDate: date.maxDate
        }
      })
    }

    return {
      dayClicked,
      selectedDay,
      formatDate,
      isCoach,
      timezone,
      rooms,
      attributes,
      range,
      modelConfig
    }
  }
})
