











import { defineComponent, PropType, ref, useContext } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaReloadTriggerEffect extends StoryblokComponent<'reload-trigger-effect'> {
  content: StoryblokComponent<any>[]
  bus_event: string
  hard_reload: boolean
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaReloadTriggerEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { dashify } = useHelper()
    const nuxtContext = useContext()
    const key = ref(0)
    const visible = ref(true)

    if (props.blok.bus_event) {
      nuxtContext.$bus.on(props.blok.bus_event, () => {
        if (props.blok.hard_reload) {
          visible.value = false
          setTimeout(() => (visible.value = true), 100)
        } else {
          key.value = key.value + 1
        }
      })
    }

    return {
      dashify,
      key,
      visible
    }
  }
})
