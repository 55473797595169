import { Model } from '@vuex-orm/core'
import dayjs from 'dayjs'
import Appointment from './Appointment'
import AppointmentType from '~/models/AppointmentType'
import Calendar from '~/models/Calendar'
import GameRank from '~/models/GameRank'
// import Game from '~/models/Game'
import User from '~/models/User'

export default class AppointmentRoom extends Model {
  static entity = 'appointmentRooms'

  static fields() {
    return {
      id: this.uid(),
      appointmentTypeID: this.attr(null),
      calendarID: this.attr(null),
      user_id: this.attr(null),
      name: this.string(''),
      heading: this.attr(null),
      language: this.string(''),
      calendar: this.string(''),
      topic: this.string('').nullable(),
      photo_url: this.string('').nullable(),
      min_game_rank_id: this.attr(null),
      max_game_rank_id: this.attr(null),
      price: this.number(0),
      duration: this.number(0),
      canceled: this.boolean(false),
      location: this.string(''),
      time: this.string(''),
      bookable_till: this.string(''),
      starts_at: this.string(''),
      ends_at: this.string(''),
      slots: this.number(0),
      calendarTimezone: this.string(''),
      slotsAvailable: this.number(0),
      appointments: this.hasMany(Appointment, 'classID'),
      appointmentType: this.belongsTo(AppointmentType, 'appointmentTypeID'),
      calendarObject: this.belongsTo(Calendar, 'calendarID'),
      minRank: this.belongsTo(GameRank, 'min_game_rank_id'),
      maxRank: this.belongsTo(GameRank, 'max_game_rank_id'),
      owner: this.belongsTo(User, 'user_id')
    }
  }

  id!: number
  appointmentTypeID!: number
  time!: string
  name!: string
  heading!: string | null
  calendar!: string
  calendarID!: number
  starts_at!: string
  ends_at!: string
  location!: string
  photo_url!: string | null
  topic!: string | null
  minRank!: GameRank | null
  maxRank!: GameRank | null
  user_id!: number
  owner!: User | null
  appointmentType!: AppointmentType | null
  appointments!: Appointment[] | []
  slotsAvailable!: number

  get timestamp() {
    return dayjs(this.time).unix()
  }

  get photo() {
    return this.owner?.user_coach_profile?.photo_url
  }

  get day() {
    return dayjs(this.time).daysInMonth()
  }

  get date() {
    return dayjs(this.time).toDate().toDateString()
  }

  get zoomLink() {
    return this.location.split('\n')[0]
  }
}
