






import { defineComponent } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'

export default defineComponent({
  name: 'GaRichTextComponentWrapper',
  props: {
    body: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const { dashify } = helper()

    return {
      dashify
    }
  }
})
