<template>
  <client-only>
    <svg
      v-if="svgIcon && svgIcon.attributes"
      v-bind="svgIcon.attributes"
      :class="[
        (blok && blok.size) || null,
        svgIcon.attributes.class,
        {
          'fa-spin': spin,
          'fa-pulse': pulse,
          'fa-fw': fixedWidth,
          'fa-border': border,
          'fa-li': listItem,
          'fa-inverse': inverse,
          'fa-flip-horizontal': flip === 'horizontal' || flip === 'both',
          'fa-flip-vertical': flip === 'vertical' || flip === 'both' || flipVertical,
          [`fa-${size}`]: size !== null,
          [`fa-rotate-${rotation}`]: rotation !== null,
          [`fa-pull-${pull}`]: pull !== null,
          'fa-swap-opacity': swapOpacity
        }
      ]"
    >
      <component :is="child.tag" v-for="(child, index) in svgIcon.children" :key="index" v-bind="child.attributes">
        <component
          :is="child2.tag"
          v-for="(child2, index2) in child.children"
          :key="index + index2"
          v-bind="child2.attributes"
        >
          <component
            :is="child3.tag"
            v-for="(child3, index3) in child2.children"
            :key="index + index + index3"
            v-bind="child3.attributes"
          />
        </component>
      </component>
    </svg>
    <span v-else></span>
  </client-only>
</template>

<script>
import { defineComponent, ref, useAsync, useContext, useStore } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'FontAwesome',
  props: {
    border: {
      type: Boolean,
      default: false
    },
    fixedWidth: {
      type: Boolean,
      default: false
    },
    flip: {
      type: String,
      default: null,
      validator: (value) => ['horizontal', 'vertical', 'both'].includes(value)
    },
    flipVertical: {
      type: Boolean,
      default: false
    },
    icon: {
      type: [Object, Array, String],
      required: false,
      default: () => []
    },
    mask: {
      type: [Object, Array, String],
      default: null
    },
    listItem: {
      type: Boolean,
      default: false
    },
    pull: {
      type: String,
      default: null,
      validator: (value) => ['right', 'left'].includes(value)
    },
    pulse: {
      type: Boolean,
      default: false
    },
    rotation: {
      type: [String, Number],
      default: null,
      validator: (value) => [0, 90, 180, 270].includes(parseInt(value, 10))
    },
    swapOpacity: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null,
      validator: (value) =>
        ['lg', 'xs', 'sm', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'].includes(value)
    },
    spin: {
      type: Boolean,
      default: false
    },
    transform: {
      type: [String, Object],
      default: null
    },
    symbol: {
      type: [Boolean, String],
      default: false
    },
    title: {
      type: String,
      default: null
    },
    inverse: {
      type: Boolean,
      default: false
    },
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const nuxtContext = useContext()
    const icon = ref(props.icon)
    const store = useStore()

    if (props.blok) {
      icon.value = [props.blok.type, props.blok.icon]
    } else if (typeof icon.value === 'string') {
      icon.value = ['far', icon.value]
    }
    const iconId = icon.value[0] + icon.value[1]

    const findIcon = store.getters['icons/find'](iconId)
    const fetchIcon = async () => {
      return await nuxtContext.$axios
        .get(`${nuxtContext.$config.api}/api/fontAwesome`, {
          params: {
            prefix: icon.value[0],
            iconName: icon.value[1]
          }
        })
        .then((fa) => {
          store.dispatch('icons/insert', {
            id: iconId,
            data: fa.data
          })
          return fa.data
        })
        .catch((error) => {
          console.error(error)
          return {}
        })
    }

    const svgIcon = findIcon ? findIcon.data : useAsync(fetchIcon, iconId)

    return {
      svgIcon
    }
  }
})
</script>
