import dayjs from 'dayjs'
import _ from 'lodash'

const placeholderPipes = {
  _fallback: (rawValue: any) => rawValue,
  date: (rawValue: any) => {
    return rawValue ? dayjs(rawValue).format('MM-DD-YYYY') : ''
  },
  datetime: (rawValue: any) => {
    return rawValue ? dayjs(rawValue).format('MM-DD-YYYY HH:mm') : ''
  },
  time: (rawValue: any) => {
    return rawValue ? dayjs(rawValue).format('HH:mm') : ''
  },
  extractZoomUrl: (rawValue: any) => {
    const result = /(?:https:\/\/zoom.)[\S]*/.exec(rawValue)
    return result !== null ? result[0] : ''
  },
  whitespaceToUnderscore: (rawValue: any) => {
    return rawValue ? rawValue.replace(' ', '_') : ''
  }
}

export function getPlaceholderPipeRegexKeyChain() {
  return Object.keys(placeholderPipes).join('|')
}

export function getPlaceholderPipe(key: string) {
  const pipe = _.find(Object.keys(placeholderPipes), (placeholder: string) =>
    new RegExp(`.*\\|${placeholder}%`, 'i').test(key)
  )
  return pipe !== undefined ? _.get(placeholderPipes, pipe) : placeholderPipes._fallback
}
