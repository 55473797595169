







import { computed, defineComponent, PropType, provide, ref, useAsync, useContext } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import useStoryblok from '@/utils/storyblok'
import { NavigationMobile } from '~/components/navigation/NavigationMobile.vue'
import { NavigationDesktop } from '~/components/navigation/NavigationDesktop.vue'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface Navigation extends StoryblokComponent<'BlokNavigation'> {
  custom_classes: string
  mobile: NavigationMobile[]
  desktop: NavigationDesktop[]
  menu: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<Navigation>,
      default: () => {}
    }
  },
  setup(props) {
    const { dashify, checkScopes } = useHelper()
    const { getStory } = useStoryblok()
    const nuxtContext = useContext()
    const { mobile, desktop } = props.blok
    const mobileComponent = mobile?.[0]
    const desktopComponent = desktop?.[0]

    const getNavigationItems = async () => {
      if (nuxtContext.payload) {
        const menuItemStory = nuxtContext.payload.menuItemStories.find(
          (menuItemStory) => menuItemStory.uuid === props.blok.menu
        )
        return menuItemStory ? menuItemStory.content.children : null
      }
      return await getStory({ path: props.blok.menu, context: nuxtContext, isUuid: true }).then((story) =>
        story ? story.content.children : null
      )
    }
    const navigationItems = props.blok.menu ? useAsync(getNavigationItems, props.blok.menu) : ref([])
    const checkedNavigationItem = computed(() => {
      return (
        navigationItems.value?.filter((item: any) =>
          checkScopes(nuxtContext, [
            ...(item.auth || []),
            ...(item.plan_features || []),
            ...(item.game_accounts || []),
            ...(item.is_coach ? [item.is_coach] : [])
          ])
        ) || []
      )
    })
    provide('navigationItems', checkedNavigationItem)

    const getUserMenuItems = async () => {
      if (nuxtContext.payload) {
        const menuItemStory = nuxtContext.payload.menuItemStories.find(
          (menuItemStory) => menuItemStory.uuid === props.blok.menu_user
        )
        return menuItemStory ? menuItemStory.content.children : null
      }
      return await getStory({ path: props.blok.menu_user, context: nuxtContext, isUuid: true }).then((story) =>
        story ? story.content.children : null
      )
    }
    const userMenuItems = props.blok.menu_user ? useAsync(getUserMenuItems, props.blok.menu_user) : ref([])
    const checkedUserItems = computed(() => {
      return (
        userMenuItems.value?.filter((item: any) =>
          checkScopes(nuxtContext, [
            ...(item.auth || []),
            ...(item.plan_features || []),
            ...(item.game_accounts || []),
            ...(item.is_coach ? [item.is_coach] : [])
          ])
        ) || []
      )
    })

    provide('userMenuItems', checkedUserItems)

    const mobileMenuOpen = ref(false)
    provide('mobileMenuOpen', mobileMenuOpen)

    return {
      dashify,
      mobileComponent,
      desktopComponent
    }
  }
})
