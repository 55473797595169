/* eslint-disable */
import useGetters from '@/utils/getters.ts'

export const state = () => ({
  fa: []
})

export const mutations = {
  insert(state, icon) {
    state.fa.push(icon)
  }
}

export const actions = {
  insert({ commit, getters }, data) {
    if(!getters['find'](data.id)) {
      commit('insert', data)
    }
  },
}

export const getters = useGetters('fa')
