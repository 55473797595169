import { computed, inject, ref, useContext, useRoute, watch } from '@nuxtjs/composition-api'
import AppointmentRoom from '@/models/AppointmentRoom'
import AppointmentsModel from '@/models/Appointment'

const useAppointments = () => {
  const { store, $dayjs, $axios, payload } = useContext()
  const route = useRoute()
  const isBusy = ref(false)
  const userData = inject('userData')
  const calendar_id = userData.value?.user_coach_profile?.calendar_id || ''
  const isCoach = userData.value?.is_coach
  const calendarID = ref(null)
  const coachingType = ref(null)
  const language = ref(null)
  const perPage = ref(null)
  const page = ref(null)

  const loadAppointmentRooms = async (day, includeUnavailable = false) => {
    if (payload && payload.store) {
      return
    }
    isBusy.value = true
    let params
    if (typeof day === 'object' && day !== null) {
      params = Object.assign({}, { params: { ...day, includeUnavailable } })
    } else {
      params = Object.assign({}, { params: { month: $dayjs().format('YYYY-MM'), includeUnavailable } })
    }

    await $axios
      .$get(`/api/booking/availability/classes`, params)
      .then((response) => {
        store.$repo(AppointmentRoom).save(response.data.values)
        isBusy.value = false
      })
      .catch((e) => console.error(e))
  }

  const loadAppointments = async () => {
    if (payload && payload.store) {
      return
    }
    await $axios
      .$get(`/api/booking/appointments`, { params: { calendarID: calendar_id } })
      .then((response) => store.$repo(AppointmentsModel).save(response.data.values))
      .catch((e) => console.error(e))
  }

  const rooms = computed(() => {
    const rooms = store
      .$repo(AppointmentRoom)
      .with('appointments')
      .with('appointmentType')
      .with('minRank')
      .with('maxRank')
      .with('owner', (query) => {
        query.with('user_coach_profile', (query) => {
          query.with('languages')
        })
      })
      .where('time', (value) => {
        return (
          $dayjs(value).unix() >= parseInt(route.value.query.minDate) &&
          $dayjs(value).unix() < parseInt(route.value.query.maxDate)
        )
      })
      .orderBy((appointmentRoom) => $dayjs(appointmentRoom.time).unix())
    if (calendarID.value !== null) {
      rooms.where('calendarID', (value) => value === parseInt(calendarID.value)).get()
    }
    if (coachingType.value !== null) {
      rooms.where('name', (value) => value === coachingType.value).get()
    }
    if (language.value !== null) {
      rooms.where('language', (value) => value === language.value).get()
    }
    if (perPage.value !== null) {
      rooms
        .offset(page.value * perPage.value)
        .limit(perPage.value)
        .get()
    }
    return rooms.get()
  })

  watch(
    () => rooms,
    (val) => {
      return val
    }
  )

  watch(
    () => route.value.query,
    (val) => {
      calendarID.value = val.coach || null
      coachingType.value = val.type || null
      language.value = val.language || null
      perPage.value = parseInt(val.perPage) || null
      page.value = parseInt(val.page) || null
    },
    {
      deep: true
    }
  )

  return {
    rooms,
    loadAppointments,
    loadAppointmentRooms,
    isBusy,
    isCoach
  }
}

export default useAppointments
