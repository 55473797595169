
























import { defineComponent, inject, PropType } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import { Link } from '~/components/link/Link.vue'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface NavigationMobile extends StoryblokComponent<'navigation-mobile'> {
  custom_classes: string
  link_style: Link[]
  content_down: StoryblokComponent<any>[]
  content_user_icon: StoryblokComponent<any>[]
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<NavigationMobile>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)

    const menuIsOpen = inject('mobileMenuOpen', false)

    return {
      dashify,
      classes,
      menuIsOpen
    }
  }
})
