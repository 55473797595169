

























import { toRefs, watch, defineComponent, ref, onMounted, useRoute } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    item: { type: Object, default: () => {} },
    showProperty: { type: String, default: () => '' },
    valueProperty: { type: String, default: () => '' },
    queryParameter: { type: String, default: () => '' }
  },
  setup(props) {
    const { item, valueProperty, queryParameter } = toRefs(props)
    const query = ref([])
    const route = useRoute()
    const status = ref(false)

    onMounted(() => {
      checkStatus()
    })

    const checkStatus = () => {
      query.value = route.value.query
      if (queryParameter.value in query.value && valueProperty.value in item.value) {
        const dataString = query.value[queryParameter.value]
        const valueToCheck = item.value[valueProperty.value]
        let dataArray: any[] = []

        if (typeof dataString === 'string') {
          dataArray = dataString.split(',')
        }

        status.value = dataArray.includes(valueToCheck.toString())
      }
    }

    watch(
      () => route.value.query,
      (val) => {
        checkStatus()
        return val
      },
      {
        deep: true
      }
    )

    return {
      checkStatus,
      status
    }
  }
})
