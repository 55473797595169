







































































































import { defineComponent, useRoute, useRouter, ref, useContext } from '@nuxtjs/composition-api'
import Checkbox from '~/components/calendar/Filter/Checkbox.vue'
import Tag from '~/models/Tag'
import User from '~/models/User'
import FilterList from '~/components/calendar/Filter/TagList.vue'
import CoachList from '~/components/calendar/Filter/CoachList.vue'
import useFilters from '~/composables/useFilters'

export default defineComponent({
  components: { CoachList, FilterList, Checkbox },
  props: {
    types: { type: Array, default: () => ['Focus', 'Skilllevel'] }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const isToggled = ref<Boolean>(false)
    const { activeCalendars, activeTags, activeCoaches, query } = useFilters()
    const { i18n } = useContext()

    const resetFilter = () => {
      router.push({
        path: route.value.path,
        query: {
          coaches: null,
          type: route.value.query.type,
          tags: null,
          minDate: route.value.query.minDate,
          maxDate: route.value.query.maxDate,
          game: route.value.query.game
        }
      })
    }

    const select = (selected: Tag) => {
      const allTags = activeTags.value.map((tag: Tag) => tag.id)

      if (allTags.includes(selected.id)) {
        const index = allTags.indexOf(selected.id)
        if (index > -1) {
          allTags.splice(index, 1)
        }
      }

      query.tags = allTags.length > 0 ? allTags.join() : null

      router.push({
        path: route.value.path,
        query
      })
    }

    const selectCoach = (selected: User) => {
      const allCoaches = activeCoaches.value.map((user: User) => user.id)

      if (allCoaches.includes(selected.id)) {
        const index = allCoaches.indexOf(selected.id)
        if (index > -1) {
          allCoaches.splice(index, 1)
        }
      }

      query.coaches = allCoaches.length > 0 ? allCoaches.join() : null

      router.push({
        path: route.value.path,
        query
      })
    }

    return {
      i18n,
      activeTags,
      activeCalendars,
      resetFilter,
      select,
      selectCoach,
      isToggled
    }
  }
})
