var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"},{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"fixed z-40 inset-0 overflow-y-auto",attrs:{"aria-labelledby":"modal-title","role":"dialog","aria-modal":"true"}},[_c('div',{staticClass:"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"},[_c('transition',{attrs:{"enter":"ease-out duration-300","enter-from":"opacity-0","enter-to":"opacity-100","leave":"ease-in duration-200","leave-from":"opacity-100","leave-to":"opacity-0"}},[(_vm.open)?_c('div',{staticClass:"fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",attrs:{"aria-hidden":"true"}}):_vm._e()]),_vm._v(" "),(!_vm.blokCustom.full_size)?_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"enter":"ease-out duration-300","enter-from":"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95","enter-to":"opacity-100 translate-y-0 sm:scale-100","leave":"ease-in duration-200","leave-from":"opacity-100 translate-y-0 sm:scale-100","leave-to":"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"}},[(_vm.open)?_c('div',{ref:"modal",staticClass:"inline-block align-bottom text-left transform transition-all sm:p-6 sm:align-middle w-full",class:[
          _vm.blokCustom.modal_classes,
          _vm.blokCustom.content ? _vm.blokCustom.content.custom_classes : '',
          {
            'bg-white dark:bg-gray-800 overflow-hidden':
              !_vm.blokCustom.modal_classes && !(_vm.blokCustom.content && _vm.blokCustom.content.custom_classes)
          },
          { 'shadow-xl sm:my-8 sm:max-w-xl px-4 pt-5 pb-4': !_vm.blokCustom.full_size }
        ]},[(!_vm.blokCustom.without_close_button)?_c('div',{staticClass:"absolute top-0 right-0 z-50"},[_c('button',{staticClass:"p-2 text-gray-400 dark:bg-gray-700 hover:text-gray-300",attrs:{"type":"button"},on:{"click":_vm.close}},[_c('span',{staticClass:"sr-only"},[_vm._v("Close")]),_vm._v(" "),_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])])]):_vm._e(),_vm._v(" "),_vm._l((_vm.innerContent),function(blokChild){return _c('component',_vm._b({key:blokChild._uid},'component',_vm.generateItemAttributes(blokChild) || { is: 'div' },false))}),_vm._v(" "),_vm._t("default")],2):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }