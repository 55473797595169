




import { defineComponent, inject, PropType, ref, unref, useContext } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaMultigameRedirectEffect extends StoryblokComponent<'MultigameRedirectEffect'> {
  paths: StoryblokComponent<any>[]
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaMultigameRedirectEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { checkScopes } = useHelper()
    const nuxtContext = useContext()
    const userData = inject('userData')
    const isStoryblokEdit = inject('storyblokEdit', ref(false))

    if (isStoryblokEdit.value) {
      return {}
    }

    const from = unref(nuxtContext.from)?.fullPath ?? ''
    let redirected = false

    // Redirect game which matches to path
    props.blok.paths.forEach((p) => {
      if (from.length > 0) {
        if (from.includes(p.urlPathContains)) {
          nuxtContext.redirect('/' + p.url.cached_url)
          redirected = true
        }
      }
    })

    // Redirect to last game booked
    if (userData.value && userData.value.latest_game)
      props.blok.paths.forEach((p) => {
        if (p.gameAccount === userData.value.latest_game.name) {
          nuxtContext.redirect('/' + p.url.cached_url)
          redirected = true
        }
      })

    // Path not matched; Redirect to first found game account
    if (!redirected) {
      props.blok.paths.forEach((p) => {
        const hasGameAccount = checkScopes(nuxtContext, [`gameAccount:${p.gameAccount}`])

        if (hasGameAccount) {
          nuxtContext.redirect('/' + p.url.cached_url)
          redirected = true
        }
      })
    }

    // No game accounts; Redirect to first path in list
    if (!redirected) {
      props.blok.paths.forEach((p) => {
        nuxtContext.redirect('/' + p.url.cached_url)
      })
    }

    return {}
  }
})
