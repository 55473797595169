




import { defineComponent, inject, PropType, ref, unref, useContext, useRoute, useRouter } from '@nuxtjs/composition-api'
import { promiseTimeout } from '@vueuse/core'
import { StoryblokComponent, StoryblokLink } from '~/types/storyblok-vue'

export interface GaRedirectEffect extends StoryblokComponent<'redirect-effect'> {
  redirect_to: StoryblokLink
  timer: number
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaRedirectEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const storyblokEdit = inject('storyblokEdit', ref(false))
    const redirectTo = props.blok.redirect_to
    const timer = props.blok.timer
    const router = useRouter()
    const route = useRoute()
    const nuxtContext = useContext()

    const redirect = async () => {
      await promiseTimeout(timer * 1000)
      if (redirectTo.linktype === 'url') {
        try {
          nuxtContext.redirect(redirectTo.cached_url)
        } catch (error) {
          if (error.message === 'ERR_REDIRECT') {
            return context.emit('routeChanged', redirectTo.cached_url, '', error)
          }
        }
      } else {
        let path = '/' + redirectTo.cached_url
        const appendQueryParams = props.blok.append_query_params || false
        if (appendQueryParams) {
          path = path + '?' + appendQueryParams
        }
        router.push({ path, query: route.value.query })
      }
    }

    if (redirectTo && timer) {
      if (!unref(storyblokEdit) && !process.server) {
        redirect()
      }
    }

    return {}
  }
})
