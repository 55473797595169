




import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  }
})
