










import { defineComponent, useContext, unref, inject, PropType } from '@nuxtjs/composition-api'
import { useLocalStorage } from '@vueuse/core'
import get from 'get-value'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useTemplates from '~/utils/useTemplates'

export interface GaReplaceData extends StoryblokComponent<'replace-data-effect'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  item_name: string
  placeholder_prefix: string
  by_prop: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaReplaceData>,
      default: () => {}
    }
  },
  setup(props) {
    const { $dayjs } = useContext()
    const { concatClasses, dashify, replacePlaceholder } = useHelper()
    const { generateItemAttributes } = useTemplates()
    const classes = concatClasses(props.blok)
    const item = inject(props.blok.item_name ? props.blok.item_name : 'item', {})
    const currency = useLocalStorage('currency', 'EUR')

    const generateComponentItemAttributes = (component_style) => {
      const placeholder = [
        {
          key: /%item_appointment_datetime%/,
          value: $dayjs(item.value?.booking_attributes.time).format('DD/MM/YYYY')
        },
        {
          key: /%item_appointment_time%/,
          value: $dayjs(item.value?.booking_attributes.time).format('HH:mm')
        },
        {
          key: /%item_user_first_last_name%/,
          value: item.value?.user.name + ' ' + (item.value?.user.last_name === null ? '' : item.value?.user.last_name)
        }
      ]
      return generateItemAttributes(
        {
          ...(props.blok.by_prop ? get(unref(item), props.blok.by_prop) : unref(item)),
          geo_currency: currency.value === 'EUR' ? '€' : '$'
        },
        { ...replacePlaceholder(component_style, placeholder) },
        props.blok.placeholder_prefix ? props.blok.placeholder_prefix : 'item'
      )
    }

    return {
      classes,
      dashify,
      generateComponentItemAttributes
    }
  }
})
