import {
  computed,
  inject,
  ref,
  useContext,
  useRoute,
  watch,
  ComputedRef,
  unref,
  reactive,
  toRefs
} from '@nuxtjs/composition-api'
import dayjs from 'dayjs'
import AppointmentRoom from '@/models/AppointmentRoom'
import Tag from '@/models/Tag'
import User from '~/models/User'
import Game from '~/models/Game'
import Appointment from '~/models/Appointment'

type dateObject = {
  [key: string]: AppointmentRoom[] | undefined
}

type CalendarObject = {
  id: number
  name: string
}

type dateObjectArray = {
  [key: string]: dateObject | undefined
}

const useAppointments = () => {
  const { store, $dayjs } = useContext()
  const route = useRoute()
  const isBusy = ref(false)
  const userData: ComputedRef<User> | undefined = inject('userData')
  const user = unref(userData)
  const isCoach = user ? user.is_coach : false
  const coachingType = ref<string | null>(null)
  const gameName = ref<string | null>(null)
  const filterTags = ref<string[]>([])
  const filterCoaches = ref<string[]>([])
  const minDate = ref<Date | string>(dayjs().toDate())
  const maxDate = ref<Date | string>(dayjs().add(7, 'days').toDate())
  let gameSlug = route.value.fullPath.substring(
    route.value.fullPath.lastIndexOf('/booking/') + 9,
    route.value.fullPath.lastIndexOf('/calendar')
  )

  switch (gameSlug) {
    case 'leagueoflegends':
      gameSlug = 'league-of-legends'
      break
    case 'rocketleague':
      gameSlug = 'rocket-league'
      break
    default:
      break
  }

  const queryParams = reactive({
    coaches: route.value.query.coaches || null,
    type: route.value.query.type || [],
    tags: route.value.query.tags || null,
    minDate: dayjs().unix().toString(),
    maxDate: dayjs().add(7, 'days').unix().toString(),
    game: route.value.query.game || gameSlug
  })

  const activeTags = computed(() => {
    return store
      .$repo(Tag)
      .where('id', (value: Number) => {
        return filterTags.value.includes(value.toString())
      })
      .get()
  })

  const activeCoaches = computed(() => {
    return store
      .$repo(User)
      .where('id', (value: Number) => {
        return filterCoaches.value.includes(value.toString())
      })
      .get()
  })

  const tags = computed(() => {
    return store
      .$repo(Tag)
      .where('id', (value: number) => {
        return rooms.value
          .map((room: AppointmentRoom) => room.owner?.user_coach_profile.tag_ids)
          .flat(1)
          .includes(value)
      })
      .orderBy('order_column', 'asc')
      .get()
  })

  const activeTagsPerType = computed(() => {
    const tagMap: Map<string, Array<number>> = new Map<string, Array<number>>()
    const types = [...new Set<string>(activeTags.value.map((tag: Tag) => tag.type))]

    types.forEach((type: string) => {
      const activeTypeTags = activeTags.value.filter((tag: Tag) => {
        return tag.type === type
      })
      tagMap.set(
        type,
        activeTypeTags.map((tag: Tag) => tag.id)
      )
    })

    return tagMap
  })

  const games = computed(() => {
    return store.$repo(Game).all()
  })

  console.log(games)

  const coaches = computed(() => {
    const owners: Array<User> = []

    rooms.value.forEach((room: AppointmentRoom) => {
      if (room.owner !== null) {
        owners.push(room.owner)
      }
    })

    return [...new Set<User>(owners)]
  })

  const calendars = computed(() => {
    const calendar_names: Array<CalendarObject> = []
    const coach_ids: Array<Number> = []

    rooms.value.forEach((room: AppointmentRoom) => {
      if (room.calendar !== null && room.calendarID !== null && room.user_id !== null) {
        if (!coach_ids.includes(room.user_id)) {
          coach_ids.push(room.user_id)
          calendar_names.push({
            id: room.user_id,
            name: room.calendar
          })
        }
      }
    })
    return [...new Set<CalendarObject>(calendar_names)]
  })

  const activeCalendars = computed(() => {
    return calendars.value.filter((calendar: CalendarObject) => {
      return filterCoaches.value.includes(calendar.id.toString())
    })
  })

  const rooms = computed(() => {
    const rooms = store
      .$repo(AppointmentRoom)
      .with('appointments')
      .with('appointmentType', (query) => {
        query.with('game')
      })
      .with('minRank')
      .with('maxRank')
      .with('owner', (query) => {
        query.with('user_coach_profile', (query) => {
          query.with('tags')
        })
      })
      .where('time', (value: number) => {
        return dayjs(value).isAfter(minDate.value) && dayjs(value).isBefore(maxDate.value)
      })
      .orderBy((appointmentRoom) => $dayjs(appointmentRoom.time).unix())
    // addFilters
    let filtered = rooms.get()
    filtered = filtered.filter((room) => {
      return coachingType.value !== null ? room.appointmentType?.slug.includes(coachingType.value) : false
    })
    filtered = filtered.filter((room) => {
      return gameName.value !== null ? room.appointmentType?.game.slug.includes(gameName.value) : false
    })
    if (filterTags.value.length > 0) {
      activeTagsPerType.value.forEach((ids: Number[]) => {
        filtered = filtered.filter(function (room: AppointmentRoom) {
          const intersection: Number[] | undefined = room.owner?.user_coach_profile?.tag_ids.filter((id: Number) => {
            return ids.includes(id)
          })
          if (typeof intersection !== 'undefined') {
            return intersection.length > 0
          } else {
            return false
          }
        })
      })
    }
    if (filterCoaches.value.length > 0) {
      filtered = filtered.filter(function (room: AppointmentRoom) {
        const ownerId = room.owner?.id?.toString() || null
        if (ownerId === null) {
          return false
        }
        return filterCoaches.value.includes(ownerId)
      })
    }
    return filtered
  })

  const roomsByDate = computed(() => {
    const result: dateObject = {}
    const diff = dayjs(maxDate.value?.toString()).diff(minDate.value?.toString(), 'day')

    for (let i = 0; i <= diff; i++) {
      const day = dayjs(minDate.value?.toString()).add(i, 'day').format('YYYY-MM-DD')
      const array = rooms.value.filter((room: AppointmentRoom) => {
        return dayjs(room.starts_at).format('YYYY-MM-DD') === day
      })
      if (array.length > 0) {
        result[day] = array
      } else {
        result[day] = undefined
      }
    }
    return result
  })

  const roomsByDateAndTime = computed(() => {
    const roomsDateTime: dateObjectArray = {}
    const diff = dayjs(maxDate.value?.toString()).diff(minDate.value?.toString(), 'day')

    for (let i = 0; i <= diff; i++) {
      const day = dayjs(minDate.value?.toString()).add(i, 'day').format('YYYY-MM-DD')
      const roomsOfDay = rooms.value.filter((room: AppointmentRoom) => {
        return dayjs(room.starts_at).format('YYYY-MM-DD') === day
      })
      const timesOfDay = roomsOfDay.map((room: AppointmentRoom) => dayjs(room.time).format('YYYY-MM-DDTHH:mm'))
      const roomsTime: dateObject = {}
      if (timesOfDay.length > 0) {
        timesOfDay.forEach((time: string) => {
          const timeArray = roomsOfDay.filter((room: AppointmentRoom) => {
            return dayjs(room.time).format('YYYY-MM-DDTHH:mm') === time
          })
          roomsTime[time] = timeArray
        })
        roomsDateTime[day] = roomsTime
      } else {
        roomsDateTime[day] = undefined
      }
    }

    return roomsDateTime
  })

  const isBooked = (roomArray: AppointmentRoom[]) => {
    let status = false
    if (Array.isArray(roomArray) && typeof user !== 'undefined') {
      roomArray.forEach((room: AppointmentRoom) => {
        const booked = room.appointments.filter((appointment: Appointment) => appointment.user_id === user.id)
        if (booked.length > 0) {
          status = true
        }
      })
    }
    return status
  }

  const hasSlots = (roomArray: AppointmentRoom[]) => {
    if (!Array.isArray(roomArray)) {
      return true
    }
    const slots = roomArray?.filter((room: AppointmentRoom) => room.slotsAvailable > 0)
    return slots.length > 0
  }

  watch(
    () => route.value.query,
    (val) => {
      const newQuery = toRefs(queryParams)
      coachingType.value = queryParams.type =
        typeof val.type === 'string' ? decodeURIComponent(val.type) : queryParams.type
      filterTags.value = queryParams.tags = typeof val.tags === 'string' ? val.tags.split(',') : []
      filterCoaches.value = queryParams.coaches = typeof val.coaches === 'string' ? val.coaches.split(',') : []
      gameName.value = queryParams.game
      newQuery.minDate.value = val.minDate
      newQuery.maxDate.value = val.maxDate
      minDate.value = typeof val.minDate === 'string' ? dayjs(parseInt(val.minDate) * 1000).toDate() : dayjs().toDate()
      maxDate.value =
        typeof val.maxDate === 'string' ? dayjs(parseInt(val.maxDate) * 1000).toDate() : dayjs().add(7, 'day').toDate()
    },
    {
      deep: true
    }
  )

  return {
    rooms,
    tags,
    coaches,
    activeTags,
    activeTagsPerType,
    activeCoaches,
    activeCalendars,
    games,
    roomsByDate,
    roomsByDateAndTime,
    isBusy,
    isBooked,
    hasSlots,
    isCoach,
    calendars,
    query: queryParams
  }
}

export default useAppointments
