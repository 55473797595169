











import { defineComponent, PropType, provide, ref } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaTableWrapper extends StoryblokComponent<'table'> {
  table_style: StoryblokComponent<any>[]
  table: any
  custom_classes: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaAside>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const tableContent = ref(props.blok.table)

    provide('tableContent', tableContent)

    return {
      classes,
      dashify
    }
  }
})
