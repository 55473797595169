
































































import 'vue-select/dist/vue-select.css'
import { defineComponent, watch, ref, inject, onMounted, useRoute, useContext, PropType } from '@nuxtjs/composition-api'
import { ValidationProvider } from 'vee-validate'
import set from 'set-value'
import get from 'get-value'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import FormLabelWrapper from '~/components/FormLabel/FormLabelWrapper'

export interface GaSelect extends StoryblokComponent<'select'> {
  custom_classes: string
  injectItemsName: string
  model: string
  hasCommaSeperatedValues: boolean
  name: string
  route_param_prefill: boolean
  label: string
  v_select_label: string
  v_select_reduce: string
  multiple: boolean
  disabled: boolean
  default_label: string
  default_value: string
  disable_validation_display: boolean
}

export default defineComponent({
  components: {
    ValidationProvider,
    FormLabelWrapper
  },
  props: {
    blok: {
      type: Object as PropType<GaSelect>,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const internalValue = ref(props.value)
    const dataModel = inject('dataModel', {})
    const isFocused = ref(false)
    const { concatClasses } = useHelper()
    const route = useRoute()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const input = ref()
    const injectItemsName = props.blok.injectItemsName || 'selectOptionItems'

    const selectOptionItems = ref([])
    const options = inject(injectItemsName, selectOptionItems)

    const v_select_label = props.blok.v_select_label || 'name'
    const v_select_reduce = props.blok.v_select_reduce || 'id'

    const addDefaultOption = () => {
      if (props.blok.default_label && props.blok.default_value) {
        if (
          options.value.length === 0 ||
          (options.value.length > 0 && options.value[0][v_select_reduce] !== props.blok.default_value)
        ) {
          options.value.splice(0, 0, {
            [v_select_label]: props.blok.default_label,
            [v_select_reduce]: props.blok.default_value
          })
        }
      }
    }

    watch(options, addDefaultOption)

    const focus = () => {
      isFocused.value = true
    }
    const blur = () => {
      isFocused.value = false
    }
    const handleInput = (data: string) => {
      if (dataModel && props.blok.model) {
        set(
          dataModel,
          props.blok.model,
          props.blok.hasCommaSeperatedValues ? internalValue.value.split(',') : internalValue.value
        )
      }
      context.emit('input', data)
      nuxtContext.$bus.emit('input')
    }

    nuxtContext.$bus.off('validateFormField' + props.blok.name)
    nuxtContext.$bus.on('validateFormField' + props.blok.name, () => input.value.validate())

    onMounted(() => {
      internalValue.value = get(dataModel, props.blok.model)

      if (props.blok.route_param_prefill && route.value.query[props.blok.model]) {
        const queryData = route.value.query[props.blok.model]
        internalValue.value = Array.isArray(queryData) ? '' : queryData
        set(
          dataModel,
          props.blok.model,
          props.blok.hasCommaSeperatedValues ? internalValue.value.split(',') : internalValue.value
        )
      }
    })

    watch(
      () => props.value,
      (newVal: any) => {
        internalValue.value = newVal
      }
    )

    addDefaultOption()

    return {
      internalValue,
      handleInput,
      isFocused,
      focus,
      blur,
      classes,
      input,
      options,
      v_select_label,
      v_select_reduce
    }
  }
})
