




















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaCalendarSheet extends StoryblokComponent<'CalendarSheet'> {
  custom_classes: string
  datetime: string
  end_datetime: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaCalendarSheet>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)

    const date = new Date(props.blok.datetime)
    const monthName = date.toLocaleString('default', { month: 'long' })
    const weekdayName = date.toLocaleString('default', { weekday: 'short' })
    const dayNumeric = date.toLocaleString('default', { day: '2-digit' })
    const time = date.toLocaleString('default', { hour: '2-digit', minute: '2-digit' })

    let endtime = ''
    if (props.blok.end_datetime.length > 0) {
      const enddate = new Date(props.blok.end_datetime)
      endtime = enddate.toLocaleString('default', { hour: '2-digit', minute: '2-digit' })
    }

    return {
      classes,
      dashify,
      monthName,
      weekdayName,
      dayNumeric,
      time,
      endtime
    }
  }
})
