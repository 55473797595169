<template>
  <ValidationProvider
    ref="textarea"
    v-slot="{ errors, invalid, validated }"
    v-editable="blok"
    slim
    :name="blok.name"
    :rules="blok.validation_rules"
  >
    <div :class="classes">
      <FormLabelWrapper
        :class="['relative', ...classes]"
        :blok="{
          validated: !blok.disable_validation_display && validated,
          invalid: !blok.disable_validation_display && invalid,
          errors,
          text: blok.label,
          icon: blok.extra_field_icon,
          isFocused
        }"
      >
        <textarea
          :id="blok.name + '-' + blok._uid"
          v-model="internalValue"
          :name="blok.name"
          class="
            relative
            block
            px-4
            pt-3
            pb-1
            w-full
            text-lg
            appearance-none
            focus:outline-none
            rounded
            bg-white
            leading-4
          "
          :class="[
            {
              'border-error': !blok.disable_validation_display && validated && invalid,
              'border-success': !blok.disable_validation_display && validated && !invalid,
              'border-secondary-500': !validated && isFocused,
              'shadow-outline-error': !blok.disable_validation_display && validated && invalid && isFocused,
              'shadow-outline-success': !blok.disable_validation_display && validated && !invalid && isFocused
            },
            classes
          ]"
          :placeholder="blok.placeholder"
          :rows="blok.rows"
          :cols="blok.cols"
          @input="handleInput('textarea', $event)"
          @focus="focus"
          @blur="blur"
        ></textarea>
      </FormLabelWrapper>
    </div>
  </ValidationProvider>
</template>

<script>
import { defineComponent, watch, ref, onMounted, inject } from '@nuxtjs/composition-api'
import { ValidationProvider } from 'vee-validate'
import set from 'set-value'
import get from 'get-value'
import helper from '@/utils/helper'
import FormLabelWrapper from '~/components/FormLabel/FormLabelWrapper'

export default defineComponent({
  components: {
    ValidationProvider,
    FormLabelWrapper
  },
  props: {
    blok: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const { concatClasses } = helper()
    const classes = concatClasses(props.blok)
    const dataModel = inject('dataModel', null)
    const internalValue = ref(props.value || get(dataModel, props.blok.model))
    const isFocused = ref(false)

    const focus = () => {
      isFocused.value = true
    }
    const blur = () => {
      isFocused.value = false
    }
    const handleInput = (type, event) => {
      if (dataModel && props.blok.model) {
        set(
          dataModel,
          props.blok.model,
          props.blok.hasCommaSeperatedValues ? internalValue.value.split(',') : internalValue.value
        )
      }
      context.emit('update:value', event)
      context.emit(type, event)
    }

    onMounted(() => {
      internalValue.value = get(dataModel, props.blok.model)
    })

    watch(
      () => props.value,
      (newValue) => {
        internalValue.value = newValue
      }
    )

    return {
      internalValue,
      handleInput,
      isFocused,
      focus,
      blur,
      classes
    }
  }
})
</script>
