var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.blok.type,{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],tag:"component",class:_vm.classes},[_vm._l((_vm.blok.content),function(blokChild){return _c(_vm.dashify(blokChild.component),{key:blokChild._uid,tag:"component",attrs:{"blok":blokChild}})}),_vm._v(" "),_vm._l((_vm.items),function(item,index){return _c('RepeatableItem',{key:item._uid,attrs:{"item":item,"item-name":_vm.blok.provide_item_name || 'item',"item-index":index,"provide-data-model":_vm.blok.provide_data_model || false,"data-model":_vm.blok.data_model,"component-props":_vm.generateRepeatableItemAttributes(item),"focused-item-id":_vm.focusedItemId},on:{"click":_vm.handleClick}})}),_vm._v(" "),_c('div',{staticClass:"flex"},[(_vm.blok.with_show_more && !_vm.showMore && _vm.allItemscount > parseInt(_vm.blok.show_more_item_limit))?_c('BlokButton',{attrs:{"blok":{
        custom_classes: 'mx-auto',
        content: _vm.blok.show_more_content
      }},on:{"click":function($event){_vm.showMore = true}}}):_vm._e()],1),_vm._v(" "),_vm._l((_vm.blok.content_after),function(blokChild){return _c(_vm.dashify(blokChild.component),{key:blokChild._uid,tag:"component",attrs:{"blok":blokChild}})}),_vm._v(" "),(_vm.blok.with_validation)?_c('BlokInput',{attrs:{"value":String(_vm.focusedItemId),"blok":{
      name: _vm.blok.provide_selected_item_name + '_id',
      label: 'Selection',
      model: _vm.blok.provide_selected_item_name + '_id',
      type: 'hidden',
      validation_rules: 'required',
      show_hidden_errors: true
    }}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }