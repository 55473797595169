import { Ref, useAsync, useContext } from '@nuxtjs/composition-api'
import Storyblok, { StoryParams, StoriesParams } from 'storyblok-js-client'
import { StoryblokComponent, StoryData } from '~/types/storyblok-vue'
import { Context } from '@nuxt/types'
import { Route } from 'vue-router'

interface LoadData {
  api: Storyblok
  cacheVersion?: number
  version?: 'draft' | 'published'
  path: string
  isUuid: boolean
  context: UseContextReturn
}

interface UseContextReturn extends Omit<Context, 'route' | 'query' | 'from' | 'params'> {
  route: Ref<Route>
  query: Ref<Route['query']>
  from: Ref<Context['from']>
  params: Ref<Route['params']>
}

export interface GetStoryblokOptions {
  path: string
  context?: UseContextReturn
  isUuid?: boolean
}

export interface DatasourceEntry {
  id: number
  name: string
  value: string
  dimension_value: string | null
}

export default function () {
  const getStories = (context: UseContextReturn, params: StoriesParams) => {
    params.version = isEditMode(context) ? 'draft' : 'published'
    params.cv = context.store.state.cacheVersion

    return context.app.$storyapi
      .get(`cdn/stories`, params)
      .then((res) => {
        return res.data.stories
      })
      .catch((res) => {
        if (!res.response) {
          console.error(res)
          context.error({ statusCode: 404, message: 'Failed to receive content form api' })
        } else {
          console.error(res.response.data)
          context.error({ statusCode: res.response.status, message: res.response.data })
        }
      })
  }

  const getPagedStories = (context: UseContextReturn, params: StoriesParams) => {
    params.version = isEditMode(context) ? 'draft' : 'published'
    params.cv = context.store.state.cacheVersion

    return context.app.$storyapi
      .get(`cdn/stories`, params)
      .then((res) => {
        return {
          stories: res.data.stories,
          perPage: res.headers['per-page'],
          total: res.headers.total
        }
      })
      .catch((res) => {
        if (!res.response) {
          console.error(res)
          context.error({ statusCode: 404, message: 'Failed to receive content form api' })
        } else {
          console.error(res.response.data)
          context.error({ statusCode: res.response.status, message: res.response.data })
        }
      })
  }

  const loadData = async ({ api, cacheVersion, version, path, isUuid, context }: LoadData) => {
    const params: StoryParams = {
      version: version,
      cv: cacheVersion,
      resolve_relations: 'blog-page.author,blog-page.categories,video-page.categories'
    }
    if (path !== '/') {
      path = path.replace(/\/$/, '')
    }
    if (isUuid) {
      params.find_by = 'uuid'
    }
    if (!isUuid && context.$config.storyblokRealPath) {
      path = context.store.getters['links/findByRealPath'](path)
      if (!path) {
        return null
      }
    }

    return api
      .get(`cdn/stories/${path}`, params)
      .then((res) => {
        return res.data.story
      })
      .catch((res) => {
        console.error({
          Version: version,
          CacheVersion: cacheVersion,
          Path: path
        })
        if (!res.response) {
          console.error(res)
          context.error({ statusCode: 404, message: 'Failed to receive content form api' })
        } else {
          console.error(res.response.data)
          context.error({ statusCode: res.response.status, message: res.response.data })
        }
        return false
      })
  }

  const listenToStoryBridgeChanges = (story: Ref) => {
    const context = useContext()
    context.$storybridge(
      () => {
        const storyblokInstance = new StoryblokBridge()

        storyblokInstance.on(['input', 'published', 'change'], (event) => {
          if (event !== undefined && event.action == 'input') {
            if (story.value.story && event.story.id === story.value.story.id) {
              story.value.story.content = event.story.content
            }
          } else {
            window.location.reload()
          }
        })
      },
      (error: any) => {
        console.error(error)
      }
    )
  }

  const isEditMode = (context: UseContextReturn) => {
    context = context || useContext()
    if (
      context.query.value._storyblok ||
      context.isDev ||
      context.$config.dev ||
      (typeof window !== 'undefined' && window.localStorage.getItem('_storyblok_draft_mode'))
    ) {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem('_storyblok_draft_mode', '1')
        if (window.location === window.parent.location) {
          window.localStorage.removeItem('_storyblok_draft_mode')
        }
      }

      return true
    }

    return false
  }

  const getDatasource = (path: string, dimension = ''): Promise<void|Array<DatasourceEntry>> => {
    const context = useContext()
    // Check if we are in the editing mode
    const editMode = isEditMode(context)

    let version = editMode ? 'draft' : 'published'

    // Load the JSON from the API
    return context.app.$storyapi
      .get('cdn/datasource_entries', {
        datasource: path,
        version: version,
        cv: context.store.state.cacheVersion,
        dimension: dimension
      })
      .then((res) => {
        return res.data.datasource_entries as Array<DatasourceEntry>
      })
      .catch((res) => {
        if (!res.response) {
          console.error(res)
          context.error({ statusCode: 404, message: 'Failed to receive content form api' })
        } else {
          console.error(res.response.data)
          context.error({ statusCode: res.response.status, message: res.response.data })
        }
      })
  }

  const getTemplate = (
    uuid: string,
    options: { templateProperty?: string; key?: string | number; context?: any } = {}
  ) => {
    if (!uuid) {
      return null
    }
    const storyContent = async (): Promise<StoryblokComponent<any>[]> =>
      await getStory({
        ...options,
        path: uuid,
        isUuid: true
      }).then((story) => (options.templateProperty ? story.content[options.templateProperty] : story.content))

    return useAsync(storyContent, uuid)
  }

  const getStory = (options: GetStoryblokOptions): Promise<StoryData> => {
    const context = options.context || useContext()

    // Check if we are in the editing mode
    const editMode = isEditMode(context)
    let version: 'draft' | 'published' = editMode ? 'draft' : 'published'
    const path = options.path

    // Load the JSON from the API
    return loadData({
      version: version,
      api: context.app.$storyapi,
      cacheVersion: context.store.state.cacheVersion,
      path: path,
      isUuid: options.isUuid || false,
      context
    })
  }

  return {
    getStory,
    getStories,
    getPagedStories,
    getTemplate,
    listenToStoryBridgeChanges,
    isEditMode,
    getDatasource
  }
}
