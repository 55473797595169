






import { defineComponent, PropType, useContext, ref, unref, useAsync, computed } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import { Link } from '~/components/link/Link.vue'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useTemplates from '~/utils/useTemplates'
import useStoryblok from '~/utils/storyblok'

export interface GaNavigationSimple extends StoryblokComponent<'navigation-simple'> {
  custom_classes: string
  link_style: Link[]
  menu_items_id: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaNavigationSimple>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify, checkScopes } = helper()
    const { generateNavItemAttributes } = useTemplates()
    const { getStory } = useStoryblok()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const { link_style } = props.blok

    const getNavigationItems = async () => {
      if (nuxtContext.payload) {
        const menuItemStory = nuxtContext.payload.menuItemStories.find(
          (menuItemStory) => menuItemStory.uuid === props.blok.menu_items_id
        )
        return menuItemStory ? menuItemStory.content.children : null
      }
      return await getStory({ path: props.blok.menu_items_id, context: nuxtContext, isUuid: true }).then((story) => {
        return story ? story.content.children : null
      })
    }
    const uncheckedNavigationItems = props.blok.menu_items_id
      ? useAsync(getNavigationItems, props.blok.menu_items_id)
      : ref([])

    const navigationItems = computed(
      () =>
        unref(uncheckedNavigationItems)?.filter((item: any) =>
          checkScopes(nuxtContext, [...(item.auth || []), ...(item.plan_features || []), ...(item.game_accounts || [])])
        ) || []
    )

    const generateItemAttributes = (item: any) => {
      return generateNavItemAttributes(item, link_style)
    }

    return {
      classes,
      dashify,
      navigationItems,
      generateItemAttributes
    }
  }
})
