import { ref, useContext } from '@nuxtjs/composition-api'

export function useModal(options = {}) {
  const isModalOpen = ref(false)
  const modalConfig = ref({})
  const nuxtContext = useContext()

  nuxtContext.$bus.off('closeFormModal')
  nuxtContext.$bus.on('closeFormModal', () => {
    document.body.classList.remove('overflow-hidden')
    isModalOpen.value = false
  })

  nuxtContext.$bus.off('openFormModal')
  nuxtContext.$bus.on('openFormModal', (config) => {
    document.body.classList.add('overflow-hidden')
    modalConfig.value = config
    isModalOpen.value = true
  })

  return [isModalOpen, modalConfig]
}
