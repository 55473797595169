/* eslint-disable */
// import axios from 'axios'

import VuexORM from '@vuex-orm/core'

export const plugins = [
  VuexORM.install()
]

export const state = () => ({
  cacheVersion: 0,
  links: [],
  language: 'en',
  formData: null,
  formTemplateId: null
})

export const mutations = {
  setLanguage(state, language) {
    state.language = language
  },
  setCacheVersion(state, version) {
    state.cacheVersion = version
  },
  setLinks(state, links) {
    state.links = links
  },
  setFormData(state, formData) {
    state.formData = formData
  },
  setFormTemplateId(state, formTemplateId) {
    state.formTemplateId = formTemplateId
  }
}

export const actions = {
  async loadCacheVersion({ commit}, data) {
    return await this.$storyapi.get(`cdn/spaces/me`).then(async (res) => {
      commit('setCacheVersion', res.data.space.version)
      return await this.$storyapi.get(`cdn/links`, { ...data, cv: res.data.space.version}).then((res) => {
        commit('setLinks', Object.values(res.data.links).map(link => {
          return {
            slug: link.slug,
            real_path: link.real_path
          }
        }))
      })
    })
  },
  setLanguage: ({commit, state}, newValue) => {
    commit('setLanguage', newValue)
    return state.language
  },
  setFormData: ({commit, state}, newValue) => {
    commit('setFormData', newValue)
    return state.formData
  },
  setFormTemplateId: ({commit, state}, newValue) => {
    commit('setFormTemplateId', newValue)
    return state.formTemplateId
  },
}

export const getters = {
  'links/findByRealPath': state => (realPath) => {
    const links = state.links.filter(link => link.real_path === realPath)
    if (links.length === 1) {
      return links[0].slug
    }
    if (links.length > 1) {
      console.warn('Same link slugs found! Please change them', links)
      return links[0].slug
    }
    console.warn('No route found for realPath', realPath)
    return null
  },
  'links/findBySlug': state => (slug) => {
    const links = state.links.filter(link => link.slug === slug.replace(/\/$/, ''))
    if (links.length === 1) {
      return links[0].real_path
    }
    if (links.length > 1) {
      console.warn('Same link slugs found! Please change them', links)
      return links[0].real_path
    }
    console.warn('No route found for slug', slug)
    return null
  }
}
