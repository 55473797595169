import validationMessages from 'vee-validate/dist/locale/en.json'

// export default async (context: any, locale: any) => {
//   return await Promise.resolve({
//     welcome: 'Welcome'
//   })
// }

export default {
  validations: validationMessages,
  fields: {
    password: 'Password',
    email: 'E-Mail',
  }
}
