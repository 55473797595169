












import { defineComponent, PropType } from '@nuxtjs/composition-api'
import helper from '~/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaDescriptionList extends StoryblokComponent<'description-list'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  text: string
  type: 'dl' | 'dd' | 'dt'
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaDescriptionList>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)

    return {
      classes,
      dashify
    }
  }
})
