















import { defineComponent, useRoute, useRouter } from '@nuxtjs/composition-api'
import Checkbox from '~/components/calendar/Filter/Checkbox.vue'
import useFilters from '~/composables/useFilters'

export default defineComponent({
  components: { Checkbox },
  props: {
    heading: { type: String, default: () => '' }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { activeCoaches, coaches, query, calendars } = useFilters()

    const select = (selected: { id: { toString: () => string } }) => {
      let queryCoaches: string[] = []

      if ('coaches' in query) {
        const dataString = query.coaches
        if (typeof dataString === 'string') {
          queryCoaches = dataString?.split(',')
        }
      }

      if (!queryCoaches.includes(selected.id.toString())) {
        queryCoaches.push(selected.id.toString())
      } else {
        const index = queryCoaches.indexOf(selected.id.toString())
        if (index > -1) {
          queryCoaches.splice(index, 1)
        }
      }

      query.coaches = queryCoaches.length > 0 ? queryCoaches.join() : null

      router.push({
        path: route.value.path,
        query
      })
    }

    return {
      activeCoaches,
      calendars,
      coaches,
      select,
      query
    }
  }
})
