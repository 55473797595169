




import { defineComponent, ref, PropType, useContext, useMeta } from '@nuxtjs/composition-api'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface Tweet extends StoryblokComponent<'Tweet'> {
  id: string
  theme: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<Tweet>,
      default: () => {}
    }
  },
  setup(props) {
    const tweet = ref(null)
    const nuxtContext = useContext()
    const { script } = useMeta()
    script.value = [
      {
        hid: 'twitter',
        type: 'text/javascript',
        src: '//platform.twitter.com/widgets.js',
        defer: true,
        async: true,
        callback: () => {
          twttr.widgets.load()
          twttr.widgets.createTweet(props.blok.id, tweet.value, { theme: nuxtContext.$colorMode.preference })
        }
      }
    ]

    return { tweet }
  },
  head: {}
})
