











import { defineComponent, PropType } from '@nuxtjs/composition-api'
import helper from '~/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaSvgElement extends StoryblokComponent<'svg-element'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  type: 'rect' | 'polygon' | 'defs' | 'pattern'
  xmlns: string
  viewBox: string
  version: string
  preserveAspectRatio: string
  fill: string
  patternUnits: string
  x: string
  y: string
  points: string
  width: string
  height: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaSvgElement>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)

    const attr = {
      ...(classes && { class: classes }),
      ...(props.blok.fill && { fill: props.blok.fill }),
      ...(props.blok.patternUnits && { patternUnits: props.blok.patternUnits }),
      ...(props.blok.x && { x: props.blok.x }),
      ...(props.blok.y && { y: props.blok.y }),
      ...(props.blok.points && { points: props.blok.points }),
      ...(props.blok.width && { width: props.blok.width }),
      ...(props.blok.height && { height: props.blok.height })
    }

    return {
      attr,
      dashify
    }
  }
})
