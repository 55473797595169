import { Model } from '@vuex-orm/core'

export default class Tag extends Model {
  static entity = 'tags'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      type: this.attr(''),
      order_column: this.attr(null)
    }
  }

  id!: number
  name!: string
  type!: string
  order_column!: number
}
