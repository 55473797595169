import { Model } from '@vuex-orm/core'
import Order from '~/models/Order'
import Subscription from '~/models/Subscription'
import UserCoachProfile from '~/models/UserCoachProfile'
import GameAccount from '~/models/GameAccount'
import StoreCredit from '~/models/StoreCredit'
import Appointment from '~/models/Appointment'
import Game from '~/models/Game'

export default class User extends Model {
  static entity = 'users'

  static fields() {
    return {
      id: this.uid(),
      subscriptionIds: this.attr([]),
      storeCreditIds: this.attr([]),
      name: this.string(''),
      email: this.string(''),
      email_verified_at: this.string('').nullable(),
      first_name: this.string('').nullable(),
      last_name: this.string('').nullable(),
      profile_photo_url: this.string(''),
      profile_image: this.string(''),
      is_coach: this.boolean(false),
      used_free_trial: this.boolean(false),
      timezone: this.attr(''),
      latest_game_id: this.attr(0).nullable(),
      orders: this.hasMany(Order, 'userId'),
      appointments: this.hasMany(Appointment, 'user_id'),
      subscriptions: this.hasManyBy(Subscription, 'subscriptionIds'),
      store_credits: this.hasManyBy(StoreCredit, 'storeCreditIds'),
      user_coach_profile: this.hasOne(UserCoachProfile, 'user_id'),
      game_account: this.hasMany(GameAccount, 'user_id'),
      latest_game: this.hasOne(Game, 'id')
    }
  }

  id!: number
  first_name!: string | null
  last_name!: string | null
  name!: string
  timezone!: string
  email!: string
  email_verified_at!: string | null
  is_coach!: boolean
  used_free_trial!: boolean
  orders!: Order[]
  subscriptions!: Subscription[]
  store_credits!: StoreCredit[]
  user_coach_profile!: UserCoachProfile
  game_account!: GameAccount[]
  latest_game!: Game | null

  get fullName(): string | undefined {
    if (this.first_name !== null) return `${this.first_name} ${this.last_name}`
  }
}
