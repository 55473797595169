











import { defineComponent, ref, useContext, watch } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'

export default defineComponent({
  name: 'GaPage',
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { dashify, concatClasses } = useHelper()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const currentColor = ref(nuxtContext.$colorMode.value)

    watch(currentColor, (currentColor) => {
      if (props.blok.color_theme && currentColor !== props.blok.color_theme) {
        nuxtContext.$colorMode.preference = props.blok.color_theme
      } else {
        nuxtContext.$colorMode.preference = 'dark'
      }
    })

    return {
      classes,
      dashify
    }
  }
})
