var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',[(_vm.svgIcon && _vm.svgIcon.attributes)?_c('svg',_vm._b({class:[
      (_vm.blok && _vm.blok.size) || null,
      _vm.svgIcon.attributes.class,
      ( _obj = {
        'fa-spin': _vm.spin,
        'fa-pulse': _vm.pulse,
        'fa-fw': _vm.fixedWidth,
        'fa-border': _vm.border,
        'fa-li': _vm.listItem,
        'fa-inverse': _vm.inverse,
        'fa-flip-horizontal': _vm.flip === 'horizontal' || _vm.flip === 'both',
        'fa-flip-vertical': _vm.flip === 'vertical' || _vm.flip === 'both' || _vm.flipVertical
      }, _obj[("fa-" + _vm.size)] = _vm.size !== null, _obj[("fa-rotate-" + _vm.rotation)] = _vm.rotation !== null, _obj[("fa-pull-" + _vm.pull)] = _vm.pull !== null, _obj['fa-swap-opacity'] =  _vm.swapOpacity, _obj )
    ]},'svg',_vm.svgIcon.attributes,false),_vm._l((_vm.svgIcon.children),function(child,index){return _c(child.tag,_vm._b({key:index,tag:"component"},'component',child.attributes,false),_vm._l((child.children),function(child2,index2){return _c(child2.tag,_vm._b({key:index + index2,tag:"component"},'component',child2.attributes,false),_vm._l((child2.children),function(child3,index3){return _c(child3.tag,_vm._b({key:index + index + index3,tag:"component"},'component',child3.attributes,false))}),1)}),1)}),1):_c('span')])}
var staticRenderFns = []

export { render, staticRenderFns }