var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:bg-transparent sm:px-6",attrs:{"aria-label":"Pagination"}},[_c('div',{staticClass:"flex-1 flex justify-between sm:justify-end space-x-2"},[_c('blok-link',{class:[{ 'bg-gray-300 hover:bg-gray-300 dark:bg-gray-600 text-white cursor-not-allowed': _vm.page <= 1 }],attrs:{"disabled":_vm.page <= 1,"blok":{
        custom_classes:
          'relative inline-flex items-center  px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer',
        text: 'Previous'
      }},on:{"click":function($event){_vm.page >= 2 ? _vm.changePage((_vm.page += -1)) : null}}}),_vm._v(" "),_c('blok-link',{attrs:{"blok":{
        custom_classes:
          'relative inline-flex items-center  px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer',
        text: 'Next'
      }},on:{"click":function($event){_vm.changePage((_vm.page += 1))}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }