


















































































import { defineComponent, inject, PropType, ref, watch } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { Link } from '~/components/link/Link.vue'
import { StoryblokComponent } from '~/types/storyblok-vue'
import { Dropdown } from '~/components/dropdown/Dropdown.vue'

export interface NavigationDesktop extends StoryblokComponent<'navigation-desktop'> {
  custom_classes: string
  link_style: Link[]
  dropdown_style: Dropdown[]
  content_left: StoryblokComponent<any>[]
  content_right: StoryblokComponent<any>[]
  content_right_mobile: StoryblokComponent<any>[]
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<NavigationDesktop>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify, isMobile, replacePlaceholder, stringToJson } = useHelper()
    const classes = concatClasses(props.blok)
    const menuIsOpen = inject('mobileMenuOpen', ref(false))

    const { link_style, dropdown_style } = props.blok

    const navigationItems = inject('navigationItems', ref([]))

    const generateSubItemAttributes = (item: any) => {
      const isDropDownTemplate = dropdown_style && dropdown_style[0].component === 'dropdown'
      const dropdownTemplateClone = stringToJson(JSON.stringify(dropdown_style[0]))
      const placeholder = [
        {
          key: /%button_link_text%/,
          value: item.label
        },
        {
          key: /%button_icon_type%/,
          value: item.icon_type
        },
        {
          key: /%button_icon_name%/,
          value: item.icon_name
        }
      ]
      return {
        is: isDropDownTemplate ? dashify(dropdownTemplateClone.component) : 'a',
        ...(isDropDownTemplate && {
          blok: { ...replacePlaceholder(dropdownTemplateClone, placeholder), items: item.children }
        }),
        ...(!isDropDownTemplate && { href: '#' })
      }
    }

    const generateItemAttributes = (item: any) => {
      if (item.children.length) {
        return generateSubItemAttributes(item)
      }
      const isLinkTemplate = link_style && link_style[0].component === 'link'
      const linkTemplateClone = stringToJson(JSON.stringify(link_style[0]))
      const placeholder = [
        {
          key: /%link_text%/,
          value: item.label
        },
        {
          key: /%icon_type%/,
          value: item.icon_type
        },
        {
          key: /%icon_name%/,
          value: item.icon_name
        }
      ]
      return {
        is: isLinkTemplate ? dashify(linkTemplateClone.component) : 'a',
        ...(isLinkTemplate && {
          blok: { ...replacePlaceholder(linkTemplateClone, placeholder), link: item.link, action: item.action }
        }),
        ...(!isLinkTemplate && { href: '#' })
      }
    }

    const handleMenuState = () => {
      menuIsOpen.value = !menuIsOpen.value
    }

    watch(isMobile, (newValue) => {
      if (!newValue) {
        menuIsOpen.value = false
      }
    })

    return {
      classes,
      dashify,
      navigationItems,
      generateItemAttributes,
      menuIsOpen,
      handleMenuState
    }
  }
})
