











import { defineComponent, PropType, provide } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaFooter extends StoryblokComponent<'footer'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaFooter>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)

    const ariaLabelId = 'section' + props.blok._uid
    provide('ariaLabelId', ariaLabelId)

    return {
      classes,
      dashify,
      ariaLabelId
    }
  }
})
