import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import User from '@/models/User'

export default defineNuxtMiddleware(async (ctx) => {
  // const isLoggedIn = ctx.$auth.loggedIn
  if (process.client && ctx.$auth && ctx.$auth.$state.loggedIn) {
    const axios = ctx.$axios.create({
      withCredentials: true,
      baseURL: ctx.$config.middlewareApi
    })
    try {
      const userData = await axios.get('/api/user').then((response) => response.data.data)
      if (userData) {
        ctx.$auth.setUser(userData.id)
        ctx.store.$repo(User).save(userData)
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === 'Unauthenticated.' && error.response.status === 401) {
          ctx.$auth.logout()
        }
      } else {
        console.error(error)
      }
    }

  }
  // ctx.$auth.onError((error, name, endpoint) => {
  // @ts-ignore
  // ctx.$toast.error(`Error: "${error.message}" with ${name.method}`)
  // })
})
