







































import { defineComponent, watch, ref, inject, PropType, onMounted, ComputedRef, unref } from '@nuxtjs/composition-api'
import { ValidationProvider } from 'vee-validate'
import set from 'set-value'
import get from 'get-value'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import { GaRadioItem } from '~/components/radioGroup/RadioItem.vue'
import useTemplates from '~/utils/useTemplates'
import User from '~/models/User'

export interface GaRadioGroup extends StoryblokComponent<'radio-group'> {
  content: StoryblokComponent<any>[]
  radio_item_style: GaRadioItem[]
  custom_classes: string
  name: string
  model: string
  validation_rules: string
  screen_reader_text: string
  default_value_by_store: string
  display_only_selected: boolean
  value: boolean
  disable_validation_display: boolean
}

export default defineComponent({
  components: {
    ValidationProvider
  },
  props: {
    blok: {
      type: Object as PropType<GaRadioGroup>,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const { dashify, concatClasses } = useHelper()
    const { generateItemAttributes } = useTemplates()
    const classes = concatClasses(props.blok)
    const internalValue = ref('')
    const dataModel = inject('dataModel', {})
    const userData: ComputedRef<User> | undefined = inject('userData')
    const user = unref(userData)
    const radioGroupItems = ref([])
    radioGroupItems.value = unref(inject('radioGroupItems', radioGroupItems))
    const { radio_item_style } = props.blok

    const handleInput = (type: string, value: string) => {
      internalValue.value = value
      if (dataModel && props.blok.model) {
        set(dataModel, props.blok.model, value)
      }
      context.emit(type, value)
    }

    const generateRadioItemAttributes = (item) => {
      const attr = generateItemAttributes(item, radio_item_style)
      return {
        ...attr,
        blok: {
          ...attr.blok,
          name: props.blok.name,
          value: item.id
        }
      }
    }

    onMounted(() => {
      internalValue.value = get(dataModel, props.blok.model)
      const defaultValue = props.blok.default_value_by_store
      if (defaultValue) {
        // Dirty hack @todo: refactor
        if (/subscriptions/.test(defaultValue)) {
          if (!get(user, 'subscriptions.0.isCancled')) {
            handleInput('input', get(user, defaultValue))
          }
        } else {
          handleInput('input', get(user, defaultValue))
        }
      }
      if (props.blok.display_only_selected) {
        const singleItem = unref(radioGroupItems).filter((item) => item.id === internalValue.value)
        if (singleItem && singleItem.length > 0) {
          radioGroupItems.value = singleItem
        }
      }
    })

    watch(
      () => props.value,
      (newValue) => {
        internalValue.value = newValue
      }
    )

    return {
      internalValue,
      handleInput,
      classes,
      dashify,
      generateRadioItemAttributes,
      radioGroupItems
    }
  }
})
