





























import { defineComponent, inject, PropType, ref, useContext, useStore, watch } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent, StoryblokLink } from '~/types/storyblok-vue'
import useStoryblok from '~/utils/storyblok'

export interface Link extends StoryblokComponent<'link'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  link: StoryblokLink
  data_attributes: any
  is_button: boolean
  target: string
  action_template_id: string
  append_query_params: string
  append_query_action_template_id: string
  save_data_model_to_store: boolean
  save_action_template_id_to_store: boolean
  action: string
  text: string
  disabled: boolean
}

export default defineComponent({
  name: 'GaLink',
  props: {
    blok: {
      type: Object as PropType<Link>,
      classes: String,
      default: () => {}
    }
  },
  setup(props, context) {
    const { concatClasses, getButtonClasses, scrollTo, dashify, stringToJson, formatToAnchor } = useHelper()
    const storyblokEdit = inject('storyblokEdit', ref(false))
    const dataModel = inject('dataModel', {})
    const { getTemplate } = useStoryblok()
    const store = useStore()
    const buttonClasses = ref({})
    const nuxtContext = useContext()
    const dataAttributes = ref()
    const link = props.blok.link
    const disabled = ref(props.blok.disabled)
    const hasAction = props.blok.action && props.blok.action !== ''
    // const active = ref(false)

    const handleClick = (event: MouseEvent) => {
      if (props.blok.save_data_model_to_store) {
        store.dispatch('setFormData', dataModel)
      }

      if (props.blok.action === 'openFormModal') {
        watch(
          () => props.blok.action_json,
          () => {
            nuxtContext.$bus.emit('updateModal', stringToJson(props.blok.action_json))
          }
        )
      }

      const emitAction = (action = props.blok.custom_action || props.blok.action) => {
        nuxtContext.$bus.emit(action, {
          ...(props.blok.action_include_data_model &&
            dataModel !== {} &&
            Object.keys(dataModel).length > 0 && { dataModel }),
          ...(props.blok.action_template_id && {
            content: getTemplate(props.blok.action_template_id, { context: nuxtContext }),
            takeInnerContent: true
          }),
          ...(props.blok.action_content &&
            props.blok.action_content.length > 0 && { content: props.blok.action_content }),
          ...(props.blok.action_json && { ...stringToJson(props.blok.action_json) }),
          ...(props.blok.action_props && { ...props.blok.action_props }),
          ...(props.blok.action_pass_focused_item_as_prop && { ...injectedProps })
        })
      }

      if (hasAction) {
        event.preventDefault()
        nuxtContext.$bus.emit(props.blok.action)
        emitAction('openFormModal')
      }

      if (disabled.value || storyblokEdit.value) return event.preventDefault()

      context.emit('click', event)
      if (link && link.linktype === 'story' && link.anchor) {
        scrollTo(`#${link.anchor}`)
      }
    }

    const handleMove = () => {
      if (disabled.value) return
      // if (active.value) return
      context.emit('mousemove')
    }

    const handleLeave = () => {
      if (disabled.value) return
      // if (!active.value) return
      context.emit('mouseleave')
    }

    const events = {
      mouseleave: handleLeave,
      pointerleave: handleLeave,
      mousemove: handleMove,
      pointermove: handleMove
    }

    if (props.blok.data_attributes) {
      try {
        dataAttributes.value = stringToJson(JSON.stringify(props.blok.data_attributes))
      } catch {
        // Todo: Logg that there is an invalid Json somewhere
      }
    }

    if (props.blok.is_button) {
      buttonClasses.value = getButtonClasses(props.blok)
    }
    const classes = concatClasses({
      ...props.blok,
      ...buttonClasses.value
    })

    let linkTo = link?.cached_url || ''
    const appendQueryParams = props.blok.append_query_params || false
    const appendActionTemplateId = props.blok.append_query_action_template_id || false

    if (appendQueryParams || appendActionTemplateId) {
      linkTo = linkTo + '?'
    }
    if (appendQueryParams) {
      linkTo = linkTo + appendQueryParams
    }

    if (appendActionTemplateId) {
      linkTo =
        linkTo +
        (appendQueryParams ? '&' : '') +
        'actionParamContentId=' +
        encodeURIComponent(props.blok.action_template_id)
    }

    if (props.blok.save_action_template_id_to_store) {
      store.dispatch('setFormTemplateId', props.blok.action_template_id)
    }

    const attributes = {
      is: link && link.linktype === 'story' && !hasAction ? 'nuxt-link' : 'a',
      ...(link && link.linktype === 'story' && !hasAction && storyblokEdit.value && { event: '' }),
      ...(classes && { class: classes }),
      ...(props.blok.target && { target: props.blok.target }),
      ...(link && link.linktype === 'story' && !hasAction && { to: `/${linkTo}` + formatToAnchor(link.anchor) }),
      ...(link && link.linktype === 'url' && { href: linkTo }),
      ...(link && link.linktype === 'email' && { href: `mailto:${link.email}` }),
      ...(link && link.linktype === 'asset' && { href: linkTo, download: true }),
      ...(hasAction && { href: `#` })
    }

    return {
      dashify,
      attributes,
      events,
      handleClick
    }
  }
})
