




























import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  ref,
  useContext,
  useStore,
  watch
} from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaHasRightsEffect extends StoryblokComponent<'has-rights-effect'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  scopes: string[]
  plan_features: string[]
  game_accounts: string[]
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaHasRightsEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify, checkScopes } = useHelper()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const userData = inject('userData')
    const isStoryblokEdit = inject('storyblokEdit', ref(false))
    const isGenerating = computed(() => {
      return !!nuxtContext.payload
    })
    const store = useStore()
    const busy = computed(() => store.state.auth.busy)
    const isVisible = ref(false)

    nuxtContext.$auth.$storage.watchState('loggedIn', () => {
      isVisible.value = checkScopes(nuxtContext, [
        ...props.blok.scopes,
        ...(props.blok.plan_features || []),
        ...(props.blok.game_accounts || [])
      ])
    })

    onMounted(() => {
      isVisible.value = checkScopes(nuxtContext, [
        ...props.blok.scopes,
        ...(props.blok.plan_features || []),
        ...(props.blok.game_accounts || [])
      ])
    })

    watch(userData, () => {
      isVisible.value = checkScopes(nuxtContext, [
        ...props.blok.scopes,
        ...(props.blok.plan_features || []),
        ...(props.blok.game_accounts || [])
      ])
    })

    return {
      classes,
      dashify,
      isVisible,
      isStoryblokEdit,
      isGenerating,
      busy
    }
  }
})
