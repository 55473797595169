



















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import { useModal } from '~/utils/useModel'

export interface ModalTemplate extends StoryblokComponent<'modal-template'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  dataModel: any
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<ModalTemplate>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const [isModalOpen, modalConfig] = useModal()
    isModalOpen.value = true

    return {
      classes,
      dashify,
      isModalOpen,
      modalConfig
    }
  }
})
