





















import { defineComponent, onMounted, PropType, ref, useAsync, useContext } from '@nuxtjs/composition-api'
import { MatomoAbTestingGetExperimentResponse } from 'vue-matomo'
import { AxiosResponse } from 'axios'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaAbTest extends StoryblokComponent<'abTest'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  experiment_id: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaAbTest>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const activeTest = ref('')
    const isStoryblokEdit = ref(false)
    const matomoLoaded = ref(false)
    const variationIds: string[] = []
    const getExperimentConfigById = async (id: string) => {
      const experimentConfig = await nuxtContext.$axios
        .get(nuxtContext.$config.matomo.url, {
          params: {
            module: 'API',
            method: 'AbTesting.getExperiment',
            format: 'JSON',
            token_auth: nuxtContext.$config.matomo.apiToken,
            idSite: nuxtContext.$config.matomo.siteId,
            idExperiment: id
          }
        })
        .then((response: AxiosResponse<MatomoAbTestingGetExperimentResponse>) => response.data)

      return {
        name: id,
        percentage: experimentConfig.percentage_participants,
        includedTargets: experimentConfig.included_targets,
        excludedTargets: experimentConfig.excluded_targets,
        variations: experimentConfig.variations
      }
    }

    const experiment = useAsync(() => {
      return getExperimentConfigById(props.blok.experiment_id)
    }, 'abtest' + props.blok.experiment_id)
    const pushTest = () => {
      const MatomoExperiment = Matomo.AbTesting.Experiment
      const variations = [
        {
          name: 'original',
          activate() {
            activeTest.value = 'original'
          }
        }
      ]

      if (experiment.value && experiment.value.variations) {
        experiment.value.variations.forEach((variation) => {
          variations.push({
            name: variation.idvariation,
            ...(variation.percentage && { percentage: variation.percentage }),
            activate: (event?: any) => {
              variationIds.push(variation.idvariation)
              if (variation.redirect_url) {
                event.redirect(variation.redirect_url)
              }
              activeTest.value = variation.idvariation
            }
          })
        })
        if (nuxtContext.isDev) {
          window._paq.push(['AbTesting::enableDebugMode'])
        }
        const newExperiment = new MatomoExperiment({
          ...experiment.value,
          variations,
          trigger() {
            return true // here you can further customize which of your visitors will participate in this experiment
          }
        })
        if (newExperiment.getActivatedVariationName() === null) {
          activeTest.value = 'original'
        }
      }
    }

    onMounted(async () => {
      if (props.blok.experiment_id && !experiment.value) {
        experiment.value = await getExperimentConfigById(props.blok.experiment_id)
      }
      if (!isStoryblokEdit.value) {
        if (typeof Piwik === 'object' && typeof Matomo.AbTesting === 'object') {
          // if matomo.js was embedded before this code
          pushTest()
        } else {
          // if matomo.js is loaded after this code
          window.matomoAbTestingAsyncInit = pushTest
        }
      }
      if (typeof Matomo === 'object') {
        matomoLoaded.value = Matomo.initialized
      }
      setTimeout(() => {
        if (activeTest.value === '') {
          activeTest.value = 'original'
        }
        matomoLoaded.value = true
      }, 2000)
      if (nuxtContext.$config.dev && window.location !== window.parent.location) {
        isStoryblokEdit.value = true
      }
    })

    return {
      experiment,
      classes,
      dashify,
      activeTest,
      variationIds,
      isStoryblokEdit,
      matomoLoaded
    }
  }
})
