<template>
  <client-only>
    <div v-swiper="swiperOption" class="relative w-5/6 ml-auto" :loadtheme="false">
      <div class="swiper-wrapper">
        <div v-for="(game, index) in shuffledGames" :key="index" class="swiper-slide">
          <div class="w-full relative rounded-lg sahdow-lg overflow-hidden block cursor-pointer">
            <img class="object-center object-cover h-80 w-full" :src="game[1]" alt="game" />
            <div class="text-center py-2 bg-[#00000057] backdrop-filter backdrop-blur-xl absolute w-full bottom-0 flex">
              <img
                class="relative w-32 h-24 left-2 object-center object-cover !rounded-lg overflow-hidden"
                :src="game[3][0]"
                alt="coach"
              />
              <div class="relative ml-4 pb-5 md:pb-0 text-left">
                <h5 class="font-bold mb-2 text-white italic">{{ game[2] }}</h5>
                <p class="!text-gray-200 font-bold text-xl">{{ game[3][1] }}</p>
                <p class="!text-gray-200 font-normal">{{ game[0] }} Coach</p>
              </div>
              <p class="!text-primary-500 absolute right-2 bottom-2 text-right">
                {{ blok.is_English ? `${game[4]} minutes ago` : `vor ${game[4]} Minuten` }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div slot="button-prev" class="swiper-button-prev"></div>
      <div slot="button-next" class="swiper-button-next"></div>
    </div>
  </client-only>
</template>

<script>
export default {
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const swiperOption = {
      slidesPerView: 2,
      slidesPerGroup: 2,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        1024: {
          slidesPerView: 2,
          spaceBetween: 50
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        }
      }
    }

    const shuffle = (arr) => {
      return arr.sort(() => 0.5 - Math.random())
    }

    const randNum = () => {
      return Math.floor(Math.random() * (59 - 1) + 1)
    }

    const fifaCoaches = [
      ['https://a.storyblok.com/f/112666/1080x1080/2077f256ef/stylo.png', 'Stylo'],
      ['https://a.storyblok.com/f/112666/1080x1080/df7c6ea865/jeffry.png', 'Jeffryy'],
      ['https://a.storyblok.com/f/112666/1080x1080/2f22f1bff7/bono.png', 'Bono'],
      ['https://a.storyblok.com/f/112666/1080x1080/c2846f8fdb/basti24hd.png', 'Basti24HD'],
      ['https://a.storyblok.com/f/112666/1080x1080/e14f90dbaa/nealguides.png', 'NealGuides'],
      ['https://a.storyblok.com/f/112666/1080x1080/e8ffae952c/mike-labelle.png', 'Mike LaBelle']
    ]

    const fortniteCoaches = [
      ['https://a.storyblok.com/f/112666/1080x1080/9a8b0be293/unknown-fortnite-coach.png', 'Malte'],
      ['https://a.storyblok.com/f/112666/1080x1080/9a8b0be293/unknown-fortnite-coach.png', 'Sharko'],
      ['https://a.storyblok.com/f/112666/1080x1080/9a8b0be293/unknown-fortnite-coach.png', 'Hizir'],
      ['https://a.storyblok.com/f/112666/1080x1080/9a8b0be293/unknown-fortnite-coach.png', 'Lewis'],
      ['https://a.storyblok.com/f/112666/1080x1080/9a8b0be293/unknown-fortnite-coach.png', 'Naoki'],
      ['https://a.storyblok.com/f/112666/1080x1080/9a8b0be293/unknown-fortnite-coach.png', 'CloudFN']
    ]

    const lolCoaches = [
      ['https://a.storyblok.com/f/112666/1080x1080/9c08b3be67/ikkine.png', 'Ikkine'],
      ['https://a.storyblok.com/f/112666/1080x1080/546aae1197/predox.png', 'NiceGuyBen'],
      ['https://a.storyblok.com/f/112666/1080x1080/08654986e5/lastangel.png', 'LastAngel'],
      ['https://a.storyblok.com/f/112666/1080x1080/546aae1197/predox.png', 'NiceGuyBen'],
      ['https://a.storyblok.com/f/112666/1080x1080/2e17bb0bca/outlandisch1-new.png', 'Outlandisch'],
      ['https://a.storyblok.com/f/112666/1080x1080/15c0c08189/sarg.png', 'Sarg']
    ]

    const rocketLeagueCoaches = [
      ['https://a.storyblok.com/f/112666/1080x1080/9958bb4c30/theezy.png', 'Theezy'],
      ['https://a.storyblok.com/f/112666/1080x1080/be30686289/jan.png', 'Jan']
    ]

    const valorantCoaches = [
      ['https://a.storyblok.com/f/112666/1079x788/8709e9e394/hightkitty.png', 'HighKitty'],
      ['https://a.storyblok.com/f/112666/1079x786/c0f6ab1638/mts-coach.png', 'mts'],
      ['https://a.storyblok.com/f/112666/1080x742/f7092f7bbf/nitro.png', 'Nitro'],
      ['https://a.storyblok.com/f/112666/1079x762/4f70f5f325/knightvoice.png', 'KnightVoice'],
      ['https://a.storyblok.com/f/112666/1080x1080/2889fb4a1c/reyna.png', 'NOM Freyy']
    ]

    const shuffledFifaCoaches = shuffle(fifaCoaches)
    const shuffledFortniteCoaches = shuffle(fortniteCoaches)
    const shuffledLolCoaches = shuffle(lolCoaches)
    const shuffledRocketLeagueCoaches = shuffle(rocketLeagueCoaches)
    const shuffledValorantCoaches = shuffle(valorantCoaches)

    const games = [
      [
        'FIFA',
        'https://a.storyblok.com/f/112666/1920x1080/fa2f22965a/fifa-22-mm.jpg',
        'BASIC OFFENSE',
        shuffledFifaCoaches[0],
        randNum()
      ],
      [
        'FIFA',
        'https://a.storyblok.com/f/112666/1920x1080/fa2f22965a/fifa-22-mm.jpg',
        'BASIC DEFENSE',
        shuffledFifaCoaches[1],
        randNum()
      ],
      [
        'FIFA',
        'https://a.storyblok.com/f/112666/1920x1080/fa2f22965a/fifa-22-mm.jpg',
        'META',
        shuffledFifaCoaches[2],
        randNum()
      ],
      [
        'Fortnite',
        'https://a.storyblok.com/f/112666/1024x1024/6f7d1f1f50/fortnite-cover.jpeg',
        '1ON1 COACHING',
        shuffledFortniteCoaches[0],
        randNum()
      ],
      [
        'Fortnite',
        'https://a.storyblok.com/f/112666/1024x1024/6f7d1f1f50/fortnite-cover.jpeg',
        '1ON1 COACHING',
        shuffledFortniteCoaches[1],
        randNum()
      ],
      [
        'Fortnite',
        'https://a.storyblok.com/f/112666/1024x1024/6f7d1f1f50/fortnite-cover.jpeg',
        '1ON1 COACHING',
        shuffledFortniteCoaches[2],
        randNum()
      ],
      [
        'Rocket League',
        'https://a.storyblok.com/f/112666/1900x1583/e90189a2bc/rocket_menu_0422.jpg',
        '1ON1 COACHING',
        shuffledRocketLeagueCoaches[0],
        randNum()
      ],
      [
        'Rocket League',
        'https://a.storyblok.com/f/112666/1900x1583/e90189a2bc/rocket_menu_0422.jpg',
        '1ON1 COACHING',
        shuffledRocketLeagueCoaches[1],
        randNum()
      ],
      [
        'Valorant',
        'https://a.storyblok.com/f/112666/1900x1583/07787ab3cf/valorant_menu_0422.jpg',
        '1ON1 COACHING',
        shuffledValorantCoaches[0],
        randNum()
      ],
      [
        'Valorant',
        'https://a.storyblok.com/f/112666/1900x1583/07787ab3cf/valorant_menu_0422.jpg',
        '1ON1 COACHING',
        shuffledValorantCoaches[1],
        randNum()
      ],
      [
        'Valorant',
        'https://a.storyblok.com/f/112666/1900x1583/07787ab3cf/valorant_menu_0422.jpg',
        '1ON1 COACHING',
        shuffledValorantCoaches[2],
        randNum()
      ],
      [
        'LOL',
        'https://a.storyblok.com/f/112666/1920x1080/8dac04d51e/loool.jpg',
        'LANE MANIPULATION',
        shuffledLolCoaches[0],
        randNum()
      ],
      [
        'LOL',
        'https://a.storyblok.com/f/112666/1920x1080/8dac04d51e/loool.jpg',
        'JUNGLE TRACKING',
        shuffledLolCoaches[1],
        randNum()
      ],
      [
        'LOL',
        'https://a.storyblok.com/f/112666/1920x1080/8dac04d51e/loool.jpg',
        'DECISION MAKING',
        shuffledLolCoaches[2],
        randNum()
      ]
    ]

    const shuffledGames = shuffle(games)

    return {
      swiperOption,
      shuffledGames
    }
  }
}
</script>
