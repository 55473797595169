



















































import { defineComponent, ref } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'

export default defineComponent({
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)
    const isOpen = ref(props.blok.isOpen)
    const toggleItem = () => {
      isOpen.value = !isOpen.value
    }

    return {
      classes,
      dashify,
      isOpen,
      toggleItem
    }
  }
})
