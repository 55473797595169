<template>
  <div class="group col-span-4 order-6 md:order-none mr-3 relative w-full">
    <button
      aria-haspopup="true"
      aria-controls="menu"
      :class="[
        'focus:outline-none px-2 py-2 rounded flex justify-between min-w-32 w-full text-gray-800 border-2 border-gray-500 mb-2'
      ]"
      @click="isToggled = true"
    >
      <span class="pr-1 text-gray-400 text-l">{{ placeholder !== '' ? placeholder : '...' }}</span>
      <span>
        <svg
          :class="[
            'fill-[#c7f201] h-6 w-6 transform transition duration-150 ease-in-out',
            isToggled === true && 'group-hover:-rotate-180'
          ]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </span>
    </button>
    <div
      id="menu"
      aria-hidden="true"
      :class="[
        'bg-gray-800 border-gray-700 border-2 rounded transform scale-0 absolute transition duration-150 ease-in-out origin-top min-w-32 w-full z-50 -mt-2 px-2 py-2 flex flex-col gap-y-2',
        isToggled === true && 'group-hover:scale-100'
      ]"
    >
      <Checkbox
        v-for="item in tagsOfType"
        :key="item.id"
        :item="item"
        :show-property="'name'"
        :value-property="'id'"
        :query-parameter="'tags'"
        @check="select(item)"
      />
    </div>
  </div>
</template>

<script>
import { ref, useRoute, useRouter, toRefs, computed } from '@nuxtjs/composition-api'
import Checkbox from '~/components/calendar/Filter/Checkbox'
import useFilters from '~/composables/useFilters'

export default {
  components: { Checkbox },
  props: {
    type: { type: String, default: () => null },
    multiselect: { type: Boolean, default: () => false }
  },
  setup(props) {
    const { type, multiselect } = toRefs(props)
    const route = useRoute()
    const router = useRouter()
    const isToggled = ref(true)
    const { tags, activeTags, query } = useFilters()

    const select = (selected) => {
      const otherActives = activeTags.value
        .filter(function (obj) {
          return obj.type !== type.value
        })
        .map((tag) => tag.id)

      let ownActives = activeTags.value
        .filter(function (obj) {
          return obj.type === type.value
        })
        .map((tag) => tag.id)

      if (ownActives.includes(selected.id)) {
        const index = ownActives.indexOf(selected.id)
        if (index > -1) {
          ownActives.splice(index, 1)
        }
      } else if (multiselect.value) {
        ownActives.push(selected.id)
      } else {
        ownActives = [selected.id]
      }
      const actives = [...otherActives, ...ownActives]

      query.tags = actives.length > 0 ? actives.join() : null

      router.push({
        path: route.value.path,
        query
      })
    }

    const tagsOfType = computed(() => {
      return tags.value.filter(function (obj) {
        return obj.type === type.value
      })
    })

    const placeholder = computed(() => {
      return activeTags.value
        .filter(function (obj) {
          return obj.type === type.value
        })
        .map((tag) => tag.name)
        .toString()
    })

    return {
      placeholder,
      query,
      select,
      isToggled,
      tags,
      tagsOfType,
      activeTags
    }
  }
}
</script>
