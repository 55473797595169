import { Model } from '@vuex-orm/core'
import Appointment from '~/models/Appointment'
import AppointmentRoom from '~/models/AppointmentRoom'
import User from '~/models/User'

export default class Calendar extends Model {
  static entity = 'calendars'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      userID: this.attr(null),
      user: this.belongsTo(User, 'userID'),
      appointments: this.hasMany(Appointment, 'calendarID'),
      appointmentRooms: this.hasMany(AppointmentRoom, 'calendarID')
    }
  }

  id!: number
  name!: string
  userID!: number
  user!: User | null
}
