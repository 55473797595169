











import { defineComponent, PropType, inject, computed } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import { unixDate } from '@/utils/date'

export interface GaProgressBar extends StoryblokComponent<'progress-bar'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  date: string
  text_color: string
  shimmer: boolean
  destination_time: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaProgressBar>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const injectedTime = inject('destination_time')
    const countdownTo = computed(() => {
      return injectedTime ? unixDate(injectedTime) : unixDate(props.blok.date)
    })
    const remain = Math.round(countdownTo.value - Date.now() / 1000)
    const duration = 88000 // 24h
    const percentage = remain > duration ? '100%' : `${Math.round((remain / duration) * 100)}%`

    return {
      classes,
      dashify,
      unixDate,
      percentage
    }
  }
})
