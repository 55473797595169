
































































































































































































































































































































import { defineComponent, computed, useContext, PropType } from '@nuxtjs/composition-api'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface Payment extends StoryblokComponent<'Payment'> {
  content: StoryblokComponent<any>[]
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<Payment>,
      default: () => {}
    }
  },
  setup() {
    const { store, $dayjs } = useContext()
    const event = computed(() => store.state.calendar.event)

    function formatDate(date) {
      const { time, duration } = date
      const from = $dayjs(time).format('HH:mm')
      const to = $dayjs(time).add(duration, 'm').format('HH:mm')
      const dateTimeFormat = $dayjs(time).format('dddd, MMMM DD, YYYY')
      return `${from} - ${to}, ${dateTimeFormat}`
    }

    function close() {
      store.commit('calendar/setEvent', {})
    }

    return {
      event,
      close,
      formatDate
    }
  }
})
