












import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaLanguageItems extends StoryblokComponent<'language-items'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaLanguageItems>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const availableLocales = computed(() => {
      return nuxtContext.i18n.locales.filter((i) => i.code !== nuxtContext.i18n.locale)
    })

    return {
      classes,
      dashify,
      availableLocales
    }
  }
})
