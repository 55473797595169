import { Model } from '@vuex-orm/core'

export default class Product extends Model {
  static entity = 'products'

  static fields() {
    return {
      id: this.uid(),
      title: this.string(''),
      sku: this.string(''),
      price: this.number(0),
      plan_feature_tag: this.string('')
    }
  }

  title!: string
  sku!: string
  price!: number
  plan_feature_tag!: string
}
