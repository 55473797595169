






import { defineComponent, PropType, useAsync, useContext } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent, StoryData } from '~/types/storyblok-vue'
import useStoryblok from '~/utils/storyblok'
import BlogItem from '~/components/blog/BlogItem.vue'

export interface GaBlogSmallOverview extends StoryblokComponent<'blog-small-overview'> {
  blog_style: StoryblokComponent<any>[]
  custom_classes: string
  number: number
}

export default defineComponent({
  components: { BlogItem },
  props: {
    blok: {
      type: Object as PropType<GaBlogSmallOverview>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const { getStories } = useStoryblok()
    const nuxtContext = useContext()
    const classes = concatClasses(props.blok)

    const blogs = useAsync(async () => {
      if (nuxtContext.payload) {
        const filter = new RegExp(`${nuxtContext.i18n.locale}/blog`)
        return nuxtContext.payload.latestBlogs.filter((blog: StoryData) => filter.test(blog.full_slug))
      }
      return await getStories(nuxtContext, {
        by_slugs: `${nuxtContext.i18n.locale}/blog/articles/*`,
        resolve_relations: 'blog-page.author,blog-page.categories',
        sort_by: 'created_at:desc',
        per_page: props.blok.number ? props.blok.number : 3
      })
    }, nuxtContext.i18n.locale + 'smallOverview')

    return {
      classes,
      dashify,
      blogs
    }
  }
})
