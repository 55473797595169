



































































import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import { Calendar } from '~/components/calendar/Calendar1on1.vue'
import useFilters from '~/composables/useFilters'

export default defineComponent({
  props: {
    date: { type: String, default: () => '' },
    roomsByTime: { type: Object, default: () => {} },
    blok: {
      type: Object as PropType<Calendar>,
      default: () => {}
    }
  },
  setup(props) {
    const { hasSlots, isBooked } = useFilters()
    const nuxtContext = useContext()
    const availableSessions = computed(() =>
      Object.values(props.roomsByTime)
        .map((room) => room.length)
        .reduce((a, b) => a + b, 0)
    )

    const noLongerBookable = (time: any) => {
      if (props.blok.no_longer_bookable_in_hours === 0 || !props.blok.no_longer_bookable_in_hours) {
        return false
      }
      return (
        nuxtContext.$dayjs(time).diff(nuxtContext.$dayjs(), 'hour') <= parseInt(props.blok.no_longer_bookable_in_hours)
      )
    }

    const notYetBookable = (time: any) => {
      if (props.blok.bookable_period_in_days === 0) {
        return false
      }
      return nuxtContext.$dayjs(time).diff(nuxtContext.$dayjs(), 'day') > parseInt(props.blok.bookable_period_in_days)
    }

    return {
      isBooked,
      hasSlots,
      availableSessions,
      notYetBookable,
      noLongerBookable
    }
  }
})
