import Vue from 'vue'

import vSelect from 'vue-select'
import Page from '~/components/page/Page.vue'
import AuthorPage from '~/components/page/AuthorPage.vue'
import CategoryPage from '~/components/page/CategoryPage.vue'
import MemberPage from '~/components/page/MemberPage.vue'
import GuestPage from '~/components/page/GuestPage.vue'
import BlogsLandingPage from '~/components/page/BlogsLandingPage.vue'
import VideoCategoryPage from '~/components/page/VideoCategoryPage.vue'
import BlogPage from '~/components/page/BlogPage.vue'
import VideoPage from '~/components/page/VideoPage.vue'
import BlogSmallOverview from '~/components/blog/SmallOverview.vue'
// import Grid from '~/components/grid/Grid.vue'
import Link from '~/components/link/Link.vue'
import Container from '~/components/container/Container.vue'
import Image from '~/components/image/Image.vue'
import Settings from '~/components/settings/Settings.vue'
import Footer from '~/components/footer/footer.vue'
import Header from '~/components/header/header.vue'
import LandingPage from '~/components/landingpage/Landingpage.vue'
import Text from '~/components/text/Text.vue'
import SimpleText from '~/components/text/SimpleText.vue'
import Navigation from '~/components/navigation/Navigation.vue'
import NavigationSimple from '~/components/navigation/NavigationSimple.vue'
import NavigationDesktop from '~/components/navigation/NavigationDesktop.vue'
import NavigationMobile from '~/components/navigation/NavigationMobile.vue'
import Dropdown from '~/components/dropdown/Dropdown.vue'
import DropdownItems from '~/components/dropdown/DropdownItems.vue'
import Button from '~/components/button/Button.vue'
import Calendar from '~/components/calendar/Calendar.vue'
import Calendar1on1 from '~/components/calendar/Calendar1on1.vue'
import CalendarGroup from '~/components/calendar/CalendarGroup.vue'
import Pagination from '~/components/calendar/Pagination.vue'
import FontAwesome from '~/components/icon/FontAwesome.vue'
import Icon from '~/components/icon/Icon.vue'
import Form from '~/components/form/Form.vue'
import Input from '~/components/input/Input.vue'
import Textarea from '~/components/textarea/Textarea.vue'
import Toggle from '~/components/toggle/Toggle.vue'
import HasScopeEffect from '~/components/effect/HasScopeEffect.vue'
import ShowHideEffect from '~/components/effect/ShowHideEffect.vue'
import DescriptionList from '~/components/list/DescriptionList.vue'
import Svg from '~/components/svg/Svg.vue'
import SvgElement from '~/components/svg/SvgElement.vue'
import List from '~/components/list/List.vue'
import ListItem from '~/components/list/ListItem.vue'
import Aside from '~/components/aside/Aside.vue'
import Section from '~/components/section/Section.vue'
import Slider from '~/components/slider/Slider.vue'
import SliderMultigame from '~/components/slider/SliderMultigame.vue'
import Carousel from '~/components/slider/Carousel.vue'
import Table from '~/components/table/Table.vue'
import AbTest from '~/components/abTest/AbTest.vue'
import VideoItem from '~/components/blog/VideoItem.vue'
import Vimeo from '~/components/vimeo/Vimeo.vue'
import Tabs from '~/components/tabs/Tabs.vue'
import Tab from '~/components/tabs/Tab.vue'
import Accordion from '~/components/accordion/Accordion.vue'
import AccordionItem from '~/components/accordion/AccordionItem.vue'
import TableWrapper from '~/components/table/TableWrapper.vue'
import Modal from '~/components/modal/Modal.vue'
import ContainerEditable from '~/components/container/ContainerEditable.vue'
import BlogItem from '~/components/blog/BlogItem.vue'
import CoachingContainer from '~/components/blog/CoachingContainer.vue'
import LanguageItems from '~/components/dropdown/LanguageItems.vue'
import ColorSwitch from '~/components/effect/ColorSwitch.vue'
import Tweet from '~/components/social/Tweet.vue'
import TableItem from '~/components/table/TableItem.vue'
import Payment from '~/components/checkout/Payment.vue'
import Confirmation from '~/components/checkout/Confirmation.vue'
import CancelAppointmentModal from '~/components/calendar/CancelAppointmentModal.vue'
import Cancellation from '~/components/calendar/Cancellation.vue'
import Appointments from '~/components/calendar/Appointments.vue'
import Appointment from '~/components/calendar/Appointment.vue'
import AppointmentRoom from '~/components/calendar/AppointmentRoom.vue'
import OrderSummary from '~/components/calendar/OrderSummary.vue'
import CountdownBar from '~/components/calendar/CountDownBar.vue'
import Countdown from '~/components/calendar/CountDown.vue'
import ProgressBar from '~/components/calendar/ProgressBar.vue'
import ProgressBarEffect from '~/components/effect/ProgressBarEffect.vue'
import Checkbox from '~/components/checkbox/Checkbox.vue'
import Banner from '~/components/banner/Banner.vue'
import RadioItem from '~/components/radioGroup/RadioItem.vue'
import RadioGroup from '~/components/radioGroup/RadioGroup.vue'
import ProvideStoreDataEffect from '~/components/effect/ProvideStoreDataEffect.vue'
import CoachingSchedule from '~/components/coaching/Schedule.vue'
import CoachingOverview from '~/components/coaching/index.vue'
import MessageUsers from '~/components/coaching/MessageUsers.vue'
import RedirectEffect from '~/components/effect/RedirectEffect.vue'
import CheckSubscriptionEffect from '~/components/effect/CheckSubscriptionEffect.vue'
import ReplaceDataEffect from '~/components/effect/ReplaceDataEffect.vue'
import ModalTemplate from '~/components/modal/ModalTemplate.vue'
import ActionEffect from '~/components/effect/ActionEffect.vue'
import TemplateLoaderEffect from '~/components/effect/TemplateLoaderEffect.vue'
import Repeatable from '~/components/list/Repeatable.vue'
import ItemConditionEffect from '~/components/effect/ItemConditionEffect.vue'
import SkillCorridor from '~/components/skillCorridor/SkillCorridor.vue'
import VideoBackground from '~/components/videoBackground/VideoBackground.vue'
import Steps from '~/components/steps/Steps.vue'
import GetItemEffect from '~/components/effect/GetItemEffect.vue'
import SkillTags from '~/components/skillTags/SkillTags.vue'
import StyleConditionEffect from '~/components/effect/StyleConditionEffect.vue'
import FetchUserEffect from '~/components/effect/FetchUserEffect.vue'
import ProvideValueEffect from '~/components/effect/ProvideValueEffect.vue'
import Select from '~/components/select/Select.vue'
import SkillTagsVoting from '~/components/skillTags/SkillTagsVoting.vue'
import Breadcrumb from '~/components/breadcrumb/breadcrumb.vue'
import PrefetchManyStoreEffect from '~/components/effect/PrefetchManyStoreEffect.vue'
import GameRank from '~/components/gameRank/GameRank.vue'
import ReloadTriggerEffect from '~/components/effect/ReloadTriggerEffect.vue'
import Step from '~/components/steps/Step.vue'
import ProvideStoreDataEffectOldVersion from '~/components/effect/ProvideStoreDataEffectOldVersion.vue'
import Count from '~/components/count/Count.vue'
import SkillStatistics from '~/components/skillStatistics/SkillStatistics.vue'
import GridTile from '~/components/gridTile/GridTile.vue'
import CalendarSheet from '~/components/calendarSheet/CalendarSheet.vue'
import LanguageFlag from '~/components/languageFlag/LanguageFlag.vue'
import OrderSummaryNew from '~/components/calendar/New/OrderSummaryNew.vue'
import MultigameRedirectEffect from '~/components/effect/MultigameRedirectEffect.vue'

Vue.component('VSelect', vSelect)

Vue.component('BlokAuthor', AuthorPage)
Vue.component('BlokCategory', CategoryPage)
Vue.component('BlokMemberPage', MemberPage)
Vue.component('BlokGuestPage', GuestPage)
Vue.component('BlokBlogsLandingPage', BlogsLandingPage)
Vue.component('BlokBlogPage', BlogPage)
Vue.component('BlokVideoCategory', VideoCategoryPage)
Vue.component('BlokVideoPage', VideoPage)

Vue.component('BlokPage', Page)
Vue.component('BlokBlankPage', Page)
Vue.component('BlokMultigamePage', Page)
Vue.component('BlokFifaPage', Page)
Vue.component('BlokFortnitePage', Page)
Vue.component('BlokLolPage', Page)
Vue.component('BlokRocketPage', Page)
Vue.component('BlokValorantPage', Page)
Vue.component('BlokDotaPage', Page)
Vue.component('BlokWarzonePage', Page)
Vue.component('BlokCsgoPage', Page)
Vue.component('BlokApexPage', Page)

Vue.component('BlokBlogSmallOverview', BlogSmallOverview)
Vue.component('BlokBlogItem', BlogItem)
Vue.component('CoachingContainer', CoachingContainer)

Vue.component('BlokText', Text)
Vue.component('BlokHeading', SimpleText)
Vue.component('BlokSimpleText', SimpleText)
Vue.component('BlokLink', Link)
Vue.component('BlokImage', Image)
Vue.component('BlokSettings', Settings)
Vue.component('BlokHeader', Header)
Vue.component('BlokFooter', Footer)
Vue.component('BlokLandingpage', LandingPage)
Vue.component('BlokAbTest', AbTest)

Vue.component('BlokNavigation', Navigation)
Vue.component('BlokNavigationDesktop', NavigationDesktop)
Vue.component('BlokNavigationMobile', NavigationMobile)
Vue.component('BlokNavigationSimple', NavigationSimple)
Vue.component('BlokBreadcrumb', Breadcrumb)

Vue.component('BlokHasScopeEffect', HasScopeEffect)
Vue.component('BlokActionEffect', ActionEffect)
Vue.component('BlokItemConditionEffect', ItemConditionEffect)
Vue.component('BlokTemplateLoaderEffect', TemplateLoaderEffect)
Vue.component('BlokShowHideEffect', ShowHideEffect)
Vue.component('BlokProvideStoreDataEffect', ProvideStoreDataEffect)
Vue.component('BlokProvideStoreDataEffectOldVersion', ProvideStoreDataEffectOldVersion)
Vue.component('BlokReplaceDataEffect', ReplaceDataEffect)
Vue.component('BlokRedirectEffect', RedirectEffect)
Vue.component('BlokCheckSubscriptionEffect', CheckSubscriptionEffect)
Vue.component('BlokColorSwitch', ColorSwitch)
Vue.component('BlokGetItemEffect', GetItemEffect)
Vue.component('BlokStyleConditionEffect', StyleConditionEffect)
Vue.component('BlokFetchUserEffect', FetchUserEffect)
Vue.component('BlokProvideValueEffect', ProvideValueEffect)
Vue.component('BlokPrefetchManyStoreEffect', PrefetchManyStoreEffect)
Vue.component('BlokReloadTriggerEffect', ReloadTriggerEffect)
Vue.component('BlokMultigameRedirectEffect', MultigameRedirectEffect)

Vue.component('BlokDropdown', Dropdown)
Vue.component('BlokDropdownItems', DropdownItems)
Vue.component('BlokLanguageItems', LanguageItems)

Vue.component('BlokButton', Button)
Vue.component('BlokCalendar', Calendar)
Vue.component('BlokCalendar1on1', Calendar1on1)
Vue.component('BlokCalendarGroup', CalendarGroup)
Vue.component('BlokPagination', Pagination)
Vue.component('BlokModal', Modal)
Vue.component('BlokModalTemplate', ModalTemplate)
Vue.component('BlokForm', Form)
Vue.component('BlokInput', Input)
Vue.component('BlokTextarea', Textarea)
Vue.component('BlokToggle', Toggle)
Vue.component('BlokCheckbox', Checkbox)
Vue.component('BlokRadioGroup', RadioGroup)
Vue.component('BlokRadioItem', RadioItem)
Vue.component('BlokSelect', Select)

Vue.component('BlokContainer', Container)
Vue.component('BlokFeatureSection', ContainerEditable)
Vue.component('BlokLogoCloud', ContainerEditable)
// Vue.component('BlokBanner', ContainerEditable)
Vue.component('BlokTextBlock', Container)
Vue.component('BlokHero', ContainerEditable)
Vue.component('BlokHeading', Container)
Vue.component('BlokComponentTemplate', Container)
Vue.component('BlokGrid', ContainerEditable)
Vue.component('BlokGridChild', Container)
Vue.component('BlokTestimonial', ContainerEditable)
Vue.component('BlokTeam', ContainerEditable)
Vue.component('BlokCard', ContainerEditable)
Vue.component('BlokStats', ContainerEditable)
Vue.component('BlokFaq', ContainerEditable)
Vue.component('BlokAlert', ContainerEditable)
Vue.component('BlokSteps', Steps)
Vue.component('BlokStep', Step)
Vue.component('BlokCount', Count)
Vue.component('BlokGridTile', GridTile)
Vue.component('BlokCalendarSheet', CalendarSheet)
Vue.component('BlokLanguageFlag', LanguageFlag)

Vue.component('BlokAside', Aside)
Vue.component('BlokSection', Section)
Vue.component('BlokSlider', Slider)
Vue.component('BlokSliderMultigame', SliderMultigame)
Vue.component('BlokCarousel', Carousel)
Vue.component('BlokTable', TableWrapper)
Vue.component('BlokTableItem', Table)
Vue.component('BlokTableItemCustom', TableItem)
Vue.component('BlokAppointments', Appointments)
Vue.component('BlokAppointment', Appointment)
Vue.component('BlokAppointmentRoom', AppointmentRoom)
Vue.component('BlokOrderSummary', OrderSummary)
Vue.component('BlokOrderSummaryNew', OrderSummaryNew)
Vue.component('BlokCancelAppointmentModal', CancelAppointmentModal)
Vue.component('BlokCountdownBar', CountdownBar)
Vue.component('BlokCountdown', Countdown)
Vue.component('BlokProgressBar', ProgressBar)
Vue.component('BlokProgressBarEffect', ProgressBarEffect)

Vue.component('BlokDescriptionList', DescriptionList)
Vue.component('BlokList', List)
Vue.component('BlokRepeatable', Repeatable)
Vue.component('BlokListItem', ListItem)

Vue.component('Fa', FontAwesome)
Vue.component('BlokIcon', Icon)
Vue.component('BlokSvg', Svg)
Vue.component('BlokSvgElement', SvgElement)
Vue.component('BlokBanner', Banner)
Vue.component('BlokSkillCorridor', SkillCorridor)
Vue.component('BlokSkillTags', SkillTags)
Vue.component('BlokSkillTagsVoting', SkillTagsVoting)
Vue.component('BlokGameRank', GameRank)
Vue.component('BlokSkillStatistics', SkillStatistics)

Vue.component('BlokCoachingSchedule', CoachingSchedule)
Vue.component('BlokCoachingOverview', CoachingOverview)
Vue.component('BlokMessageUsers', MessageUsers)

// Vue.component('BlokToast', Toast)

Vue.component('BlokBlogItem', BlogItem)
Vue.component('BlokVimeo', Vimeo)
Vue.component('BlokTabs', Tabs)
Vue.component('BlokTab', Tab)

Vue.component('BlokAccordion', Accordion)
Vue.component('BlokAccordionItem', AccordionItem)

Vue.component('BlokTweet', Tweet)
Vue.component('BlokPayment', Payment)
Vue.component('BlokConfirmation', Confirmation)
Vue.component('BlokCancellation', Cancellation)

Vue.component('BlokVideoBackground', VideoBackground)
