






























import {
  defineComponent,
  ref,
  provide,
  reactive,
  useContext,
  useRouter,
  PropType,
  useStore,
  inject,
  unref,
  ComputedRef,
  useRoute
} from '@nuxtjs/composition-api'
import { ValidationObserver } from 'vee-validate'
import { AxiosResponse, Method } from 'axios'
import get from 'get-value'
import useHelper from '~/utils/helper'
import { StoryblokComponent, StoryblokLink } from '~/types/storyblok-vue'
import User from '~/models/User'
import useHandleResponses from '~/components/form/useHandleResponses'
import SubscriptionPlan from '~/models/SubscriptionPlan'

export interface GaForm extends StoryblokComponent<'form'> {
  content: StoryblokComponent<any>[]
  success_append_content: StoryblokComponent<any>[]
  success_redirect: StoryblokLink
  cancel_redirect: StoryblokLink
  append_success_redirect: boolean
  append_cancel_redirect: boolean
  track_successful_submit: boolean
  use_formdata_for_tracking: boolean
  inject_datamodel: boolean
  inject_datamodel_with_user: boolean
  login_after_submit: boolean
  add_user_language: boolean
  name: string
  category: string
  custom_classes: string
  data_model: string
  inject_datamodel_with_user_by_prop: string
  form_type: string
  submit_url: string
  submit_method: Method | undefined
  route_with_data_model_param: string
  success_toast_message: string
  response_trigger: string
  success_reload: boolean
}

export default defineComponent({
  name: 'GaForm',
  components: {
    ValidationObserver
  },
  props: {
    blok: {
      type: Object as PropType<GaForm>,
      default: () => {}
    }
  },
  setup(props, context) {
    const { dashify, concatClasses, stringToJson } = useHelper()
    const classes = concatClasses(props.blok)
    const handleResponses = useHandleResponses()
    const nuxtContext = useContext()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const model = props.blok.data_model
    let dataModel = reactive(stringToJson(model))
    const success = ref(false)
    const isSubmitting = ref(false)
    const isSubmitDisabled = ref(false)
    const isBot = ref(false)
    const realFullName = ref()
    const vueForm = ref()
    const userRepo = store.$repo(User)
    const axios = nuxtContext.$axios.create({
      withCredentials: true,
      baseURL: nuxtContext.$config.middlewareApi
    })

    const userData: ComputedRef<User> | undefined = inject('userData')

    const user = unref(userData)
    const item = inject('item', null)

    if (user && props.blok.inject_datamodel_with_user) {
      if (props.blok.inject_datamodel_with_user_by_prop) {
        dataModel = get(user, props.blok.inject_datamodel_with_user_by_prop)
      } else {
        dataModel = reactive(user)
      }
    } else if (item) {
      dataModel = item
    }

    if (props.blok.inject_datamodel) {
      const injectData = inject('dataModel', dataModel, true)
      dataModel = reactive({
        ...stringToJson(model),
        ...unref(injectData)
      })
    }

    provide('dataModel', dataModel)
    provide('isSubmitting', isSubmitting)
    provide('form', vueForm)
    provide('isSubmitDisabled', isSubmitDisabled)

    const login = async () => {
      return await nuxtContext.$auth
        .loginWith('laravelSanctum', {
          data: dataModel
        })
        .then(async () => {
          const userData = await axios.get('/api/user').then((response) => response.data.data)
          if (userData) {
            nuxtContext.$auth.setUser(userData.id)
            window._paq.push(['setUserId', userData.id + '-' + userData.name])
            userRepo.save(userData)
          }
          success.value = true

          return userData
        })
        .catch((error) => {
          if (error.response) {
            nuxtContext.$toast.error(error.response.data.message)
          } else {
            console.error(error)
          }
        })
    }

    const postData = () => {
      const data = {
        ...dataModel,
        matomoVisitorId: Matomo.getTracker().getVisitorId(),
        ...(props.blok.append_success_redirect && {
          redirectUrlSuccess: nuxtContext.$config.api + '/' + props.blok.success_redirect.cached_url
        }),
        ...(props.blok.append_cancel_redirect && {
          redirectUrlCancel: nuxtContext.$config.api + '/' + props.blok.cancel_redirect.cached_url
        }),
        ...(props.blok.add_user_language && { language: nuxtContext.i18n.locale })
      }

      // workarround due to shallow merge with dataModel
      if (dataModel.game_rank_progress !== null) {
        data.game_rank_progress = dataModel.game_rank_progress
      }

      return data
    }

    const middleware = async (withLogin = false) => {
      try {
        await axios.get('/sanctum/csrf-cookie')
        const response = await axios({
          method: props.blok.submit_method ? props.blok.submit_method : 'post',
          url: props.blok.submit_url.replace(
            '{paramId}',
            user ? get(user, props.blok.route_with_data_model_param) : ''
          ),
          data: postData()
        })
        if (withLogin) {
          await login()
        }
        success.value = true
        return response
      } catch (error) {
        if (error && error.response) {
          let errorMsg = error.response.data.message
          if (error.response.data.errors) {
            const errors: any[] = Object.values(error.response.data.errors)
            errorMsg = errors[0][0]
          }
          nuxtContext.$toast.error(errorMsg)
          if (error.response.status === 401) {
            router.push('/login')
          }
        } else {
          console.error(error)
        }
      }
    }

    const onSubmit = async () => {
      const isValid = await vueForm.value.validate()
      isBot.value = false
      if (realFullName.value.value !== '') {
        isBot.value = true
        nuxtContext.$toast.error('You are not human ;-)', {})
      }
      if (isValid && !isBot.value) {
        isSubmitting.value = true
        let response: AxiosResponse<any> | undefined = {}
        // const submitData = { ...dataModel }
        // const dataLayers = useDataLayer(context.parent.$store, submitData)
        switch (props.blok.form_type) {
          case 'login': {
            response = await login()
            handleResponses.login(response, nuxtContext)
            break
          }
          case 'middleware': {
            response = await middleware(props.blok.login_after_submit || false)
            break
          }
          case 'register':
            response = await middleware(true)
            break
        }

        if (response && response.status >= 200 && typeof handleResponses[props.blok.response_trigger] === 'function') {
          handleResponses[props.blok.response_trigger](response, nuxtContext)
        }
        context.emit('submit', dataModel)

        setTimeout(() => {
          isSubmitting.value = false
        }, 500)

        if (success.value) {
          if (props.blok.track_successful_submit && props.blok.name && props.blok.category) {
            let id = user.id
            let action = props.blok.name
            let value

            switch (props.blok.category) {
              case 'subscription': {
                if (user.subscriptions) {
                  id = user.subscriptions[0].id
                  value = user.subscriptions[0].price
                  if (props.blok.use_formdata_for_tracking && dataModel && dataModel.planId) {
                    const plan = store.$repo(SubscriptionPlan).find(parseInt(dataModel.planId))
                    action = 'upgrade'
                    value = plan.price
                    if (plan.tier < user.subscriptions[0].plan.tier) {
                      action = 'downgrade'
                    }
                  }
                }
                break
              }
              default:
            }
            if (value) {
              Matomo.getTracker().trackEvent(props.blok.category, action, id.toString(), value)
            } else {
              Matomo.getTracker().trackEvent(props.blok.category, action, id.toString())
            }
          }

          if (props.blok.form_type === 'register') {
            nuxtContext.$fb.query('track', 'CompleteRegistration')
            if (window.ttq) {
              window.ttq.track('SubmitForm')
            }
          }
        }

        if (response && response.status >= 200) {
          nuxtContext.$bus.emit('refetchStore', 'user')
        }

        setTimeout(() => {
          nuxtContext.$bus.emit('closeFormModal')
        }, 500)

        if (success.value && props.blok.success_toast_message) {
          nuxtContext.$toast.success(props.blok.success_toast_message)
        }
        if (response && response.data && response.data.data && response.data.data.redirect_url) {
          // this needs to be done see: https://github.com/nuxt/nuxt.js/issues/8594#issuecomment-754120298
          try {
            nuxtContext.redirect(response.data.data.redirect_url)
          } catch (error) {
            if (error.message === 'ERR_REDIRECT') {
              return context.emit('routeChanged', response.data.data.redirect_url, '', error)
            }
          }
        } else if (success.value && props.blok.success_redirect && props.blok.success_redirect.cached_url) {
          const path = '/' + props.blok.success_redirect.cached_url
          let query = {}
          if (props.blok.forward_query_params) {
            query = route.value.query
          }
          router.push({ path, query })
        } else if (success.value && props.blok.success_reload) {
          router.go(0)
        }
        success.value = false
      }
    }

    //
    return {
      classes,
      vueForm,
      realFullName,
      onSubmit,
      dataModel,
      dashify,
      success
    }
  }
})
