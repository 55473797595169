














import { computed, defineComponent, inject, PropType, provide, ref, unref, watch } from '@nuxtjs/composition-api'
import get from 'get-value'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useTemplates from '~/utils/useTemplates'

export interface GaGetItemEffect extends StoryblokComponent<'get-item-effect'> {
  custom_classes: string
  content: StoryblokComponent<any>[]
  contentIfUnavailable: StoryblokComponent<any>[]
  itemIndex: number
  injectItemsName: string
  injectItemsProp: string
  placeholderPrefix: string
  provideDataModel: boolean
  dataModel: string
  conditions: StoryblokComponent<any>[]
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaGetItemEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify, checkConditionEffect } = useHelper()
    const { generateItemAttributes } = useTemplates()
    const classes = concatClasses(props.blok)
    const injectName = props.blok.injectItemsName || 'items'
    const injectItems = inject(injectName, [])
    const visible = ref(true)

    const checkCondition = (item, condition) => {
      if (condition.compare_operator.length > 0 && condition.compare_prop.length > 0) {
        return checkConditionEffect(
          get(item, condition.compare_prop, { default: null }),
          condition.compare_operator,
          condition.compare_to_value
        )
      }

      return true
    }

    const item = computed(() => {
      const getProp = props.blok.itemIndex + '.' + (props.blok.injectItemsProp || '')
      const value = props.blok.injectItemsProp ? get(unref(injectItems), getProp) : unref(injectItems)
      let items = []
      if (Array.isArray(value)) {
        items = [...value]
      } else {
        items = [value]
      }

      if (props.blok.conditions != null && props.blok.conditions.length > 0) {
        items = items.filter((item) => {
          let result = true

          props.blok.conditions.forEach((condition) => {
            result = result && checkCondition(item, condition)
          })

          return result
        })
      }

      return props.blok.itemIndex < items.length ? items[props.blok.itemIndex] : null
    })

    if (props.blok.provideDataModel) {
      watch(item, () => {
        visible.value = false
        setTimeout(() => {
          visible.value = true
        }, 1)
      })

      provide('dataModel', item)
    }

    const _generateItemAttributes = (item: any) => {
      return generateItemAttributes(
        item,
        props.blok.content,
        props.blok.placeholderPrefix ? props.blok.placeholderPrefix : 'item'
      )
    }

    return {
      classes,
      dashify,
      generateItemAttributes: _generateItemAttributes,
      item,
      visible
    }
  }
})
