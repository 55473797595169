


















import { defineComponent, useContext, useAsync, useRoute } from '@nuxtjs/composition-api'
import useStoryblok, { GetStoryblokOptions } from '@/utils/storyblok'
import useHelper from '@/utils/helper'

export default defineComponent({
  setup() {
    const { dashify } = useHelper()
    const { getStory } = useStoryblok()
    const nuxtContext = useContext()
    const route = useRoute()
    const lang = route.value.params.lang ? route.value.params.lang : nuxtContext.i18n.defaultLocale

    const getOptions: (path: string) => GetStoryblokOptions = (path) => {
      return {
        path,
        isUuid: true,
        context: nuxtContext
      }
    }
    const settingsPath =
      (route.value.params.lang && nuxtContext.i18n.locale !== nuxtContext.i18n.defaultLocale ? `/${lang}` : '') +
      '/settings/page'

    const getSettings = async () =>
      await getStory({ path: settingsPath, context: nuxtContext })
        .then(async ({ content }) => {
          return {
            header: await getStory(getOptions(content.header_template)),
            footer: await getStory(getOptions(content.footer_template))
          }
        })
        .catch((error) => {
          console.error(error)
        })

    const template = useAsync(getSettings, settingsPath)

    return {
      classes,
      dashify,
      template
    }
  }
})
