











import {
  computed,
  defineComponent,
  PropType,
  provide,
  inject,
  useContext,
  useRoute,
  useStore,
  watch,
  unref,
  ref,
  onMounted
} from '@nuxtjs/composition-api'
import { OrderDirection, Query } from '@vuex-orm/core'
import dayjs from 'dayjs'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import SubscriptionPlan from '~/models/SubscriptionPlan'
import Order from '~/models/Order'
import User from '~/models/User'
import Subscription from '~/models/Subscription'
import AppointmentRoom from '@/models/AppointmentRoom'
import AppointmentRoomOld from '@/models/AppointmentRoomOld'
import GameAccount from '@/models/GameAccount'
import Game from '@/models/Game'
import GameRank from '@/models/GameRank'
import GameRankSystem from '@/models/GameRankSystem'
import StoreCredit from '@/models/StoreCredit'
import Appointment from '@/models/Appointment'

export interface GaProvideUserData extends StoryblokComponent<'provide-user-data'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  plan_filter_whitelist: string[]
  plan_filter_whitelist_local: string[]
  plan_filter_whitelist_dev: string[]
  game_filter: string
  user_relation_object: string
  provide_name: string
  provide_user_property: string
  store_repository: string
  repository_with_relations: boolean
  limit?: number
  whereField?: string
  whereValue?: string
  orderBy?: string
  orderByDirection?: string
  onlyLoggedInUser: boolean
  useUrlQueryParam: boolean
  additional_query_params: string
  delay: string
  force_fetching_first_time: boolean
  force_refetch_after_store_finished: boolean
  type: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaProvideUserData>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const store = useStore()
    const route = useRoute()
    const userData = inject('userData')
    const provideStoreEntityId = ref(route.value.query.provideStoreEntityId?.toString() ?? null)
    const { orderId } = route.value.query

    let entity
    if (orderId) {
      entity = 'orders'
      provideStoreEntityId.value = orderId
    }

    let repo: null | Query<SubscriptionPlan> | Query<Order> | Query<AppointmentRoom> = null
    let repoNames: String[] = []
    const repository = entity || props.blok.store_repository

    let shouldRefetch = (_fetchResult) => false

    switch (repository) {
      case 'plans': {
        if (store.$repo(SubscriptionPlan).all().length === 0) {
          repoNames = ['plans']
        }
        let planRepo = store.$repo(SubscriptionPlan).query().orderBy('sort_order')
        if (props.blok.repository_with_relations) {
          planRepo = planRepo.with('features', (query) => {
            query.orderBy('sort_order')
          })
        }

        const planFilterWhitelist = computed(() => {
          if (nuxtContext.$config.dev) {
            return nuxtContext.$config.isLocal
              ? props.blok.plan_filter_whitelist_local
              : props.blok.plan_filter_whitelist_dev
          }
          return props.blok.plan_filter_whitelist || []
        })

        if (planFilterWhitelist.value && planFilterWhitelist.value.length) {
          planRepo = planRepo.where('id', (planId: number) => {
            return planFilterWhitelist.value.includes(planId.toString())
          })
        }
        repo = planRepo
        break
      }

      case 'orders': {
        repoNames = ['user']
        repo = store.$repo(Order).query()
        if (props.blok.repository_with_relations) {
          repo = repo.with('products').with('appointment').with('user')
        }
        break
      }

      case 'subscriptions': {
        repoNames = ['user']
        repo = store.$repo(Subscription).with('plan')
        break
      }

      case 'user': {
        repoNames = ['user']
        repo = store.$repo(User).query()
        break
      }

      case 'credits': {
        repoNames = ['user', 'store_credits', 'products']
        repo = store.$repo(StoreCredit).with('product')
        break
      }

      case 'appointmentRooms': {
        repoNames = ['appointments', 'appointmentTypes', 'appointmentRooms']
        repo = store
          .$repo(AppointmentRoom)
          .with('appointments')
          .with('appointmentType')
          .with('minRank')
          .with('maxRank')
          .with('owner', (query) => {
            query.with('user_coach_profile', (query) => {
              query.with('languages')
            })
          })
        break
      }

      case 'appointmentRoomsOld': {
        repoNames = ['appointments', 'appointmentTypes', 'appointmentRoomsOld']
        repo = store
          .$repo(AppointmentRoomOld)
          .with('appointments')
          .with('appointmentType')
          .with('minRank')
          .with('maxRank')
          .with('owner', (query) => {
            query.with('user_coach_profile', (query) => {
              query.with('languages')
            })
          })
          .where('time', (val) => (dayjs(val.toString()).isValid() ? dayjs(val.toString()).isBefore(dayjs()) : false))
        break
      }

      case 'appointments': {
        repoNames = ['appointmentTypes', 'appointmentRooms', 'appointments']
        repo = store.$repo(Appointment).with('appointmentRoom').with('appointmentType')

        shouldRefetch = (fetchResult) =>
          fetchResult.filter((f) => f.appointmentType == null && f.appointmentTypeId != null).length ===
          fetchResult.length
        break
      }

      case 'gameAccounts': {
        /**
         * Examples:
         *
         * fetch game accounts for logged in user:
         *
         * nuxtContext.$bus.emit('refetchStore', 'gameAccounts')
         *
         * fetch game accounts for specific user:
         *
         * nuxtContext.$bus.emit('refetchStore', {type: 'gameAccounts', params:{'user_id':6}})
         *
         * fetch game accounts for multiple users:
         *
         * nuxtContext.$bus.emit('refetchStore', {type: 'gameAccounts', params:{'user_id':'1,2,3,4'}})
         */
        repoNames = ['gameAccounts']
        repo = store.$repo(GameAccount).query()
        if (props.blok.repository_with_relations) {
          repo = repo.with('game_rank_progress')
        }
        break
      }

      case 'games': {
        repoNames = ['games']
        repo = store.$repo(Game).query()
        break
      }
      case 'gameRankSystems': {
        repoNames = ['games']
        repo = store.$repo(GameRankSystem).query()
        break
      }
      case 'gameRanks': {
        repoNames = ['games']
        repo = store.$repo(GameRank).query()
        break
      }

      default:
        break
    }

    if (repo && props.blok.limit) {
      repo = repo.limit(props.blok.limit)
    }

    if (repo && props.blok.orderBy) {
      repo = repo.orderBy(props.blok.orderBy, props.blok.orderByDirection as OrderDirection)
    }

    if (repo && userData.value && props.blok.onlyLoggedInUser) {
      repo = repo.where(repository === 'user' ? 'id' : 'user_id', userData.value.id)
    }

    if (repo && props.blok.useUrlQueryParam && provideStoreEntityId) {
      repo = repo.where('id', (val) => val.toString() === unref(provideStoreEntityId))
    }

    if (repo && props.blok.whereField && props.blok.whereValue) {
      const values = props.blok.whereValue.split(',')
      props.blok.whereField.split(',').forEach((wF, index) => {
        repo = repo.where(wF, (val) =>
          val !== null ? val.toString() === (index < values.length ? values[index] : '') : false
        )
      })
    }

    if (repo && props.blok.game_filter) {
      repo = repo.where('game_id', parseInt(props.blok.game_filter))
    }

    let fetching = false
    const getData = () => {
      const result = repo?.get()

      if (shouldRefetch(result) || result.length === 0) {
        if (!fetching) {
          // ignore placeholders
          if (props.blok.whereField && props.blok.whereValue && /^[ ]*%.*%[ ]*$/g.test(props.blok.whereValue)) {
            return result
          }

          fetching = true

          repoNames.forEach((name) => {
            let params = {}

            if (props.blok.whereField && props.blok.whereValue) {
              params = { ...params, [props.blok.whereField]: props.blok.whereValue }
            }

            if (props.blok.game_filter) {
              params = { ...params, game_id: props.blok.game_filter }
            }

            if (props.blok.additional_query_params) {
              const aqp = props.blok.additional_query_params.split('&')
              aqp.forEach((qp) => {
                const kv = qp.split('=')
                params = { ...params, [kv[0]]: kv[1] }
              })
            }

            nuxtContext.$bus.emit('refetchStore', Object.keys(params).length > 0 ? { type: name, params } : name)
          })
        }
      } else {
        fetching = false
      }

      return result
    }

    const storeData = ref([])
    storeData.value = getData()

    const provideName =
      props.blok.provide_name || (props.blok.useUrlQueryParam && provideStoreEntityId.value ? 'item' : 'items')
    provide(provideName, storeData)

    watch(
      () => props.blok.whereValue,
      () => (storeData.value = getData())
    )

    onMounted(() => {
      nuxtContext.$bus.on('refetchStoreFinished', (storeName: String) => {
        if (repoNames.includes(storeName)) {
          storeData.value = getData()
        }
      })
    })

    return {
      classes,
      dashify
    }
  }
})
