











import { defineComponent, PropType, provide } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaCountdownBar extends StoryblokComponent<'countdown-bar'> {
  custom_classes: string
  destination_time: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaCountdownBar>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    provide('destination_time', props.blok.destination_time)

    return {
      classes,
      dashify
    }
  }
})
