















































































































































































import {
  defineComponent,
  PropType,
  ref,
  useContext,
  computed,
  useRouter,
  useRoute,
  onBeforeMount,
  toRefs
} from '@nuxtjs/composition-api'
import { formatDate } from '@/utils/date'
import MainFilter from '~/components/calendar/Filter/MainFilter.vue'
import DatePicker from '~/components/calendar/Filter/DatePicker.vue'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useHelper from '@/utils/helper'
import AppointmentModel from '@/models/Appointment'
import useFilters from '~/composables/useFilters'

export interface CalendarGroup extends StoryblokComponent<'caelndar-group'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
}

export default defineComponent({
  components: {
    MainFilter,
    DatePicker
  },
  props: {
    blok: {
      type: Object as PropType<CalendarGroup>,
      default: () => {}
    }
  },

  setup(props) {
    const { store, $dayjs, $bus, $axios } = useContext()
    const route = useRoute()
    const router = useRouter()
    const { rooms, roomsByDate, query, isBooked } = useFilters()
    const newQuery = toRefs(query)
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)

    const appointmentId = ref()
    const showCancelAppointment = ref(false)
    const isProgressBooking = ref(false)
    const isProgressCancellation = ref(false)

    const toggleModal = () => {
      showCancelAppointment.value = !showCancelAppointment.value
    }

    const getSkillLevel = (room: any) => {
      if (!room.minRank || !room.maxRank) {
        return 'All Ranks'
      }
      if (room.minRank && room.maxRank) {
        if (room.minRank === room.maxRank) return room.minRank.name
      }
      return room.minRank.name + ' - ' + room.maxRank.name
    }

    const emitAppointmentId = (id: Number) => {
      showCancelAppointment.value = true
      appointmentId.value = id
    }

    const isBusy = ref(false)
    const loggedIn = computed(() => store.state.auth.loggedIn)

    newQuery.type.value = props.blok.filter_by_room_type
    newQuery.game.value = props.blok.filter_by_games

    const loadMore = () => {
      router.push({
        path: route.value.path,
        query: {
          minDate: $dayjs(parseInt(query.minDate) * 1000)
            .unix()
            .toString(),
          maxDate: $dayjs(parseInt(query.maxDate) * 1000)
            .add(7, 'days')
            .unix()
            .toString()
        }
      })
    }

    const noLongerBookable = (time: any) => {
      if (props.blok.no_longer_bookable_in_hours === 0 || !props.blok.no_longer_bookable_in_hours) {
        return false
      }
      return $dayjs(time).diff($dayjs(), 'hour') <= parseInt(props.blok.no_longer_bookable_in_hours)
    }

    const notYetBookable = (time: any) => {
      if (props.blok.bookable_period_in_days === 0) {
        return false
      }
      return $dayjs(time).diff($dayjs(), 'day') > parseInt(props.blok.bookable_period_in_days)
    }

    const cancelAppointment = async () => {
      isProgressCancellation.value = true
      const appointment = appointmentId.value

      await $axios
        .$post(`/api/booking/appointments/${appointment}/cancel`)
        .then(() => {
          store.$repo(AppointmentModel).destroy(appointment)
          isProgressCancellation.value = false
        })
        .catch((e) => console.error(e))
        .finally(() => {
          $bus.emit('refetchStore', 'appointments')
          $bus.emit('refetchStore', 'appointmentRooms')
        })
      isProgressCancellation.value = false
      showCancelAppointment.value = false
    }

    onBeforeMount(() => {
      $bus.emit('refetchStore', 'appointmentTypes')
      $bus.emit('refetchStore', 'appointments')
      $bus.emit('refetchStore', 'coaches')
      $bus.emit('refetchStore', 'appointmentRooms')
    })

    return {
      classes,
      dashify,
      roomsByDate,
      loggedIn,
      formatDate,
      isBusy,
      showCancelAppointment,
      isProgressBooking,
      isProgressCancellation,
      emitAppointmentId,
      appointmentId,
      cancelAppointment,
      loadMore,
      toggleModal,
      rooms,
      isBooked,
      getSkillLevel,
      noLongerBookable,
      notYetBookable
    }
  }
})
