import dayjs from 'dayjs'

const formatDate = (date) => {
  const { time, duration } = date
  const from = dayjs(time).format('HH:mm')
  const to = dayjs(time).add(duration, 'm').format('HH:mm')
  const dateTimeFormat = dayjs(time).format('ddd, MMMM DD, YYYY')
  return `${from} - ${to}, ${dateTimeFormat}`
}

const unixDate = (date) => {
  return dayjs(date).unix()
}

export { formatDate, unixDate }
