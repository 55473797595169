



















import { defineComponent } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'

export default defineComponent({
  name: 'GaPage',
  auth: true,
  props: {
    blok: {
      type: Object,
      default: () => {}
    },
    sidebarLeft: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const { dashify, concatClasses } = useHelper()
    const classes = concatClasses(props.blok)

    return {
      classes,
      dashify
    }
  },
  head: {}
})
