















































































































import { defineComponent, watch, ref, inject, onMounted, useRoute, useContext } from '@nuxtjs/composition-api'
import { ValidationProvider } from 'vee-validate'
import set from 'set-value'
import get from 'get-value'
import FormLabelWrapper from '~/components/FormLabel/FormLabelWrapper'
import useHelper from '~/utils/helper'

export default defineComponent({
  components: {
    ValidationProvider,
    FormLabelWrapper
  },
  props: {
    blok: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const internalValue = ref(props.value)
    const dataModel = inject('dataModel', {})
    const isFocused = ref(false)
    const { concatClasses } = useHelper()
    const route = useRoute()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const input = ref()

    const focus = () => {
      isFocused.value = true
    }
    const blur = () => {
      isFocused.value = false
    }
    const handleInput = (data: string) => {
      if (dataModel && props.blok.model) {
        let val = props.blok.hasCommaSeperatedValues ? internalValue.value.split(',') : internalValue.value

        if (props.blok.type === 'number') {
          if (!isNaN(parseInt(props.blok.min ?? ''))) {
            const min = parseInt(props.blok.min)
            const valIn = parseInt(val + '')
            val = (valIn < min ? min : valIn) + ''
          }

          if (!isNaN(parseInt(props.blok.max ?? ''))) {
            const max = parseInt(props.blok.max)

            let valIn = parseInt(val + '')
            while (valIn > max) {
              val = val.slice(0, -1)
              valIn = parseInt(val + '')
            }
          }
        }

        set(dataModel, props.blok.model, val)
        internalValue.value = Array.isArray(val) ? val.join(',') : val
      }
      context.emit('input', data)
    }

    nuxtContext.$bus.off('validateFormField' + props.blok.name)
    nuxtContext.$bus.on('validateFormField' + props.blok.name, () => input.value.validate())

    onMounted(() => {
      internalValue.value = get(dataModel, props.blok.model)

      if (props.blok.route_param_prefill && route.value.query[props.blok.model]) {
        const queryData = route.value.query[props.blok.model]
        internalValue.value = Array.isArray(queryData) ? '' : queryData
        set(
          dataModel,
          props.blok.model,
          props.blok.hasCommaSeperatedValues ? internalValue.value.split(',') : internalValue.value
        )
      }
    })

    watch(
      () => props.value,
      (newValue) => {
        internalValue.value = newValue
      }
    )

    return {
      internalValue,
      handleInput,
      isFocused,
      focus,
      blur,
      classes,
      input
    }
  }
})
