









import { defineComponent, PropType } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaBreadcrumb extends StoryblokComponent<'breadcrumb'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaBreadcrumb>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = [...concatClasses(props.blok), 'flex flex-row text-gray-500 text-sm font-medium']

    return {
      classes,
      dashify
    }
  }
})
