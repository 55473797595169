var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classes},[_c('GameRank',{attrs:{"blok":{
      inject_game_ranks_name: _vm.blok.inject_game_ranks_name,
      inject_game_rank_systems_name: _vm.blok.inject_game_rank_systems_name,
      game_rank_system_id: _vm.blok.game_rank_system_id,
      inject_game_account_name: _vm.blok.inject_game_account_name,
      rank_id_property_path: _vm.blok.rank_start_id_property_path
    }}}),_vm._v(" "),_c('span',{staticClass:"text-blue-400 skillcorridor-chevron"},[_c('fa-icon',{attrs:{"icon":"chevron-right"}})],1),_vm._v(" "),_c('GameRank',{attrs:{"blok":{
      inject_game_ranks_name: _vm.blok.inject_game_ranks_name,
      inject_game_rank_systems_name: _vm.blok.inject_game_rank_systems_name,
      game_rank_system_id: _vm.blok.game_rank_system_id,
      inject_game_account_name: _vm.blok.inject_game_account_name,
      rank_id_property_path: _vm.blok.rank_end_id_property_path
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }