












import { defineComponent, inject, PropType, provide } from '@nuxtjs/composition-api'
import helper from '~/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useTemplates from '~/utils/useTemplates'

export interface GaList extends StoryblokComponent<'list'> {
  content: StoryblokComponent<any>[]
  item_style: StoryblokComponent<any>[]
  custom_classes: string
  inject_items_name: string
  type: 'ul' | 'ol'
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaList>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const { generateItemAttributes } = useTemplates()
    const classes = concatClasses(props.blok)
    const injectName = props.blok.inject_items_name || 'items'
    const items = inject(injectName, [])
    const activeId = 0

    provide('activeId', activeId)

    const generateListItemAttributes = (item: any) => {
      return generateItemAttributes(item, props.blok.item_style)
    }

    return {
      classes,
      dashify,
      generateListItemAttributes,
      items
    }
  }
})
