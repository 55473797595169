import { Model } from '@vuex-orm/core'
import Order from './Order'
import User from './User'
import AppointmentType from '~/models/AppointmentType'
import Calendar from '~/models/Calendar'
import AppointmentRoom from '~/models/AppointmentRoom'

export default class Appointment extends Model {
  static entity = 'appointments'

  static fields() {
    return {
      id: this.uid(),
      user_id: this.attr(null),
      appointmentTypeID: this.attr(null),
      calendarID: this.uid(),
      calendar: this.string(''),
      classID: this.attr(null),
      type: this.string(''),
      duration: this.number(0),
      datetime: this.string(''),
      canceled: this.boolean(false),
      order: this.hasOne(Order, 'appointment_id'),
      appointmentType: this.belongsTo(AppointmentType, 'appointmentTypeID'),
      location: this.string(''),
      language: this.string(''),
      appointmentRoom: this.belongsTo(AppointmentRoom, 'classID'),
      calendarObject: this.belongsTo(Calendar, 'calendarID'),
      user: this.belongsTo(User, 'user_id')
    }
  }

  location!: string
  user_id!: Number | null

  get zoomLink() {
    return this.location.split('\n')[0].replace('URL: ', '')
  }
}
