import { extend, configure } from 'vee-validate'
import { required, email, min, max, confirmed, regex } from 'vee-validate/dist/rules'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import {ValidationRuleSchema} from "vee-validate/dist/types/types";

export default defineNuxtPlugin((ctx) => {
  extend('required', {
    ...required,
    message: 'is required'
  })
  extend('email', {
    ...email,
    message: 'is not a valid address'
  })
  extend('regex', {
    ...regex,
    message: 'does not match'
  })

  extend('min', {
    ...min,
    message: (field, params) => {
      return `needs ${params.length} characters minimum`
    }
  })
  extend('max', max)
  extend('confirmed', {
    ...confirmed,
    message: 'is not identical'
  })

  const voucherSchema = (type = 'plan'): ValidationRuleSchema => {
    return {
      params: ['entityId'],
      message:'not valid',
      // @ts-ignore
      validate: async (value, { entityId }) => {
        let valid
        if (value) {
          await ctx.$axios.get('/sanctum/csrf-cookie')
          valid = await ctx.$axios.$post('/api/payment/voucher/check', {
            'code': value,
            ...(type === 'plan' && { 'planId': entityId }),
            ...(type === 'product' && { 'productId': entityId })
          }).then((response: any) => {
            ctx.$bus.emit('displayVoucherInfo', response.code === 200 ? response.data : null)
            return response.code === 200
          })
        } else {
          valid = true
          ctx.$bus.emit('displayVoucherInfo', null)
        }

        return !!valid
      },
      computesRequired: true
    }
  }

  extend('voucherPlan', voucherSchema('plan'))
  extend('voucherProduct', voucherSchema('product'))


  configure({
    mode: 'eager',
    // @ts-ignore
    defaultMessage: (field, values) => {
      values._field_ = ctx.i18n.t(`fields.${field}`);
      // return ctx.i18n.t(`validations.messages.${values._rule_}`, values);
      return 'not valid'
    }
  })
})


