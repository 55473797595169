





























import { defineComponent, PropType } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaGridTile extends StoryblokComponent<'GridTile'> {
  custom_classes: string
  title_classes: string
  main_content_classes: string
  content: StoryblokComponent<any>[]
  content_before_title: StoryblokComponent<any>[]
  title: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaGridTile>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = [...concatClasses(props.blok), 'gridtile']

    return {
      classes,
      dashify
    }
  }
})
