









import { defineComponent, PropType } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaLanguageFlag extends StoryblokComponent<'LanguageFlag'> {
  custom_classes: string
  language: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaLanguageFlag>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses } = helper()
    const classes = concatClasses(props.blok)

    return {
      classes
    }
  }
})
