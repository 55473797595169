




import { defineComponent, PropType, computed, inject } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import { unixDate } from '@/utils/date'
import CountDownPlugin from '~/components/calendar/CountDownPlugin.vue'

export interface GaCountdown extends StoryblokComponent<'countdown'> {
  custom_classes: string
  date: string
  format: string
  color: string
}

export default defineComponent({
  components: { CountDownPlugin },
  props: {
    blok: {
      type: Object as PropType<GaCountdown>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const injectedTime = inject('destination_time')
    const destinationTime = computed(() => {
      return injectedTime || props.blok.date
    })

    return {
      classes,
      dashify,
      unixDate,
      destinationTime
    }
  }
})
