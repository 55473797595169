import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin(({ $config }) => {
  (function(g,e,o,t?: any,a,r,ge,tl,y?: any){
    t=g.getElementsByTagName(e)[0];y=g.createElement(e);y.async=true;
    // @ts-ignore
    y.src='https://g594253005.co/gj.js?id=-'+ $config.geoTargetlyId +'&refurl='+g.referrer+'&winurl='+encodeURIComponent(window.location);
    t.parentNode.insertBefore(y,t);
  })(document,'script');
})
