











import { defineComponent, PropType, inject, unref } from '@nuxtjs/composition-api'
import get from 'get-value'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaStep extends StoryblokComponent<'step'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  use_visible_condition: boolean
  visible_condition_compare_value: string
  visible_condition_comparison_operator: string
  visible_condition_item_name: string
  visible_condition_item_path: string
  visible_condition_use_item_index: boolean
  visible_condition_use_null_as_default: boolean
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaStep>,
      default: () => {}
    }
  },
  setup(props, context) {
    const { concatClasses, dashify, checkConditionEffect } = helper()
    const classes = concatClasses(props.blok)

    const item = inject(props.blok.visible_condition_item_name ? props.blok.visible_condition_item_name : 'item', {})
    const itemIndex = inject(
      props.blok.visible_condition_item_name ? props.blok.visible_condition_item_name + '_index' : 'item_index',
      '0'
    )
    const getProp = props.blok.visible_condition_use_item_index
      ? itemIndex + '.' + props.blok.visible_condition_item_path
      : props.blok.visible_condition_item_path
    const unrefedItem = unref(item)
    const valueToCheck = get(unrefedItem, getProp.replace('{index}', itemIndex), {
      default: props.blok.visible_condition_use_null_as_default ? null : unrefedItem
    })

    // onMounted(() => {
    if (
      !checkConditionEffect(
        valueToCheck,
        props.blok.visible_condition_comparison_operator,
        props.blok.visible_condition_compare_value
      )
    ) {
      context.emit('skip_step')
    }
    // })

    return {
      dashify,
      classes
    }
  }
})
