export default function (collection: string) {
  const find = (state: any) => (id: string|number, select = '*') => {
    const storeObject = state[collection].find((entity: any) => entity.id === id)
    if (storeObject && select !== '*') {
      return storeObject[select]
    }
    return storeObject
  }

  const all = (state: any) => () => {
    return state
  }

  return {
    find,
    all
  }
}
