import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import get from 'get-value'

export default defineNuxtPlugin((ctx) => {
  ctx.$bus.off('logout')
  ctx.$bus.on('logout', () => {
    ctx.$auth.logout().then(() => {
      ctx.$toast.success('Signed off')
    })
  })
  ctx.$bus.off('triggerMatomoAction')
  ctx.$bus.on('triggerMatomoAction', (data) => {
    if (typeof Matomo !== 'undefined') {
      const tracker = Matomo.getTracker()
      const replacer = data.replacer ? get(data.item, data.replacer) : ''
      const replacer2 = data.replacer2 ? get(data.item, data.replacer2) : ''
      const value_replacer = data.value_replacer ? get(data.item, data.value_replacer) : ''
      const trackName = data.name.replace('{replacer}', replacer).replace('{replacer2}', replacer2)
      const trackValue = data.value.replace('{replacer}', value_replacer)
      tracker.trackEvent(data.category, data.action, trackName, trackValue)
    }
  })
})
