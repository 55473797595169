




import { computed, defineComponent, PropType, inject, useStore, ref, unref } from '@nuxtjs/composition-api'
import get from 'get-value'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import SubscriptionFeature from '~/models/SubscriptionFeature'

export interface GaCount extends StoryblokComponent<'count'> {
  custom_classes: string
  inject_items_name: string
  inject_items_prop: string
  conditions: StoryblokComponent<any>[]
  subscription_plan_feature_tag: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaCount>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, checkConditionEffect } = useHelper()
    const store = useStore()
    const classes = concatClasses(props.blok)
    const injectName = props.blok.inject_items_name || 'items'
    const injectItems = inject(injectName, [])
    const userData = inject('userData', ref({ subscriptions: [] }))

    const getSubscriptionPlanCount = () => {
      if (
        props.blok.subscription_plan_feature_tag &&
        props.blok.subscription_plan_feature_tag.length > 0 &&
        userData.value &&
        userData.value.subscriptions &&
        userData.value.subscriptions.length > 0
      ) {
        const planFeature = store
          .$repo(SubscriptionFeature)
          .with('usage')
          .where('tag', props.blok.subscription_plan_feature_tag)
          .where('plan_subscription_id', userData.value.subscriptions[0].id)
          .first()
        if (planFeature && planFeature.value) {
          if (planFeature.value === ('true' || 'false')) {
            return 0
          } else {
            const featureUsageOverall = parseInt(planFeature.value)
            const featureUsageUsed = parseInt(planFeature.usage?.used) || 0
            return featureUsageOverall - featureUsageUsed
          }
        }
      }

      return 0
    }

    const checkCondition = (item, condition) => {
      if (condition.compare_operator.length > 0 && condition.compare_prop.length > 0) {
        return checkConditionEffect(
          get(item, condition.compare_prop, { default: null }),
          condition.compare_operator,
          condition.compare_to_value
        )
      }

      return true
    }

    const itemCount = computed(() => {
      let items = unref(injectItems)
      let count = items == null ? 0 : 1

      if (props.blok.inject_items_prop) {
        items = get(items, props.blok.inject_items_prop)
      }

      if (Array.isArray(items)) {
        if (props.blok.conditions != null && props.blok.conditions.length > 0) {
          items = items.filter((item) => {
            let result = true

            props.blok.conditions.forEach((condition) => {
              result = result && checkCondition(item, condition)
            })

            return result
          })
        }

        count = items.length
      } else if (props.blok.conditions != null && props.blok.conditions.length > 0) {
        let result = true

        props.blok.conditions.forEach((condition) => {
          result = result && checkCondition(items, condition)
        })

        count = result ? 1 : 0
      }

      return count + getSubscriptionPlanCount()
    })

    return {
      classes,
      itemCount
    }
  }
})
