


















import {
  defineComponent,
  ref,
  inject,
  onMounted,
  useContext,
  unref,
  ComputedRef,
  computed
} from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import User from '~/models/User'

export default defineComponent({
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const userData: ComputedRef<User> | undefined = inject('userData')
    const user = unref(userData)
    const isStoryblokEdit = ref(false)
    const nuxtContext = useContext()
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)
    const dataModel: any = inject('dataModel', { value: {} })
    const {
      has_no_subscriptions: hasNoSubscriptions,
      subscription_property: subscriptionProperty,
      compare_to_value: value,
      compare_type: compareType
    } = props.blok

    const isVisible = computed(() => {
      if (hasNoSubscriptions) {
        return user && user.subscriptions.length === 0
      }
      if (subscriptionProperty && value && user && user.subscriptions[0] && user.subscriptions[0].plan) {
        const valueToCheck = String(user.subscriptions[0].plan[subscriptionProperty])
        const compareToValue = props.blok.use_data_model ? String(unref(dataModel)?.planId) : String(value)

        switch (compareType) {
          case 'isTrue':
            return !!valueToCheck === true
          case 'isFalse':
            return !!valueToCheck === false
          case 'exists': {
            if (Array.isArray(valueToCheck)) {
              return !!valueToCheck.length
            }
            return valueToCheck !== undefined
          }
          case '>':
            return valueToCheck > compareToValue
          case '<':
            return valueToCheck < compareToValue
          case 'eq':
            return valueToCheck === compareToValue
          case 'notEq':
            return valueToCheck !== compareToValue
          default:
            return false
        }
      }
      return false
    })

    if (nuxtContext.isDev || nuxtContext.$config.dev) {
      onMounted(() => {
        if (window.location !== window.parent.location) {
          isStoryblokEdit.value = true
        }
      })
    }

    return {
      classes,
      dashify,
      isVisible,
      isStoryblokEdit
    }
  }
})
