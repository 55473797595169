


























import { defineComponent, PropType, useContext, computed, ref, inject, unref, onMounted } from '@nuxtjs/composition-api'
import { useLocalStorage } from '@vueuse/core'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface Banner extends StoryblokComponent<'banner'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  display_for: string[]
  display_for_parents_of: string[]
  position: string
  disable: boolean
  starts_at: string
  ends_at: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<Banner>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const isStoryblokEdit = inject('storyblokEdit', ref(false))
    const story = inject('story', {
      story: {
        parent_id: 0,
        uuid: 0
      }
    })
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const localStorage = useLocalStorage('gamersAcademy', {
      bannerIds: []
    })

    const isBannerVisible = computed(() => {
      const {
        story: {
          parent_id: storyParentId,
          uuid: storyUuid,
          content: { component }
        }
      } = unref(story)
      const parentIds = props.blok.display_for_parents_of.map((story) => story?.parent_id)
      const disabled = props.blok.disable || false

      const displayByUuid = props.blok.display_for.includes(storyUuid)
      const displayByParentId = parentIds.includes(storyParentId)

      const isStarting = props.blok.starts_at
        ? nuxtContext.$dayjs(props.blok.starts_at).isSameOrBefore(nuxtContext.$dayjs(), 'd')
        : true

      const hasEnded = props.blok.ends_at
        ? nuxtContext.$dayjs(props.blok.ends_at).isAfter(nuxtContext.$dayjs(), 'd')
        : false

      const localStorageCheck = isStoryblokEdit.value ? true : !localStorage.value.bannerIds.includes(props.blok._uid)

      return (
        !disabled &&
        (displayByUuid || displayByParentId || component === 'banner') &&
        localStorageCheck &&
        isStarting &&
        !hasEnded
      )
    })

    nuxtContext.$bus.on('closeBanner', () => {
      if (!isStoryblokEdit.value) {
        localStorage.value.bannerIds.push(props.blok._uid)
      }
    })

    const scrollPosition = ref(null)
    const updateScroll = () => {
      scrollPosition.value = window.scrollY
    }

    onMounted(() => {
      window.addEventListener('scroll', updateScroll)
    })

    return {
      classes,
      dashify,
      isBannerVisible,
      isStoryblokEdit,
      scrollPosition
    }
  }
})
