import Vue from 'vue'
import VueGtag from 'vue-gtag'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin(({ isDev, app }) => {
  if (!isDev) {
    Vue.use(
      VueGtag,
      {
        config: {
          id: 'UA-142693365-3',
          params: {
            optimize_id: 'GTM-P7Q9RDZ'
          }
        },
        includes: [
          {
            id: 'AW-709617753',
            params: {
              send_page_view: false,
              optimize_id: 'GTM-P7Q9RDZ'
            }
          }
        ]
      },
      app.router
    )
  } else {
    console.log('Skipping GA in development')
  }
})
