




























import { defineComponent, watch, ref, inject, PropType } from '@nuxtjs/composition-api'
import { ValidationProvider } from 'vee-validate'
import set from 'set-value'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaCheckbox extends StoryblokComponent<'checkbox'> {
  // content: StoryblokComponent<any>[]
  custom_classes: string
  model: string
  name: string
  label: string
  validation_rules: string
  screen_reader_text: string
  value: boolean
  true_value: string
  false_value: string
}

export default defineComponent({
  components: {
    ValidationProvider
  },
  props: {
    blok: {
      type: Object as PropType<GaCheckbox>,
      default: () => {}
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const { concatClasses } = helper()
    const classes = concatClasses(props.blok)
    const internalValue = ref(props.value)
    const dataModel = inject('dataModel', {})

    const mapValues = (value: boolean) => {
      if (props.blok.true_value && value) {
        return props.blok.true_value
      }
      if (props.blok.false_value && !value) {
        return props.blok.false_value
      }
      return value
    }

    const handleInput = (type: string, value: boolean) => {
      internalValue.value = !value
      if (dataModel && props.blok.model) {
        set(dataModel, props.blok.model, mapValues(internalValue.value))
      }
      context.emit(type, value)
    }

    watch(
      () => props.value,
      (newValue) => {
        internalValue.value = newValue
      }
    )

    return {
      internalValue,
      handleInput,
      classes
    }
  }
})
