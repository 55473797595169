











import { defineComponent, PropType, provide, inject, useContext, computed, ref, unref } from '@nuxtjs/composition-api'
import { asyncComputed } from '@vueuse/core'
import get from 'get-value'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaFetchUserEffect extends StoryblokComponent<'fetch-user-effect'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  provide_name: string
  inject_item_name: string
  user_id_property: string
  users_list_property_path: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaFetchUserEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const userList = ref([])
    const injectName = props.blok.inject_item_name || 'item'
    const injectItem = inject(injectName, [])

    const getUserIds = computed(() => {
      const unrefed = unref(injectItem)
      if (unrefed && unrefed.length > 0) {
        const injectedUsersList = get(unrefed, props.blok.users_list_property_path, {
          default: Array.isArray(unrefed) ? unrefed : [unrefed]
        })
        const userIds = []

        for (const user of injectedUsersList) {
          if (user[props.blok.user_id_property]) {
            userIds.push(+user[props.blok.user_id_property])
          }
        }

        return userIds
      }
      return []
    })

    let fetchingUsers = false
    const fetchUsers = asyncComputed(async () => {
      if (fetchingUsers) {
        return userList.value
      }

      fetchingUsers = true
      const payload = {
        user_ids: getUserIds.value
      }

      if (payload.user_ids.length === 0) {
        fetchingUsers = false
        return userList.value
      }

      await nuxtContext.$axios.$post(`/api/user/accounts`, payload).then((response) => {
        userList.value = response.data.items.map((user) => user)
      })

      fetchingUsers = false
      return userList.value
    })

    provide(props.blok.provide_name || 'users', fetchUsers)

    return {
      classes,
      dashify,
      userList
    }
  }
})
