import { Model } from '@vuex-orm/core'

export default class SubscriptionSchedule extends Model {
  static entity = 'subscriptionSchedules'

  static fields() {
    return {
      id: this.uid(),
      plan_id: this.attr(null),
      subscription_id: this.attr(null),
      scheduled_at: this.string('')
    }
  }

  used!: number
}
