





















































import {
  defineComponent,
  useContext,
  useRoute,
  useRouter,
  reactive,
  ref,
  toRefs,
  watch,
  computed,
  inject
} from '@nuxtjs/composition-api'
import Room from '@/models/AppointmentRoom'
import DropdownSelect from '~/components/dropdown/DropdownSelect.vue'

export default defineComponent({
  components: { DropdownSelect },
  props: {
    rooms: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const { store } = useContext()
    const route = useRoute()
    const router = useRouter()
    const selectedCoach = ref(null)
    const selectedType = ref([])
    const selectedLang = ref([])
    const { rooms } = toRefs(props)
    const userData = inject('userData')
    const calendar_id = userData.value?.user_coach_profile?.calendar_id || null
    const dropdownIsActive = ref(false)
    const button =
      'border-secondary-500 py-1 rounded uppercase text-l font-black text-black shadow mr-3 transition ease-in-out duration-300 hover:bg-blue-400 hover:scale-110'
    const gradient =
      'bg-gradient-to-r from-blue-300 via-blue-400 to-rose-500 transition-all duration-300 hover:bg-blue-600'

    const resetButton = () => {
      router.push({
        path: route.value.path,
        query: {
          coach: null,
          types: null,
          language: null,
          minDate: route.value.query.minDate,
          maxDate: route.value.query.maxDate,
          perPage: 10
        }
      })
      selectedCoach.value = null
      selectedType.value = []
      selectedLang.value = []
      dropdownIsActive.value = false
    }

    const query = reactive({
      coach: route.value.query.coach || null,
      type: null,
      language: null,
      minDate: route.value.query.minDate,
      maxDate: route.value.query.maxDate,
      perPage: 10
    })

    const emitSelected = (selected) => {
      dropdownIsActive.value = true
      filter('coach', parseInt(selected))
    }

    const filter = (option, selected) => {
      if (option === 'coach') {
        selectedCoach.value = selected
        query.coach = selected
      }
      if (option === 'type') {
        selectedType.value = selected
        query.type = selected
      }
      if (option === 'language') {
        selectedLang.value = selected
        query.language = selected
      }
      query.perPage = 10
      router.push({
        path: route.value.path,
        query
      })
    }

    const coaches = computed(() => {
      /* if is coach and is on coaching page, display only the coach
       * available items */
      if (calendar_id !== null && route.value.path === '/coaching') {
        return store
          .$repo(Room)
          .where('calendarID', calendar_id)
          .get()
          .map((filter) => {
            return {
              calendar: filter.calendar,
              id: filter.calendarID,
              active: false
            }
          })
          .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
      }
      return store
        .$repo(Room)
        .all()
        .map((filter) => {
          return {
            calendar: filter.calendar,
            id: filter.calendarID,
            active: false
          }
        })
        .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
    })

    const types = computed(() =>
      store
        .$repo(Room)
        .all()
        .map((filter) => filter.name)
        .filter((v, i, a) => a.findIndex((t) => t === v) === i)
    )

    const languages = computed(() => {
      const eng = 'English'
      const de = 'Deutsch'
      const lang = {
        gb: eng,
        us: eng,
        au: eng,
        ca: eng,
        de,
        at: de
      }
      return store
        .$repo(Room)
        .all()
        .map((filter) => filter.language)
        .filter((name, indx, array) => array.findIndex((arrElement) => arrElement === name) === indx)
        .filter((nul) => nul !== 'null')
        .map((language) => {
          return [language, lang[language] ? lang[language] : language]
        })
    })

    const filters = computed(() => {
      return [
        {
          name: 'Coaches',
          key: 'coaches',
          data: coaches.value
        },
        {
          name: 'Coaching types',
          key: 'types',
          data: types.value
        },
        {
          name: 'Languages',
          key: 'languages',
          data: languages.value
        }
      ]
    })

    watch(rooms, (val) => {
      rooms.value = val
    })

    watch(route, (val) => {
      query.minDate = val.query.minDate
      query.maxDate = val.query.maxDate
    })

    return {
      coaches,
      types,
      languages,
      filter,
      selectedCoach,
      selectedType,
      selectedLang,
      query,
      emitSelected,
      resetButton,
      filters,
      dropdownIsActive,
      button,
      gradient
    }
  }
})
