














import { defineComponent, PropType } from '@nuxtjs/composition-api'
import helper from '~/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaTableItem extends StoryblokComponent<'table-item'> {
  content: StoryblokComponent<any>[]
  text: string
  custom_classes: string
  column_span: string
  type: 'table | thead | tbody | td | tr | th | caption'
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaTableItem>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)

    const attr = {
      is: props.blok.type,
      ...(classes && { class: classes })
    }

    const colSpan = props.blok.column_span && parseInt(props.blok.column_span) > 0 ? `${props.blok.column_span}` : ''

    return {
      classes,
      dashify,
      attr,
      colSpan
    }
  }
})
