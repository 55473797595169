var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"mx-auto w-full pb-4 flex flex-col lg:flex-row bg-white dark:bg-gray-800 dark:text-white"},[_c('div',{staticClass:"flex flex-col mx-2"},[_c('client-only',[_c('v-date-picker',{ref:"calendar",staticClass:"date-picker",attrs:{"attributes":_vm.attributes,"model-config":_vm.modelConfig,"min-date":new Date(),"is-range":"","is-dark":"","color":"blue","header-color":"blue"},on:{"input":_vm.dayClicked},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_vm._v(" "),_c('span',{staticClass:"text-red-500 text-lg underline cursor-pointer text-center",on:{"click":_vm.reset}},[_vm._v(_vm._s(_vm.blok.monthly_overview))]),_vm._v(" "),(_vm.loggedIn && _vm.appointments.length > 0)?_c('div',{staticClass:"px-2 py-2 mt-8"},[_c('div',{staticClass:"py-2"},_vm._l((_vm.blok.content),function(blokChild){return _c(_vm.dashify(blokChild.component),{key:blokChild._uid,tag:"component",attrs:{"blok":blokChild}})}),1)]):_vm._e()],1),_vm._v(" "),(!_vm.isBusy)?_c('div',{staticClass:"flex flex-col w-full my-4 sm:mx-8"},[_c('div',{},[_c('AppointmentFilter',{attrs:{"rooms":_vm.rooms}})],1),_vm._v(" "),(_vm.rooms.length > 0)?_vm._l((_vm.rooms),function(room,index){return _c('div',{key:index},[_c('blok-appointment-room',{attrs:{"blok":_vm.blok.appointment_room[0],"room":room},on:{"cancelAppointment":_vm.emitAppointmentId}}),_vm._v(" "),(!_vm.sameTime(room.time, index))?_c('div',[_c('div',{staticClass:"relative"},[_vm._m(0,true),_vm._v(" "),_c('div',{staticClass:"relative flex justify-center"},[_c('span',{staticClass:"px-3 bg-white dark:bg-black text-lg font-medium text-gray-900 dark:text-gray-100"},[_vm._v("\n                "+_vm._s(_vm.rooms[index + 1] &&
                  _vm.rooms[index + 1].time &&
                  _vm.$dayjs(_vm.rooms[index + 1].time).format('dddd, MMMM DD, YYYY'))+"\n              ")])])])]):_vm._e()],1)}):[_c('div',{staticClass:"my-auto mx-auto h-screen"},[_c('span',{staticClass:"flex flex-col justify-center w-full text-2xl font-bold text-black text-center dark:text-white"},[_vm._v("\n          "+_vm._s(_vm.blok.no_coachings)+"\n        ")])])],_vm._v(" "),_c('blok-pagination',{attrs:{"rooms-count":_vm.rooms.length}})],2):_c('div',{staticClass:"flex w-full"},[_vm._m(1)]),_vm._v(" "),_c('blok-modal',{attrs:{"blok":{},"open":_vm.showCancelAppointment},on:{"close":function($event){return _vm.toggleModal()}}},[_c('div',{staticClass:"text-4xl text-red-600 text-center"},[_c('blok-icon',{attrs:{"blok":{
          icon: {
            icon: null
          },
          icon_type: 'fas',
          icon_name: 'exclamation-triangle'
        }}})],1),_vm._v(" "),_c('div',{staticClass:"mt-3 md:text-center sm:mt-0 sm:ml-4 sm:text-left"},[_c('h3',{staticClass:"text-lg leading-6 font-medium"},[_vm._v(_vm._s(_vm.blok.cancelation_header))]),_vm._v(" "),_c('p',{staticClass:"mt-2 text-sm"},[_vm._v(_vm._s(_vm.blok.cancelation_message))])]),_vm._v(" "),_c('div',{staticClass:"mt-8 w-full flex px-8 justify-between gap-8 self-center text-center"},[(_vm.appointmentId)?_c('button',{staticClass:"\n          w-1/2\n          border border-transparent\n          shadow-sm\n          py-1\n          rounded\n          bg-red-600\n          text-base\n          font-medium\n          text-white\n          hover:bg-red-700\n          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500\n        ",staticStyle:{"transition":"all 0.15s ease 0s"},on:{"click":function($event){return _vm.cancelAppointment()}}},[_vm._v("\n        "+_vm._s(_vm.blok.cancelation_button)+"\n        "),(_vm.isProgressCancellation)?_c('svg',{staticClass:"animate-spin ml-3 h-5 w-5 text-white inline-block absolute",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_vm._v(" "),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})]):_vm._e()]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"bg-secondary-500 rounded w-1/2",on:{"click":function($event){return _vm.toggleModal()}}},[_vm._v("\n        "+_vm._s(_vm.blok.uncancelation_button)+"\n      ")])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"absolute inset-0 flex items-center",attrs:{"aria-hidden":"true"}},[_c('div',{staticClass:"w-full border-t border-gray-500"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full h-full items-center mx-auto my-4 sm:px-8"},[_c('div',{staticClass:"loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-16 w-16"}),_vm._v(" "),_c('span',{staticClass:"pt-4 font-xl font-normal text-secondary-400"},[_vm._v("Loading...")])])}]

export { render, staticRenderFns }