var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],attrs:{"slim":"","name":_vm.blok.name,"rules":_vm.blok.validation_rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('div',{class:_vm.classes},[_c('FormLabelWrapper',{staticClass:"relative ga_select",attrs:{"blok":{
        validated: !_vm.blok.disable_validation_display && validated,
        invalid: !_vm.blok.disable_validation_display && invalid,
        errors: errors,
        text: _vm.blok.label,
        icon: _vm.blok.extra_field_icon,
        isFocused: _vm.isFocused
      }}},[_c('v-select',{staticClass:"\n          relative\n          block\n          pl-3\n          pr-2\n          pt-2\n          pb-0\n          w-full\n          text-lg\n          appearance-none\n          focus:outline-none\n          border\n          rounded\n          bg-white\n          leading-4\n        ",class:[
          {
            'border-error': !_vm.blok.disable_validation_display && validated && invalid,
            'border-success': !_vm.blok.disable_validation_display && validated && !invalid,
            'border-secondary-500': !validated && _vm.isFocused,
            'shadow-outline-error': !_vm.blok.disable_validation_display && validated && invalid && _vm.isFocused,
            'shadow-outline-success': !_vm.blok.disable_validation_display && validated && !invalid && _vm.isFocused,
            vs__disabled: _vm.blok.disabled
          },
          _vm.classes
        ],attrs:{"id":_vm.blok.name + '-' + _vm.blok._uid,"name":_vm.blok.name,"label":_vm.v_select_label,"options":_vm.options,"reduce":function (option) { return option[_vm.v_select_reduce]; },"multiple":_vm.blok.multiple,"disabled":_vm.blok.disabled},on:{"input":function($event){return _vm.handleInput($event)}},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }