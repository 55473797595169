

























import { defineComponent, useMeta, useContext, useAsync } from '@nuxtjs/composition-api'
import useStoryblok, { GetStoryblokOptions } from '@/utils/storyblok'
import useHelper from '@/utils/helper'

export default defineComponent({
  name: 'GaPage',
  middleware: 'guest',
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { dashify, concatClasses } = useHelper()
    const { getStory } = useStoryblok()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const lang = nuxtContext.i18n.locale

    const { title } = useMeta()
    if (props.blok.seo && props.blok.seo.title) {
      title.value = props.blok.seo.title
    }

    const getOptions: (path: string) => GetStoryblokOptions = (path) => {
      return {
        path,
        isUuid: true,
        context: nuxtContext
      }
    }
    const settingsPath =
      (nuxtContext.i18n.locale !== nuxtContext.i18n.defaultLocale ? `/${lang}` : '') +
      '/settings/' +
      props.blok.component

    const getSettings = async () =>
      await getStory({ path: settingsPath, context: nuxtContext })
        .then(async ({ content }) => {
          return {
            header: await getStory(getOptions(content.header_template)),
            footer: await getStory(getOptions(content.footer_template))
          }
        })
        .catch((error) => {
          console.error(error)
        })

    const template = useAsync(getSettings, settingsPath)

    return {
      classes,
      dashify,
      template
    }
  },
  head: {}
})
