





















































import {
  defineComponent,
  useContext,
  computed,
  useRouter,
  useRoute,
  onBeforeMount,
  toRefs,
  PropType
} from '@nuxtjs/composition-api'
import OneOnOneRoom from '~/components/calendar/New/OneOnOneRoom.vue'
import { Calendar } from '~/components/calendar/Calendar1on1.vue'
import useFilters from '~/composables/useFilters'

export default defineComponent({
  components: { OneOnOneRoom },
  props: {
    game: { type: String, default: () => 'Fifa 22' },
    type: { type: String, default: () => '1on1 Coaching' },
    blok: {
      type: Object as PropType<Calendar>,
      default: () => {}
    }
  },
  setup(props) {
    const { store, $dayjs, $bus } = useContext()
    const route = useRoute()
    const router = useRouter()
    const { roomsByDateAndTime, isBusy, hasSlots, isBooked, query, rooms } = useFilters()
    const newQuery = toRefs(query)

    const loggedIn = computed(() => store.state.auth.loggedIn)

    const loadMore = () => {
      router.push({
        path: route.value.path,
        query: {
          minDate: $dayjs(parseInt(query.minDate) * 1000)
            .unix()
            .toString(),
          maxDate: $dayjs(parseInt(query.maxDate) * 1000)
            .add(7, 'days')
            .unix()
            .toString()
        }
      })
    }

    newQuery.type.value = props.blok.filter_by_room_type
    newQuery.game.value = props.blok.filter_by_games

    onBeforeMount(() => {
      $bus.emit('refetchStore', 'appointmentRooms')
    })

    return {
      roomsByDateAndTime,
      loggedIn,
      isBusy,
      loadMore,
      isBooked,
      hasSlots,
      rooms,
      newQuery
    }
  }
})
