








import { ref, computed, watch, onUnmounted } from '@nuxtjs/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  props: {
    time: { type: dayjs, default: () => dayjs() },
    // eslint-disable-next-line
    count_to: { type: String, required: true },
    format: { type: String, default: 'HH[h] mm[m] ss[s]' },
    size: { type: String, default: '1rem' },
    color: { type: String, default: 'white' }
  },
  setup(props) {
    let intervalId = null
    const now = ref(props.time / 1000)
    const remain = computed(() => Math.round(dayjs(props.count_to).unix() - now.value))
    const count = computed(() => remain.value >= 1)
    const display = computed(() => dayjs.utc(remain.value * 1000).format(props.format))

    watch(
      () => count.value,
      (v) => {
        if (v) intervalId = setInterval(() => now.value++, 1000)
      },
      {
        immediate: true
      }
    )
    onUnmounted(() => {
      clearInterval(intervalId)
    })

    return {
      remain,
      display
    }
  }
}
