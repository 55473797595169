import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin((ctx) => {
  ctx.$axios.onError((error) => {
    if (
      error &&
      error.response &&
      error.response.data.message === 'Unauthenticated.' &&
      error.response.status === 401
    ) {
      if (ctx.$auth.loggedIn) {
        // ctx.$auth.logout()
      }
    }
    if (error && error.response && error.response.data && error.response.status !== 401) {
      let errorMsg = error.response.data.message
      if (error.response.data.errors) {
        const errors: any[] = Object.values(error.response.data.errors)
        errorMsg = errors[0][0]
      }
      ctx.$toast.error(errorMsg)
    }
  })
})
