import { AxiosResponse } from 'axios'

export default function (response: AxiosResponse, nuxtContext: any) {
  if (response.status === 204) {
    nuxtContext.$toast.success('Your E-Mail address is already verified')
  }

  if (response.status === 202) {
    nuxtContext.$toast.success('E-Mail verification send.')
  }
}
