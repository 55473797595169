






















import { defineComponent, ref, onMounted, provide } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'

export default defineComponent({
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)
    const activeTab = ref('')
    const setActiveTab = (uid) => {
      activeTab.value = uid
    }

    provide('activeTabUid', activeTab)

    onMounted(() => {
      props.blok.tabs && setActiveTab(props.blok.tabs[0]._uid)
    })

    return {
      classes,
      dashify,
      activeTab,
      setActiveTab
    }
  }
})
