import {defineNuxtPlugin, onBeforeMount, onGlobalSetup, provide, ref, unref} from '@nuxtjs/composition-api'

export default defineNuxtPlugin((ctx) => {
  onGlobalSetup(() => {
    const isStoryblokEdit = ref(false)
    provide('storyblokEdit', isStoryblokEdit)
    onBeforeMount(() => {
      if ((ctx.isDev || ctx.$config.dev) && window.location !== window.parent.location) {
        isStoryblokEdit.value = true
      }
    })
  })
})
