




















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { formatDate } from '@/utils/date'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface Cancellation extends StoryblokComponent<'Cancellation'> {
  content: StoryblokComponent<any>[]
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<Cancellation>,
      default: () => {}
    }
  },
  setup() {
    const { dashify } = useHelper()

    return {
      formatDate,
      dashify
    }
  }
})
