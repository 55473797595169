















import { defineComponent, useRoute, useRouter, computed, toRefs } from '@nuxtjs/composition-api'
import Checkbox from '~/components/calendar/Filter/Checkbox.vue'
import Tag from '~/models/Tag'
import useFilters from '~/composables/useFilters'

export default defineComponent({
  components: { Checkbox },
  props: {
    showProperty: { type: String, default: () => '' },
    valueProperty: { type: String, default: () => '' },
    queryParameter: { type: String, default: () => '' },
    type: { type: String, default: () => '' },
    multiselect: { type: Boolean, default: () => false },
    heading: { type: String, default: () => '' }
  },
  setup(props) {
    const { type, multiselect } = toRefs(props)
    const route = useRoute()
    const router = useRouter()
    const { tags, activeTags, query } = useFilters()

    const tagList = computed(() => {
      return tags.value.filter((tag: Tag) => {
        return tag.type === type.value
      })
    })

    const select = (selected: Tag) => {
      const otherActives = activeTags.value
        .filter(function (obj) {
          return obj.type !== type.value
        })
        .map((tag) => tag.id)

      let ownActives = activeTags.value
        .filter(function (obj) {
          return obj.type === type.value
        })
        .map((tag) => tag.id)

      if (ownActives.includes(selected.id)) {
        const index = ownActives.indexOf(selected.id)
        if (index > -1) {
          ownActives.splice(index, 1)
        }
      } else if (multiselect.value) {
        ownActives.push(selected.id)
      } else {
        ownActives = [selected.id]
      }
      const actives = [...otherActives, ...ownActives]

      query.tags = actives.length > 0 ? actives.join() : null

      router.push({
        path: route.value.path,
        query
      })
    }

    return {
      activeTags,
      tags,
      tagList,
      select,
      query
    }
  }
})
