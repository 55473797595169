var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.appointment && _vm.appointment.canceled === false)?_c('div',{staticClass:"relative rounded-xl px-0 sm:px-6 py-2 flex items-center space-x-3 hover:bg-gray-50 dark:hover:bg-gray-500"},[_c('div',{staticClass:"flex-shrink-0 hidden sm:flex"},[_c('blok-icon',{attrs:{"blok":{
        icon: {
          icon: null
        },
        icon_type: 'fas',
        icon_name: 'calendar-alt',
        custom_classes: 'text-secondary-500 mr-4'
      }}})],1),_vm._v(" "),_c('div',{staticClass:"flex-1 min-w-0"},[_c('a',{staticClass:"focus:outline-none",attrs:{"href":"#"}},[_c('p',{staticClass:"text-base font-medium dark:text-white text-gray-900"},[_vm._v(_vm._s(_vm.appointment.type))]),_vm._v(" "),_c('p',{staticClass:"text-base dark:text-white text-gray-600 truncate"},[_vm._v("\n        "+_vm._s(_vm.formatDate({ time: _vm.appointment.datetime, duration: _vm.appointment.duration }))+"\n      ")])])]),_vm._v(" "),(_vm.isCancelable)?_c('button',{staticClass:"\n      flex\n      items-center\n      bg-primary-500\n      text-white text-md\n      font-bold\n      uppercase\n      px-3\n      py-2\n      rounded\n      shadow\n      hover:shadow-md\n      outline-none\n      focus:outline-none\n      lg:mr-1 lg:mb-0\n    ",staticStyle:{"transition":"all 0.15s ease 0s"},on:{"click":function($event){return _vm.toggleModal()}}},[_c('blok-icon',{attrs:{"blok":{
        icon: {
          icon: null
        },
        icon_type: 'fas',
        icon_name: 'times',
        custom_classes: ''
      }}})],1):_vm._e(),_vm._v(" "),_c('blok-modal',{attrs:{"blok":{},"open":_vm.isOpen},on:{"close":function($event){return _vm.toggleModal()}}},[_c('div',{staticClass:"text-4xl text-red-600 text-center"},[_c('blok-icon',{attrs:{"blok":{
          icon: {
            icon: null
          },
          icon_type: 'fas',
          icon_name: 'exclamation-triangle'
        }}})],1),_vm._v(" "),_c('div',{staticClass:"mt-3 md:text-center sm:mt-0 sm:ml-4 sm:text-left"},[_c('h3',{staticClass:"text-lg leading-6 font-medium"},[_vm._v(_vm._s(_vm.header))]),_vm._v(" "),_c('p',{staticClass:"mt-2 text-sm"},[_vm._v(_vm._s(_vm.message))]),_vm._v(" "),(_vm.twelveHrs(_vm.appointment.datetime))?_c('p',{staticClass:"mt-2 text-sm !text-red-500"},[_vm._v(_vm._s(_vm.notice))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"mt-8 w-full flex px-8 justify-between gap-8 self-center text-center"},[(_vm.isCancelable)?_c('button',{staticClass:"\n          w-1/2\n          border border-transparent\n          shadow-sm\n          py-1\n          rounded\n          bg-red-600\n          text-base\n          font-medium\n          text-white\n          hover:bg-red-700\n          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500\n        ",staticStyle:{"transition":"all 0.15s ease 0s"},on:{"click":function($event){return _vm.cancelEvent(_vm.appointment)}}},[_vm._v("\n        "+_vm._s(_vm.cancel)+"\n        "),(_vm.isProgressCancellation)?_c('svg',{staticClass:"animate-spin -ml-1 mr-3 h-5 w-5 text-white",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_vm._v(" "),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})]):_vm._e()]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"bg-secondary-500 rounded w-1/2",on:{"click":function($event){return _vm.toggleModal()}}},[_vm._v("\n        "+_vm._s(_vm.uncancel)+"\n      ")])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }