





































































































































































































































import { defineComponent, computed, useContext, PropType, useRouter, onMounted } from '@nuxtjs/composition-api'
import Order from '@/models/Order'
import useHelper from '@/utils/helper'
import Appointment from '@/models/Appointment'
import { formatDate } from '@/utils/date'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface Confirmation extends StoryblokComponent<'Confirmation'> {
  content: StoryblokComponent<any>[]
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<Confirmation>,
      default: () => {}
    }
  },
  setup() {
    const { dashify } = useHelper()
    const { store, route, $axios, app, payload } = useContext()
    const router = useRouter()
    const event = computed(() => store.state.calendar.event)
    const { orderId } = route.value.query
    const order = computed(() =>
      store.$repo(Order).with('user').with('appointment').with('products').find(parseInt(orderId))
    )
    const orderItemName = computed(
      () => `${order.value.products[0]?.title} w/ ${order.value.booking_attributes.calendar}`
    )
    const lang = app.i18n.locale
    const langDir = lang !== app.i18n.defaultLocale ? `/${lang}` : ''

    onMounted(() => {
      if (store.$repo(Appointment).all().length === 0) {
        loadAppointments()
      }
    })

    const loadAppointments = async () => {
      if (payload && payload.store) {
        return
      }
      await $axios
        .$get(`/api/booking/appointments`)
        .then((response) => store.$repo(Appointment).save(response.data.values))
        .then(() => store.$repo(Order).with('user').with('appointment').with('products').find(parseInt(orderId)))
        .catch((e) => console.error(e))
    }

    function close() {
      router.push(langDir + '/member/dashboard')
    }

    function calendar() {
      router.push('/calendar')
    }

    return {
      order,
      orderId,
      event,
      close,
      calendar,
      formatDate,
      orderItemName,
      dashify
    }
  }
})
