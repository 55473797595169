













































import { defineComponent, useContext, ref, PropType, reactive, computed } from '@nuxtjs/composition-api'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface MessageUsers extends StoryblokComponent<'MessageUsers'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  title: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<MessageUsers>,
      default: () => {}
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { $axios, payload } = useContext()
    const step = ref(0)
    const message = ref('')
    const userList = reactive([])
    const receipients = computed(() => userList.map((user) => user.name))

    const startMessaging = () => {
      step.value += 1
    }

    const close = () => {
      emit('close')
    }

    const fetchUsers = async (payloadData) => {
      if (payload && payload.store) {
        return
      }
      await $axios.$post(`/api/user/accounts`, payloadData).then((response) => {
        userList.push(...response.data.items.map((user) => user))
      })
    }

    const payloadData = {
      user_ids: props.users
    }
    fetchUsers(payloadData)

    const submit = () => {}

    return {
      close,
      step,
      startMessaging,
      message,
      userList,
      submit,
      fetchUsers,
      receipients
    }
  }
})
