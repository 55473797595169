import { Model } from '@vuex-orm/core'
import SubscriptionPlan from "~/models/SubscriptionPlan";
import SubscriptionFeature from "~/models/SubscriptionFeature";
import SubscriptionUsage from "~/models/SubscriptionUsage";
import SubscriptionSchedule from "~/models/SubscriptionSchedule";

export default class Subscription extends Model {
  static entity = 'subscriptions'

  static fields() {
    return {
      id: this.uid(),
      plan_id: this.attr(null),
      price: this.number(0),
      currency: this.string('EUR'),
      payment_method: this.string(''),
      starts_at: this.string(''),
      ends_at: this.string('').nullable(),
      canceled_at: this.string('').nullable(),
      cancels_at: this.string('').nullable(),
      invoice_period: this.number(1),
      invoice_interval: this.string('month'),
      plan: this.belongsTo(SubscriptionPlan, 'plan_id'),
      features: this.hasMany(SubscriptionFeature, 'plan_subscription_id'),
      usage: this.hasMany(SubscriptionUsage, 'subscription_id'),
      schedules: this.hasMany(SubscriptionSchedule, 'subscription_id')
    }
  }

  id!: number
  canceled_at!: string | null
  cancels_at!: string | null
  ends_at!: string | null
  payment_method!: string
  price!: number
  currency!: string
  invoice_period!: number
  invoice_interval!: string
  plan!: SubscriptionPlan
  features!: SubscriptionFeature[]
  usage!: SubscriptionUsage[]

  get isCancled() {
    return !!this.canceled_at && !!this.cancels_at
  }
}
