











import { defineComponent, PropType } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaCoachingContainer extends StoryblokComponent<'coaching-container'> {
  custom_classes: string
  destination_time: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaCoachingContainer>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)

    return {
      classes,
      dashify
    }
  }
})
