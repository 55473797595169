






import { defineComponent, inject, PropType, computed, unref } from '@nuxtjs/composition-api'
import get from 'get-value'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import GameRank from '@/models/GameRank'

export interface GaGameRank extends StoryblokComponent<'game-rank'> {
  custom_classes: string
  inject_game_ranks_name: string
  inject_game_rank_systems_name: string
  game_rank_system_id: string
  inject_game_account_name: string
  rank_id_property_path: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaGameRank>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses } = helper()
    const classes = [...concatClasses(props.blok), ...['inline-block']]
    const injectedGameRanks = inject(props.blok.inject_game_ranks_name || 'gameRanks')
    // const injectedGameRankSystems = inject(props.blok.inject_game_rank_systems_name || 'gameRankSystems')
    const injectedGameAccounts: any = inject(props.blok.inject_game_account_name, null)

    const checkGameRankSystem = (gameRank: GameRank) => {
      if (props.blok.game_rank_system_id === 'default') {
        return gameRank.is_default

        // const gameRankSystems = unref(injectedGameRankSystems)

        // if (Array.isArray(gameRankSystems)) {
        //   const system = gameRankSystems.filter(
        //     (gameRankSystem: GameRankSystem) => gameRankSystem.id === gameRank.game_rank_system_id
        //   )

        //   return system.length === 1 && system[0].is_default
        // }

        // return false
      }

      return gameRank.game_rank_system_id.toString() === props.blok.game_rank_system_id
    }

    const gameRank = computed(() => {
      const gameRanks = unref(injectedGameRanks)
      const gameAccounts = unref(injectedGameAccounts)
      if (gameAccounts && gameRanks && Array.isArray(gameRanks)) {
        const rankId = get(gameAccounts, props.blok.rank_id_property_path, { default: -1 })

        const filtered = gameRanks.filter(
          (gameRank: GameRank) => checkGameRankSystem(gameRank) && gameRank.id === rankId
        )

        if (filtered.length > 0) {
          return filtered[0]
        }
      }
      return null
    })

    return {
      classes,
      gameRank
    }
  }
})
