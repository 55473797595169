











































































import { computed, defineComponent, PropType, provide, ref, unref, useContext, watch } from '@nuxtjs/composition-api'
import { onClickOutside } from '@vueuse/core'
import useHelper from '@/utils/helper'
import useTemplates from '~/utils/useTemplates'
import { StoryblokComponent } from '~/types/storyblok-vue'
import { useTracking } from '~/utils/useTracking'

export interface Modal extends StoryblokComponent<'Modal'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  dataModel: any
  items: any

  // Parameter that can be defined in the action json of a button
  without_close_button: boolean
  modal_classes: string
  full_size: boolean
  arguments: string
  placeholder_prefix: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<Modal>,
      default: () => {}
    },
    open: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const { concatClasses, dashify } = useHelper()
    const { generateItemAttributes } = useTemplates()
    const nuxtContext = useContext()
    const classes = concatClasses(props.blok)
    const modal = ref()
    const tracking = useTracking()
    const blokCustom = ref(props.blok)
    const innerContent = computed(() => {
      return props.blok.takeInnerContent ? unref(props.blok.content)?.content : unref(props.blok.content)
    })

    onClickOutside(modal, () => close())

    if (props.blok.dataModel) {
      provide('dataModel', props.blok.dataModel)
    }

    if (props.blok.items) {
      provide('items', props.blok.items)
    }

    function close() {
      tracking.triggerEvent(unref(props.blok.content)?.on_close_events)
      context.emit('close')
      nuxtContext.$bus.emit('closeFormModal')
    }

    nuxtContext.$bus.off('updateModal')
    nuxtContext.$bus.on('updateModal', (data: Object) => {
      blokCustom.value = { ...props.blok, ...data }
    })

    const _generateItemAttributes = (contentObject: any) => {
      watch(contentObject, () => {
        if (props.open) {
          tracking.triggerEvent(unref(props.blok.content)?.on_open_events)
        }
      })

      return generateItemAttributes(
        unref(blokCustom).arguments || {},
        contentObject,
        props.blok.placeholder_prefix ? props.blok.placeholder_prefix : 'item'
      )
    }

    return {
      classes,
      dashify,
      close,
      generateItemAttributes: _generateItemAttributes,
      modal,
      innerContent,
      blokCustom
    }
  }
})
