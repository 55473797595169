import { Model } from '@vuex-orm/core'

export default class Country extends Model {
  static entity = 'countries'

  static fields () {
    return {
      id: this.uid(),
      name: this.string(''),
      nicename: this.string(''),
      iso: this.string(''),
    }
  }

  id!: number
  name!: string
  nicename!: string
  iso!: string
}
