var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isBannerVisible)?_c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"transform transition ease-in-out duration-200",class:_vm.classes.concat( [_vm.blok.position === 'bottom'
      ? 'fixed inset-x-0 bottom-0'
      : _vm.blok.position === 'top' && _vm.blok.sticky && _vm.scrollPosition > 100
      ? 'fixed top-0 w-full translate-y-0'
      : _vm.blok.position === 'top' && _vm.blok.sticky && _vm.scrollPosition > 40
      ? '-translate-y-16'
      : '']
  )},_vm._l((_vm.blok.content),function(blokChild){return _c(_vm.dashify(blokChild.component),{key:blokChild._uid,tag:"component",attrs:{"blok":blokChild}})}),1):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }