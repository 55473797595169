






















import { computed, defineComponent, inject, PropType, ref, unref } from '@nuxtjs/composition-api'
import get from 'get-value'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useTemplates from '~/utils/useTemplates'

export interface GaItemConditionEffect extends StoryblokComponent<'item-condition-effect'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  item_name: string
  second_index_name: string
  item_prop: string
  compare_to_value: string
  use_item_index: boolean
  visibility_compare: string
  use_null_as_default: boolean
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaItemConditionEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify, checkConditionEffect } = useHelper()
    const { generateItemAttributes } = useTemplates()
    const classes = concatClasses(props.blok)
    const isStoryblokEdit = inject('storyblokEdit', ref(false))
    const item = inject(props.blok.item_name ? props.blok.item_name : 'item', {})
    const itemIndex = inject(props.blok.item_name ? props.blok.item_name + '_index' : 'item_index', '0')
    const itemIndex2 = inject(
      props.blok.second_index_name ? props.blok.second_index_name + '_index' : 'item2_index',
      '0'
    )
    const getProp = props.blok.use_item_index ? itemIndex + '.' + props.blok.item_prop : props.blok.item_prop
    const unrefedItem = unref(item)
    const valueToCheck = get(unrefedItem, getProp.replace('{index}', itemIndex2), {
      default: props.blok.use_null_as_default ? null : unrefedItem
    })

    const isVisible = computed(() => {
      return checkConditionEffect(valueToCheck, props.blok.visibility_compare, props.blok.compare_to_value)
    })

    const generateRepeatableItemAttributes = (item_style: any) => {
      return generateItemAttributes(
        {
          value: valueToCheck
        },
        item_style,
        'check'
      )
    }

    return {
      classes,
      dashify,
      isVisible,
      isStoryblokEdit,
      generateRepeatableItemAttributes
    }
  }
})
