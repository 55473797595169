<template>
  <nav
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:bg-transparent sm:px-6"
    aria-label="Pagination"
  >
    <!--
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        Showing
        <span class="font-medium">tbd</span>
        to
        <span class="font-medium">tbd</span>
        of
        <span class="font-medium">{{ rooms }}</span>
        results
      </p>
    </div>
    -->
    <div class="flex-1 flex justify-between sm:justify-end space-x-2">
      <blok-link
        :disabled="page <= 1"
        :class="[{ 'bg-gray-300 hover:bg-gray-300 dark:bg-gray-600 text-white cursor-not-allowed': page <= 1 }]"
        :blok="{
          custom_classes:
            'relative inline-flex items-center  px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer',
          text: 'Previous'
        }"
        @click="page >= 2 ? changePage((page += -1)) : null"
      >
      </blok-link>
      <blok-link
        :blok="{
          custom_classes:
            'relative inline-flex items-center  px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer',
          text: 'Next'
        }"
        @click="changePage((page += 1))"
      >
      </blok-link>
    </div>
  </nav>
</template>

<script>
import { defineComponent, ref, useRoute, useRouter } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    roomsCount: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const rooms = ref(0)
    const page = ref(1)

    const changePage = (value) => {
      if (page.value <= 1) {
        value = 1
      }
      router.push({
        path: route.value.path,
        query: {
          ...route.value.query,
          page: value
        }
      })
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return {
      changePage,
      page,
      rooms
    }
  }
})
</script>
