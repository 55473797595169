





































import { computed, defineComponent, PropType, provide, ref, unref, useContext } from '@nuxtjs/composition-api'
import useHelper from '~/utils/helper'
import useStoryblok from '~/utils/storyblok'
import { usePopper } from '~/utils/usePopper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import { Link } from '~/components/link/Link.vue'

export interface Dropdown extends StoryblokComponent<'dropdown'> {
  custom_classes: string
  link_style: Link[]
  items: StoryblokComponent<any>[] | null
  content: StoryblokComponent<any>[]
  button: StoryblokComponent<any>[]
  menu: string
}

export default defineComponent({
  name: 'GaDropdown',
  props: {
    blok: {
      type: Object as PropType<Dropdown>,
      default: () => {}
    }
  },
  setup(props) {
    const { dashify, concatClasses, checkScopes } = useHelper()
    const { getTemplate } = useStoryblok()
    const classes = concatClasses(props.blok)
    const dropdownPopoverShow = ref(false)
    const nuxtContext = useContext()
    const [trigger1, container1] = usePopper({
      placement: 'bottom-start',
      strategy: 'fixed'
    })
    const { menu, link_style } = props.blok

    const getMenuItems = () => {
      if (nuxtContext.payload) {
        const menuItemStory = nuxtContext.payload.menuItemStories.find(
          (menuItemStory) => menuItemStory.uuid === props.blok.menu
        )
        return menuItemStory ? menuItemStory.content.children : null
      }
      return getTemplate(menu, { templateProperty: 'children' })
    }

    const dropdownItems = menu ? getMenuItems() : props.blok.items
    const checkedDropDownItems = computed(() => {
      return (
        unref(dropdownItems)?.filter((item: any) =>
          checkScopes(nuxtContext, [
            ...(item.auth || []),
            ...(item.plan_features || []),
            ...(item.is_coach ? [item.is_coach] : [])
          ])
        ) || []
      )
    })
    provide('dropdownItems', checkedDropDownItems)
    provide('linkStyle', link_style)

    const toggleDropdown = () => {
      dropdownPopoverShow.value = !dropdownPopoverShow.value
    }

    return {
      classes,
      dashify,
      toggleDropdown,
      dropdownPopoverShow,
      trigger1,
      container1
    }
  }
})
