











import { defineComponent, inject } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'

export default defineComponent({
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)
    const activeTabUid = inject('activeTabUid', '')

    return {
      classes,
      dashify,
      activeTabUid
    }
  }
})
