


























import { defineComponent, PropType, ref, useContext, computed, onBeforeMount } from '@nuxtjs/composition-api'
import { formatDate } from '@/utils/date'
import TopFilter from '~/components/calendar/Filter/TopFilter.vue'
import MainFilter from '~/components/calendar/Filter/MainFilter.vue'
import DatePicker from '~/components/calendar/Filter/DatePicker.vue'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useHelper from '@/utils/helper'
import AppointmentModel from '@/models/Appointment'
import CoachingList from '~/components/calendar/New/CoachingList.vue'

export interface Calendar extends StoryblokComponent<'caelndar'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  book_action_template_id: string
  booked_out_text: string
  booked_text: string
  not_yet_bookable_text: string
  book_action: string
  book_custom_action: string
  book_action_validate_form: boolean
  book_action_json: string
  book_action_include_data_model: boolean
}

export default defineComponent({
  components: {
    CoachingList,
    TopFilter,
    MainFilter,
    DatePicker
  },
  props: {
    blok: {
      type: Object as PropType<Calendar>,
      default: () => {}
    }
  },
  setup(props) {
    const { store, $bus, $axios } = useContext()
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)

    const appointmentId = ref()
    const showCancelAppointment = ref(false)
    const isProgressBooking = ref(false)
    const isProgressCancellation = ref(false)

    const toggleModal = () => {
      showCancelAppointment.value = !showCancelAppointment.value
    }

    const emitAppointmentId = (id: Number) => {
      showCancelAppointment.value = true
      appointmentId.value = id
    }

    const isBusy = ref(false)
    const loggedIn = computed(() => store.state.auth.loggedIn)

    const cancelAppointment = async () => {
      isProgressCancellation.value = true
      const appointment = appointmentId.value

      await $axios
        .$post(`/api/booking/appointments/${appointment}/cancel`)
        .then(() => {
          store.$repo(AppointmentModel).destroy(appointment)
          isProgressCancellation.value = false
        })
        .catch((e) => console.error(e))
        .finally(() => {
          $bus.emit('refetchStore', 'appointments')
          $bus.emit('refetchStore', 'appointmentRooms')
        })
      isProgressCancellation.value = false
      showCancelAppointment.value = false
    }

    onBeforeMount(() => {
      $bus.emit('refetchStore', 'appointmentTypes')
      $bus.emit('refetchStore', 'appointments')
      $bus.emit('refetchStore', 'coaches')
      $bus.emit('refetchStore', 'appointmentRooms')
    })

    return {
      classes,
      dashify,
      loggedIn,
      formatDate,
      isBusy,
      showCancelAppointment,
      isProgressBooking,
      isProgressCancellation,
      emitAppointmentId,
      appointmentId,
      cancelAppointment,
      toggleModal
    }
  }
})
