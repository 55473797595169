
















import { defineComponent, inject, PropType, provide, ref, useContext } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaActionEffect extends StoryblokComponent<'action-effect'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  action: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaActionEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const isStoryblokEdit = inject('storyblokEdit', ref(false))
    const isVisible = ref(false)
    const item = ref([])

    provide('item', item)
    nuxtContext.$bus.on(props.blok.action, (data) => {
      isVisible.value = false
      if (data) {
        item.value = data
        isVisible.value = true
      }
    })

    return {
      classes,
      dashify,
      isVisible,
      isStoryblokEdit
    }
  }
})
