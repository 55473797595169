import { Model } from '@vuex-orm/core'
import GameRank from "~/models/GameRank";

export default class GameRankSystem extends Model {
  static entity = 'gameRankSystems'

  static fields () {
    return {
      id: this.uid(),
      name: this.string(''),
      game_id: this.attr(null),
      is_default: this.boolean(false),
      game_ranks: this.hasMany(GameRank, 'game_rank_system_id'),
    }
  }

  id!: number
  name!: string
  game_id!: number
  is_default!: boolean | null
}
