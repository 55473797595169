















































































































import { defineComponent, useContext, ref, watch, computed } from '@nuxtjs/composition-api'
import { useDebounce } from '@vueuse/core'
import useStoryblok from '@/utils/storyblok'
import useHelper from '@/utils/helper'
import BlogItem from '~/components/blog/BlogItem.vue'

export default defineComponent({
  name: 'GaCategoryPage',
  components: { BlogItem },
  props: {
    blok: {
      type: Object,
      default: () => {}
    },
    uuid: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { dashify, concatClasses } = useHelper()
    const { getPagedStories } = useStoryblok()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const lang = nuxtContext.i18n.locale
    const isLoading = ref(false)
    const searchPlaceholder = ref(lang === 'en' ? 'Search blog posts by title' : 'Blogposts nach Titel durchsuchen')

    const searchTerm = ref('')
    const perPage = ref(12)
    const currentPage = ref(1)
    const totalBlogs = ref(0)
    const showPager = ref(false)

    const blogsFilter = ref({
      per_page: perPage.value,
      page: currentPage.value,
      by_slugs: `${nuxtContext.i18n.locale}/blog/articles/*`,
      resolve_relations: 'blog-page.author,blog-page.categories',
      filter_query: {
        categories: {
          in_array: props.uuid
        }
      }
    })
    const debouncedFilter = useDebounce(blogsFilter, 200)
    watch(debouncedFilter, () => {
      blogs.value = []

      getBlogs().then((result) => {
        blogs.value = result
      })
    })

    const getBlogs = async () => {
      isLoading.value = true
      if (process.client) {
        return await getPagedStories(nuxtContext, blogsFilter.value).then((result) => {
          isLoading.value = false
          totalBlogs.value = result.total

          return result.stories
        })
      }
      return []
    }

    const blogs = ref([])
    getBlogs().then((result) => {
      blogs.value = result
    })

    const updateFilter = () => {
      currentPage.value = 1

      if (searchTerm.length === '') {
        blogsFilter.value = {
          ...blogsFilter.value,
          page: currentPage.value,
          filter_query: {
            categories: {
              ...blogsFilter.value.filter_query.category
            }
          }
        }
      } else {
        blogsFilter.value = {
          ...blogsFilter.value,
          page: currentPage.value,
          filter_query: {
            ...blogsFilter.value.filter_query,
            title: {
              like: `*${searchTerm.value}*`
            }
          }
        }
      }
    }

    const setPage = (pageNumber) => {
      currentPage.value = pageNumber

      blogsFilter.value = {
        ...blogsFilter.value,
        page: currentPage.value
      }
    }

    const isCurrentPage = (linkPage) => {
      return linkPage === currentPage.value
    }

    watch(totalBlogs, () => {
      showPager.value = totalBlogs.value > perPage.value
    })

    const getPages = computed(() => {
      const numberOfPages =
        totalBlogs.value % perPage.value === 0 ? totalBlogs.value / perPage.value : totalBlogs.value / perPage.value + 1
      return Array.from({ length: numberOfPages }, (_, i) => i + 1)
    })

    return {
      blogs,
      classes,
      dashify,
      isLoading,
      showPager,
      getPages,
      searchPlaceholder,
      searchTerm,
      setPage,
      isCurrentPage,
      updateFilter
    }
  },
  head: {}
})
