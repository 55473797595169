










































import { defineComponent } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'

export default defineComponent({
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const { fa } = helper()

    return {
      fa
    }
  }
})
