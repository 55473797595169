





























import { defineComponent, useContext, reactive, useRouter, useRoute, onMounted } from '@nuxtjs/composition-api'
import { formatDate } from '~/utils/date'
import useFilters from '~/composables/useFilters'

export default defineComponent({
  setup() {
    const { $dayjs, i18n } = useContext()
    const route = useRoute()
    const router = useRouter()

    const { query } = useFilters()

    const modelConfig = {
      start: {
        timeAdjust: '00:00:00'
      },
      end: {
        timeAdjust: '23:59:59'
      }
    }

    const attributes = reactive([{ key: 'today', highlight: true }])

    const clicked = (event: any) => {
      const { start, end } = event
      query.minDate = $dayjs(start).unix().toString()
      query.maxDate = $dayjs(end).unix().toString()
      attributes[0].highlight = false
      router.push({
        path: route.value.path,
        query
      })
    }

    const reset = () => {
      query.minDate = $dayjs().unix().toString()
      query.maxDate = $dayjs().add(1, 'month').unix().toString()

      router.push({
        path: route.value.path,
        query
      })
    }

    onMounted(() => {
      query.minDate = $dayjs().unix().toString()
      query.maxDate = $dayjs().add(7, 'day').unix().toString()
      router.push({
        path: route.value.path,
        query
      })
    })

    const range = reactive({
      start: $dayjs().format(),
      end: $dayjs().add(7, 'day').format()
    })

    return {
      i18n,
      query,
      clicked,
      range,
      formatDate,
      reset,
      attributes,
      modelConfig
    }
  }
})
