



















import { defineComponent, PropType, inject, unref, ref, computed } from '@nuxtjs/composition-api'
import { LineChart } from 'vue-chart-3'
import { ChartData, ChartOptions } from 'chart.js'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaSkillStatistics extends StoryblokComponent<'SkillStatistics'> {
  custom_classes: string
  inject_gameaccounts_name: string
  inject_gameranks_name: string
  height: string
  startLabel: string
  currentLabel: string
  goalLabel: string
  textWhenNoRanksSetup: string
  gameName: string
}

export default defineComponent({
  components: {
    LineChart
  },
  props: {
    blok: {
      type: Object as PropType<GaSkillStatistics>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses } = helper()
    const classes = concatClasses(props.blok)
    const gameAccounts = inject(props.blok.inject_gameaccounts_name || 'items', [])
    const gameRanks = inject(props.blok.inject_gameranks_name || 'items', [])
    const rankTiers = ref([])

    let defaultGameRankProgress = null
    const getGameRankProgress = () => {
      if (defaultGameRankProgress == null) {
        const unrefGameAccounts = unref(gameAccounts)

        if (unrefGameAccounts.length > 0) {
          unrefGameAccounts.forEach((gameAccount) => {
            if (gameAccount.game_rank_progress != null && gameAccount.game_rank_progress.length > 0) {
              gameAccount.game_rank_progress.forEach((progress) => {
                if (progress.is_default) {
                  defaultGameRankProgress = progress
                }
              })
            }
          })
        }
      }

      return defaultGameRankProgress
    }

    const getCorrectGameRanks = () => {
      if (getGameRankProgress() != null) {
        if (defaultGameRankProgress.rank_current_custom != null) {
          return []
        }

        const unrefedGameRanks = unref(gameRanks)
        return unrefedGameRanks.filter(
          (gameRank) => gameRank.game_rank_system_id === defaultGameRankProgress.game_rank_system_id
        )
      }

      return []
    }

    const getHightestTier = () => {
      const gameRanks = getCorrectGameRanks()
      const sortedGameRanks = gameRanks.sort((a, b) => b.tier - a.tier)
      return sortedGameRanks.length > 0 ? sortedGameRanks[0].tier : -1
    }

    const getRankTiers = () => {
      if (rankTiers.value.length === 0) {
        if (getGameRankProgress() != null) {
          if (
            defaultGameRankProgress.rank_start != null &&
            defaultGameRankProgress.rank_current != null &&
            defaultGameRankProgress.rank_goal != null
          ) {
            const gameRanks = getCorrectGameRanks()

            if (gameRanks.length > 0) {
              const start = gameRanks.find((rank) => rank.id === defaultGameRankProgress.rank_start)
              const current = gameRanks.find((rank) => rank.id === defaultGameRankProgress.rank_current)
              const goal = gameRanks.find((rank) => rank.id === defaultGameRankProgress.rank_goal)

              rankTiers.value = [start.tier, current.tier, goal.tier]
            }
          } else if (
            defaultGameRankProgress.rank_start_custom != null &&
            defaultGameRankProgress.rank_current_custom != null &&
            defaultGameRankProgress.rank_goal_custom != null
          ) {
            rankTiers.value = [
              defaultGameRankProgress.rank_start_custom,
              defaultGameRankProgress.rank_current_custom,
              defaultGameRankProgress.rank_goal_custom
            ]
          }
        }
      }

      return rankTiers
    }

    const chartData = computed<ChartData<'line'>>(() => {
      if (getGameRankProgress() != null) {
        if (
          defaultGameRankProgress.rank_start != null &&
          defaultGameRankProgress.rank_current != null &&
          defaultGameRankProgress.rank_goal != null
        ) {
          const gameRanks = getCorrectGameRanks()

          if (gameRanks.length === 0) {
            return {
              labels: [],
              datasets: []
            }
          }
        }

        return {
          labels: [props.blok.startLabel, props.blok.currentLabel, props.blok.goalLabel],
          datasets: [
            {
              label: 'Ranks',
              data: unref(getRankTiers()),
              backgroundColor: '#77CEFF',
              fill: false,
              borderColor: 'rgb(75, 192, 192)'
            }
          ]
        }
      }

      return {
        labels: [],
        datasets: []
      }
    })

    const options = computed<ChartOptions<'line'>>(() => {
      const ranks = [...unref(getRankTiers())].sort((a, b) => b - a)
      let max = 0

      if (ranks.length > 0) {
        max = ranks[0] + 2

        if (
          defaultGameRankProgress.rank_start_custom != null &&
          defaultGameRankProgress.rank_current_custom != null &&
          defaultGameRankProgress.rank_goal_custom != null
        ) {
          const range = ranks[0] - ranks[2]
          max = ranks[0] + range / 6.0
        }
      }

      return {
        scales: {
          y: {
            max,
            display: false
          }
        },
        plugins: {
          tooltip: {
            enabled: false
          },
          responsive: true,
          legend: {
            display: false
          }
        }
      }
    })

    let tooltipWidth = 0
    let tooltipHeight = 0
    const plugins = computed<ChartOptions<'line'>>(() => {
      if (getGameRankProgress() != null) {
        const getOrCreateTooltip = (chart, element, index) => {
          let tooltipEl = chart.canvas.parentNode.querySelector('div#tp' + index)

          if (!tooltipEl) {
            tooltipEl = document.createElement('div')
            tooltipEl.id = 'tp' + index
            tooltipEl.style.opacity = 1
            tooltipEl.style.pointerEvents = 'none'
            tooltipEl.style.position = 'absolute'
            tooltipEl.style.left = 0
            tooltipEl.style.top = 0

            if (element.$context.raw != null) {
              if (
                defaultGameRankProgress.rank_start != null &&
                defaultGameRankProgress.rank_current != null &&
                defaultGameRankProgress.rank_goal != null
              ) {
                const img = document.createElement('img')
                img.src = require(`~/assets/games/${props.blok.gameName}/ranks/rank_${element.$context.raw}.png`)
                img.style.width = '50px'
                tooltipEl.appendChild(img)
                tooltipWidth = 50
                tooltipHeight = 50
              } else if (
                defaultGameRankProgress.rank_start_custom != null &&
                defaultGameRankProgress.rank_current_custom != null &&
                defaultGameRankProgress.rank_goal_custom != null
              ) {
                const text = document.createElement('div')
                text.innerText = element.$context.raw.toLocaleString()
                text.style.color = ['lightgray', 'white', 'black'][element.$context.index]
                text.style.background = [
                  '#343339',
                  'linear-gradient(30deg, rgba(0,178,255,1) 0%, rgba(255,0,101,1) 100%)',
                  '#c7f202'
                ][element.$context.index]
                text.classList.add('statistics-custom')
                tooltipEl.appendChild(text)
                tooltipWidth = element.$context.index === 1 ? 95 : 85
                tooltipHeight = 40
                text.style.lineHeight = '25px'
              }
            }

            tooltipEl.style.width = `${tooltipWidth}px`
            chart.canvas.parentNode.appendChild(tooltipEl)
          }

          return tooltipEl
        }

        const plugin = {
          id: 'tooltips',

          afterUpdate(chart) {
            chart._custom_tooltips = []
            chart.config.data.datasets.forEach(function (_dataset, datasetIndex) {
              chart.getDatasetMeta(datasetIndex).data.forEach(function (element, index) {
                const tooltip = getOrCreateTooltip(chart, element, index)
                tooltip.target_element = [{ element, datasetIndex, index }]
                chart._custom_tooltips.push(tooltip)
              })
            })
          },

          afterDraw(chart) {
            chart._custom_tooltips.forEach((tooltip) => {
              const x = tooltip.target_element[0].element.x - tooltipWidth * 0.5
              const y = tooltip.target_element[0].element.y - tooltipHeight

              tooltip.style.left = x + 'px'
              tooltip.style.top = y + 'px'
            })
          }
        }

        return [plugin]
      }

      return []
    })

    return {
      classes,
      chartData,
      options,
      plugins,
      rankTiers,
      getHightestTier
    }
  }
})
