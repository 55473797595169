import { Model } from '@vuex-orm/core'

export default class GameRank extends Model {
  static entity = 'gameRanks'

  static fields () {
    return {
      id: this.uid(),
      game_id: this.attr(null),
      game_rank_system_id: this.attr(null),
      name: this.attr(null),
      tier: this.attr(null),
      is_default: this.attr(null),
    }
  }

  name!: string
  id!: number
  tier!: number
  game_id!: number
  game_rank_system_id!: number
  is_default!: boolean | null
}
