
































import { defineComponent, inject, useAsync } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import useStoryblok from '@/utils/storyblok'

export default defineComponent({
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { dashify } = helper()
    const { getStory } = useStoryblok()
    const story = inject('story', {
      story: {
        content: {
          component: ''
        }
      }
    })

    const getTemplate = (uuid) =>
      uuid
        ? useAsync(async () => {
            return await getStory({
              path: uuid,
              isUuid: true
            }).then((story) => story.content.content)
          }, uuid)
        : []

    const headerTemplate = props.blok.header_content
      ? props.blok.header_content
      : getTemplate(props.blok?.header_template)
    const footerTemplate = props.blok.footer_content
      ? props.blok.footer_content
      : getTemplate(props.blok?.footer_template)
    const sidebarLeft = props.blok.sidebar_left_content
      ? props.blok.sidebar_left_content
      : getTemplate(props.blok?.sidebar_left_template_id)

    return {
      dashify,
      headerTemplate,
      footerTemplate,
      sidebarLeft,
      story
    }
  }
})
