



























































































































































import {
  defineComponent,
  useContext,
  ref,
  inject,
  useStore,
  onMounted,
  PropType,
  useRouter
} from '@nuxtjs/composition-api'
import { formatDate } from '@/utils/date'
import AppointmentType from '~/models/AppointmentType'
import StoreCredit from '~/models/StoreCredit'
import SubscriptionFeature from '~/models/SubscriptionFeature'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface OrderSummary extends StoryblokComponent<'OrderSummary'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  title: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<OrderSummary>,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  setup(props, { emit }) {
    const { $axios, $bus } = useContext()
    const store = useStore()
    const voucher = ref('')
    const isVoucherValid = ref(false)
    const message = ref('')
    const newPrice = ref(0)
    const userData = inject('userData', ref({ subscriptions: [] }))
    const appointmentType = store.$repo(AppointmentType).with('product').find(props.item.appointmentTypeID)
    const planFeatureTag = appointmentType && appointmentType.product ? appointmentType.product.plan_feature_tag : ''
    const subCount = ref(0)
    const creditCount = ref(0)
    const isFree = ref(false)
    const featureUsageOverall = ref(0)
    const featureUsageUsed = ref(0)
    const isBusy = ref(false)
    const router = useRouter()

    const submitVoucher = async () => {
      const payload = {
        code: voucher.value,
        data: {
          classId: props.item.id
        }
      }
      const response = await $axios
        .post(`/api/payment/voucher/check`, payload)
        .then((response) => response.data)
        .catch((e) => {
          console.error(e.message)
          message.value = e.message
        })
      if (response?.success) {
        isVoucherValid.value = true
        message.value = response.message
        newPrice.value = response.data.reward
      }
    }

    const checkout = () => {
      isBusy.value = true
      emit('on-verified')
    }

    const goToCreditPage = () => {
      $bus.emit('closeFormModal')
      router.push('/pricing-credits')
    }

    const cancel = () => {
      emit('cancel')
    }

    const checkSubscriptionStatus = () => {
      if (userData.value && userData.value.subscriptions && userData.value.subscriptions.length > 0) {
        const planFeature = store
          .$repo(SubscriptionFeature)
          .with('usage')
          .where('tag', planFeatureTag)
          .where('plan_subscription_id', userData.value.subscriptions[0].id)
          .first()
        if (planFeature && planFeature.value) {
          if (planFeature.value === ('true' || 'false')) {
            isFree.value = planFeature.value === 'true'
          } else {
            featureUsageOverall.value = parseInt(planFeature.value)
            featureUsageUsed.value = parseInt(planFeature.usage?.used) || 0
            isFree.value = featureUsageOverall.value > featureUsageUsed.value
            subCount.value = featureUsageOverall.value - featureUsageUsed.value
          }
        }
      }
    }

    const checkCredits = () => {
      console.log(userData.value)
      if (userData.value && userData.value.store_credits && userData.value.store_credits.length > 0) {
        const storeCredits = store.$repo(StoreCredit).where('product_tag', planFeatureTag).where('usage', false).get()
        creditCount.value = storeCredits.length
      }
    }

    onMounted(() => {
      document.body.classList.add('overflow-hidden')
      checkSubscriptionStatus()
      checkCredits()
    })

    return {
      formatDate,
      voucher,
      submitVoucher,
      isVoucherValid,
      checkout,
      goToCreditPage,
      cancel,
      message,
      newPrice,
      checkSubscriptionStatus,
      isFree,
      isBusy,
      subCount,
      creditCount
    }
  }
})
