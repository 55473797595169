











import { computed, defineComponent, PropType, provide, useContext, useRoute, useStore } from '@nuxtjs/composition-api'
import { Query } from '@vuex-orm/core'
import get from 'get-value'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import SubscriptionPlan from '~/models/SubscriptionPlan'
import Order from '~/models/Order'
import Subscription from '~/models/Subscription'

export interface GaProvideUserData extends StoryblokComponent<'provide-user-data'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  plan_filter_whitelist: string[]
  plan_filter_whitelist_local: string[]
  plan_filter_whitelist_dev: string[]
  user_relation_object: string
  provide_name: string
  provide_user_property: string
  store_repository: string
  repository_with_relations: boolean
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaProvideUserData>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const store = useStore()
    const route = useRoute()
    let { entity, entityId } = route.value.query
    const { orderId } = route.value.query
    const planFilterWhitelist = computed(() => {
      if (nuxtContext.$config.dev) {
        return nuxtContext.$config.isLocal
          ? props.blok.plan_filter_whitelist_local
          : props.blok.plan_filter_whitelist_dev
      }
      return props.blok.plan_filter_whitelist || []
    })

    let repo: null | Query<SubscriptionPlan> | Query<Order> = null
    console.log(entity)
    console.log(orderId)
    if (orderId) {
      entity = 'orders'
      entityId = orderId
    }

    const repository = entity || props.blok.store_repository

    switch (repository) {
      case 'plans': {
        nuxtContext.$bus.emit('refetchStore', 'plans')
        if (store.$repo(SubscriptionPlan).all().length === 0) {
          nuxtContext.$bus.emit('refetchStore', 'plans')
        }
        let planRepo = store.$repo(SubscriptionPlan).query().orderBy('sort_order')
        if (props.blok.repository_with_relations) {
          planRepo = planRepo.with('features', (query) => {
            query.orderBy('sort_order')
          })
        }
        if (planFilterWhitelist.value && planFilterWhitelist.value.length) {
          planRepo = planRepo.where('id', (planId: number) => {
            return planFilterWhitelist.value.includes(planId.toString())
          })
        }
        repo = planRepo
        break
      }

      case 'orders': {
        nuxtContext.$bus.emit('refetchStore', 'user')
        repo = store.$repo(Order).query()
        if (props.blok.repository_with_relations) {
          repo = repo.with('products').with('appointment').with('user')
        }
        break
      }

      case 'subscriptions': {
        nuxtContext.$bus.emit('refetchStore', 'user')
        repo = store.$repo(Subscription).with('plan')
        break
      }

      default:
        break
    }

    const provideStoreData = computed(() => {
      if (entityId) {
        const item = repo?.find(parseInt(entityId))
        if (item && props.blok.store_repository_property) {
          return get(item, props.blok.store_repository_property)
        }
        return item
      }
      return repo?.get()
    })

    provide(props.blok.provide_name || (entityId ? 'item' : 'items'), provideStoreData)

    return {
      classes,
      dashify
    }
  }
})
