import { ref, onMounted, watchEffect } from '@nuxtjs/composition-api'
import { createPopper, Options } from '@popperjs/core'

export function usePopper(options: Options) {
  let reference = ref(null)
  let popper = ref(null)

  onMounted(() => {
    watchEffect(onInvalidate => {
      if (!popper.value) return
      if (!reference.value) return

      // @ts-ignore
      let popperEl = popper.value.el || popper.value
      // @ts-ignore
      let referenceEl = reference.value.el || reference.value

      if (!(referenceEl instanceof HTMLElement)) return
      if (!(popperEl instanceof HTMLElement)) return

      let { destroy } = createPopper(referenceEl, popperEl, options)

      onInvalidate(destroy)
    })
  })

  return [reference, popper]
}
