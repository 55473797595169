






import { defineComponent } from '@nuxtjs/composition-api'
import '~/plugins/rich-text-renderer'
import useHelper from '~/utils/helper'
export default defineComponent({
  name: 'GaText',
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses } = useHelper()
    const classes = concatClasses(props.blok)

    !props.blok.without_auto_format && classes.push('prose lg:prose-lg dark:prose-dark')
    props.blok.full_width && classes.push('max-w-none')

    return {
      classes
    }
  }
})
