import dayjs from 'dayjs'

export const state = () => ({
  appointments: [],
  events: [],
  busy: false,
  isCancelling: false,
  event: {}
})

export const getters = {}

export const mutations = {
  setAppointments(state, appointments) {
    state.appointments = appointments
  },
  setEvents(state, events) {
    state.events = events
  },
  setEvent(state, event) {
    state.event = event
  },
  setBusy(state, busy) {
    state.busy = busy
  },
  setCancellation(state, isCancelling) {
    state.isCancelling = isCancelling
  }
}

export const actions = {
  async nuxtServerInit({ dispatch }) {
    await dispatch('appointments')
    await dispatch('events')
  },
  async appointments({ commit }) {
    const response = await this.$axios.$get(`/api/booking/appointments`)
    commit('setAppointments', response.data.values)
  },
  async cancel({ commit, dispatch }, order_id) {
    commit('setCancellation', true)
    await this.$axios.$post(`/api/payment/order/${order_id}/cancel`)
    commit('setCancellation', false)
    dispatch('events')
    dispatch('appointments')
  },
  async events({ commit }, day) {
    try {
      commit('setBusy', true)
      let params
      if (typeof day === 'object') {
        params = Object.assign({}, { params: { ...day } })
      } else {
        params = Object.assign({}, { params: { month: dayjs().format('YYYY-MM') } })
      }
      const response = await this.$axios.$get(`/api/booking/availability/classes`, params)
      commit('setEvents', response.data.values)
    } catch (e) {
      console.log(e)
    } finally {
      commit('setBusy', false)
    }
  },
  async order({ commit }, payload) {
    const response = await this.$axios.$post(`/api/payment/order/booking`, payload)
    const redirect = response.data.redirect_url
    return redirect
  }
}
