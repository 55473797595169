import Vue from 'vue'
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer'
import RichTextComponentWrapper from '~/components/richTextComponentWrapper/RichTextComponentWrapper.vue'

Vue.use(VueRichTextRenderer, {
  resolvers: {
    components: {
      link: RichTextComponentWrapper,
      grid: RichTextComponentWrapper,
      container: RichTextComponentWrapper,
      'feature-section': RichTextComponentWrapper,
      text: RichTextComponentWrapper,
      'simple-text': RichTextComponentWrapper,
      image: RichTextComponentWrapper,
      table: RichTextComponentWrapper,
      tweet: RichTextComponentWrapper,
      vimeo: RichTextComponentWrapper,
      tabs: RichTextComponentWrapper,
    }
  }
})
