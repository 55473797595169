import emailVerification from "~/components/form/handleResponses/emailVerification";
import login from "~/components/form/handleResponses/login";

export default function () {

  return {
    emailVerification: emailVerification,
    login: login,
  }
}
