





















































import { computed, defineComponent, inject, onMounted, PropType, provide, ref, unref } from '@nuxtjs/composition-api'
import get from 'get-value'
import set from 'set-value'
import _ from 'lodash'
import helper from '~/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useTemplates from '~/utils/useTemplates'
import RepeatableItem from '~/components/list/RepeatableItem.vue'

export interface GaRepeatable extends StoryblokComponent<'repeatable'> {
  content: StoryblokComponent<any>[]
  content_after: StoryblokComponent<any>[]
  item_style: StoryblokComponent<any>[]
  custom_classes: string
  data_model: string
  inject_items_name: string
  provide_item_name: string
  provide_selected_item_name: string
  set_provide_selected_item_to_datamodel: boolean
  preselect_first_item: boolean
  inject_items_prop: string
  placeholder_prefix: string
  provide_data_model: boolean
  show_more_item_limit: number
  with_show_more: boolean
  with_validation: boolean
  use_item_index: boolean
  flip_items_array: boolean
  type: 'div' | 'ul' | 'ol' | 'tr' | 'span' | 'section' | 'dl'
}

export default defineComponent({
  components: { RepeatableItem },
  props: {
    blok: {
      type: Object as PropType<GaRepeatable>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const { generateItemAttributes } = useTemplates()
    const classes = concatClasses(props.blok)
    const injectName = props.blok.inject_items_name || 'items'
    const defaultItems = ref([])
    const injectItems = inject(injectName, defaultItems)
    const dataModel = inject('dataModel', {})
    const itemIndex = inject('item_index', 0)
    const showMore = ref(false)
    const focusedItemId = ref(null)
    const allItemscount = ref(0)
    const items = computed(() => {
      let getItems = []
      if (props.blok.inject_items_prop) {
        const getProp = props.blok.use_item_index
          ? itemIndex + '.' + props.blok.inject_items_prop
          : props.blok.inject_items_prop
        getItems = get(unref(injectItems), getProp)
      } else {
        getItems = unref(injectItems) ?? []
      }
      allItemscount.value = getItems.length

      if (props.blok.flip_items_array) {
        getItems = _.reverse(getItems)
      }

      if (!showMore.value && props.blok.with_show_more && getItems.length > (props.blok?.show_more_item_limit ?? 2)) {
        getItems = getItems.slice(0, props.blok?.show_more_item_limit ?? 2)
      }

      return getItems
    })

    onMounted(() => {
      if (props.blok.provide_selected_item_name && props.blok.preselect_first_item) {
        focusedItemId.value = items.value[0]?.id
        set(dataModel, props.blok.provide_selected_item_name, items.value[0])
      }
    })

    const handleClick = (itemId: any) => {
      focusedItemId.value = itemId
      const selectedItem = items.value.find((object: any) => object.id === focusedItemId.value)
      set(dataModel, props.blok.provide_selected_item_name, selectedItem)
    }

    provide('focusedItemId', focusedItemId)
    if (props.blok?.provide_selected_item_name) {
      const selectedItem = computed(() => items.value.find((object: any) => object.id === focusedItemId.value))

      provide(props.blok.provide_selected_item_name, selectedItem)
    }

    const generateRepeatableItemAttributes = (item: any) => {
      return generateItemAttributes(
        item,
        props.blok.item_style,
        props.blok.placeholder_prefix ? props.blok.placeholder_prefix : 'item'
      )
    }

    return {
      classes,
      dashify,
      generateRepeatableItemAttributes,
      items,
      allItemscount,
      showMore,
      focusedItemId,
      handleClick
    }
  }
})
