import { Model } from '@vuex-orm/core'

export default class SubscriptionPlanFeature extends Model {
  static entity = 'subscriptionPlanFeatures'

  static fields() {
    return {
      id: this.uid(),
      plan_id: this.attr(null),
      name: this.string(''),
      sort_order: this.number(0),
      tag: this.string(''),
      value: this.string('false'),
      description: this.string('')
    }
  }

  id!: number
  name!: string
  tag!: string
  value!: string
  sort_order!: number
  description!: string
}
