// @ts-ignore
import { useContext } from '@nuxtjs/composition-api'
import { getPlaceholderPipeRegexKeyChain } from './placeholderPipes'
import { Link } from '~/components/link/Link.vue'
import useHelper from '~/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export default function () {
  const { dashify, replacePlaceholder, stringToJson } = useHelper()
  const nuxtContext = useContext()

  const generateNavItemAttributes = (item: any, blok_style: Link[]) => {
    const isLinkTemplate = blok_style && blok_style[0].component === 'link'
    const blokTemplateClone = stringToJson(JSON.stringify(blok_style[0]))
    const placeholder = [
      {
        key: /%link_text%/,
        value: item.label
      },
      {
        key: /%icon_type%/,
        value: item.icon_type
      },
      {
        key: /%icon_name%/,
        value: item.icon_name
      }
    ]
    return {
      is: isLinkTemplate ? dashify(blokTemplateClone.component) : 'a',
      ...(isLinkTemplate && { blok: { ...replacePlaceholder(blokTemplateClone, placeholder), link: item.link } }),
      ...(!isLinkTemplate && { href: '#' })
    }
  }

  const generateItemAttributes = (
    item: any,
    item_style: StoryblokComponent<any>[] | StoryblokComponent<any>,
    placeholderPrefix = 'item'
  ) => {
    item_style = Array.isArray(item_style) ? item_style[0] : item_style

    if (item_style) {
      const blokTemplateClone = stringToJson(JSON.stringify(item_style))
      const placeholder: any[] = []

      Object.entries(item).forEach((object) => {
        const [key, value] = object

        if (value && typeof value === 'object' && !Array.isArray(value)) {
          Object.entries(value).forEach((object2) => {
            const [key2, value2] = object2
            placeholder.push({
              key: new RegExp(`%${placeholderPrefix}_${key}_${key2}(?:\\|(?:${getPlaceholderPipeRegexKeyChain()}))?%`),
              value: value2
            })
          })
        }

        if (!Array.isArray(value)) {
          if (value && typeof value === 'object') {
            placeholder.push({
              key: new RegExp(`%${placeholderPrefix}_${key}(?:\\|(?:${getPlaceholderPipeRegexKeyChain()}))?%`),
              value: JSON.stringify(value)
            })
          }

          placeholder.push({
            key: new RegExp(`%${placeholderPrefix}_${key}(?:\\|(?:${getPlaceholderPipeRegexKeyChain()}))?%`),
            value
          })
        }
      })

      if (nuxtContext.isDev) {
        // console.info('Placeholder:', placeholder)
      }

      return {
        is: dashify(blokTemplateClone.component),
        ...{ blok: { ...replacePlaceholder(blokTemplateClone, placeholder) } }
      }
    }
    return {}
  }

  const generateBlogAttributes = (item: any, blok_style: StoryblokComponent<any>[]) => {
    const isBlokTemplate = blok_style && blok_style[0].component
    const blokTemplateClone = blok_style && blok_style[0] ? stringToJson(JSON.stringify(blok_style[0])) : null

    const placeholder = [
      {
        key: /%blog_title%/,
        value: item.title
      },
      {
        key: /%blog_short_text%/,
        value: item.short_text
      },
      {
        component: 'image',
        property: 'source',
        value: item.image
      }
    ]
    return {
      is: isBlokTemplate ? dashify(blokTemplateClone.component) : 'div',
      ...(isBlokTemplate && { blok: { ...replacePlaceholder(blokTemplateClone, placeholder) } })
    }
  }

  return {
    generateNavItemAttributes,
    generateBlogAttributes,
    generateItemAttributes
  }
}
