<template>
  <div v-editable="blok" :class="classes" class="py-6">
    <client-only>
      <carousel-3d
        :autoplay="true"
        :autoplay-timeout="5000"
        :width="300"
        :height="400"
        :border="0"
        class="transform hover:scale-105 duration-500"
      >
        <slide v-for="(blokChild, index) in blok.slides" :key="blokChild._uid" class="cursor-pointer" :index="index">
          <img
            :src="blokChild.content[0].source.filename"
            class="object-cover w-full h-full rounded-lg"
            @dblclick="imgClickRedirect(blokChild.link.cached_url)"
          />
        </slide>
      </carousel-3d>
    </client-only>
  </div>
</template>

<script>
import { defineComponent, useRouter } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'

export default defineComponent({
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)
    const router = useRouter()

    const imgClickRedirect = (url) => {
      router.push('/' + url)
    }

    return {
      classes,
      dashify,
      imgClickRedirect
    }
  }
})
</script>
