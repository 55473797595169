
















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent, StoryblokAsset } from '~/types/storyblok-vue'

export interface VideoBackground extends StoryblokComponent<'video-background'> {
  custom_classes: string
  overlay: string
  src: StoryblokAsset
  altImage: StoryblokAsset
  withSound: boolean
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<VideoBackground>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)

    const attr = {
      ...(classes && { class: classes })
    }

    return {
      attr,
      dashify
    }
  }
})
