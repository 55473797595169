



















import { computed, useContext, defineComponent, onMounted, PropType, Ref, ref } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaProgressBarEffect extends StoryblokComponent<'progress-bar-effect'> {
  custom_classes: string
  content: StoryblokComponent<any>[]
  milliseconds_to_load: number
  stuttering: number
  disable_event: string
  enable_event: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaProgressBarEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const nuxtContext = useContext()
    const classes = concatClasses(props.blok)
    const milliSecondsToLoad: number = props.blok.milliseconds_to_load
    const start: Ref<number> = ref(-1)
    const stuttering = props.blok.stuttering

    const millisecondsGone: Ref<number> = ref(0)
    const _progress = ref(0)

    const progress = computed(() => {
      const p = millisecondsGone.value >= milliSecondsToLoad ? 1 : millisecondsGone.value / milliSecondsToLoad

      _progress.value = Math.min(Math.max(Math.random() * stuttering + p, _progress.value), 1)

      return _progress.value
    })
    const displayProgress = computed(() => Math.floor(progress.value * 100) + '%')
    const loaded = computed(() => millisecondsGone.value >= milliSecondsToLoad)

    onMounted(() => {
      start.value = Date.now()
      if (props.blok.disable_event) {
        nuxtContext.$bus.emit(props.blok.disable_event, false)
      }

      const progressStep = function () {
        const now = Date.now()
        millisecondsGone.value = start.value >= now ? 0 : now - start.value
        if (!loaded.value) {
          window.requestAnimationFrame(progressStep)
        } else if (props.blok.enable_event) {
          nuxtContext.$bus.emit(props.blok.enable_event, true)
        }
      }

      window.requestAnimationFrame(progressStep)
    })

    return {
      classes,
      dashify,
      progress,
      displayProgress,
      loaded,
      milliSecondsToLoad,
      millisecondsGone,
      start
    }
  }
})
