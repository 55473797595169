


























import { defineComponent, PropType } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import GameRank from '~/components/gameRank/GameRank.vue'

export interface GaSkillCorridor extends StoryblokComponent<'SkillCorridor'> {
  custom_classes: string
  inject_game_ranks_name: string
  inject_game_rank_systems_name: string
  game_rank_system_id: string
  inject_game_account_name: string
  rank_start_id_property_path: string
  rank_end_id_property_path: string
}

export default defineComponent({
  components: { GameRank },
  props: {
    blok: {
      type: Object as PropType<GaSkillCorridor>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses } = helper()
    const classes = [...concatClasses(props.blok), ...['flex', 'flex-row']]

    return {
      classes
    }
  }
})
