











import { computed, defineComponent, inject, PropType, unref } from '@nuxtjs/composition-api'
import get from 'get-value'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useTemplates from '~/utils/useTemplates'

export interface GaStyleConditionEffect extends StoryblokComponent<'StyleConditionEffect'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  classes_true: string
  classes_false: string
  compare_to_value: string
  compare_operator: string
  item_name: string
  item_prop: string
  use_item_index: boolean
  second_index_name: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaStyleConditionEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify, checkConditionEffect } = useHelper()
    const { generateItemAttributes } = useTemplates()
    const classes = concatClasses(props.blok)
    const item = inject(props.blok.item_name ? props.blok.item_name : 'item', {})
    const itemIndex = inject(props.blok.item_name ? props.blok.item_name + '_index' : 'item_index', '0')
    const itemIndex2 = inject(
      props.blok.second_index_name ? props.blok.second_index_name + '_index' : 'item2_index',
      '0'
    )
    const getProp = props.blok.use_item_index ? itemIndex + '.' + props.blok.item_prop : props.blok.item_prop
    const valueToCheck = get(unref(item), getProp.replace('{index}', itemIndex2))

    const condition = computed(() => {
      return checkConditionEffect(valueToCheck, props.blok.compare_operator, props.blok.compare_to_value)
    })

    const generateRepeatableItemAttributes = (item_style: any) => {
      return generateItemAttributes(
        {
          value: valueToCheck
        },
        item_style,
        'check'
      )
    }

    return {
      classes,
      dashify,
      condition,
      generateRepeatableItemAttributes
    }
  }
})
