import { Model } from '@vuex-orm/core'
import SubscriptionPlanFeature from '~/models/SubscriptionPlanFeature'

export default class SubscriptionPlan extends Model {
  static entity = 'subscriptionPlans'

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      description: this.string(''),
      tier: this.number(1),
      price: this.number(0),
      currency: this.string('EUR'),
      trial_period: this.number(1),
      trial_interval: this.string('day'),
      invoice_period: this.number(1),
      invoice_interval: this.string('month'),
      sort_order: this.number(0),
      sku: this.string(''),
      features: this.hasMany(SubscriptionPlanFeature, 'plan_id')
    }
  }

  id!: number
  name!: string
  description!: string
  tier!: number
  price!: number
  sort_order!: number
  currency!: string
  invoice_period!: number
  invoice_interval!: string
  trial_period!: number
  trial_interval!: string
  sku!: string
  features!: SubscriptionPlanFeature[]
}
