import { Model } from '@vuex-orm/core'
import SubscriptionUsage from "~/models/SubscriptionUsage";
import Subscription from "~/models/Subscription";
import SubscriptionPlanFeature from "~/models/SubscriptionPlanFeature";

// This is a subscription feature which is a copy of an original PlanFeature
export default class SubscriptionFeature extends Model {
  static entity = 'subscriptionFeatures'

  static fields() {
    return {
      id: this.uid(),
      plan_subscription_id: this.attr(null),
      plan_feature_id: this.attr(null),
      name: this.string(''),
      tag: this.string(''),
      value: this.string('false'),
      description: this.string(''),
      usage: this.hasOne(SubscriptionUsage, 'plan_subscription_feature_id'),
      subscription: this.belongsTo(Subscription, 'plan_subscription_id'),
      feature: this.belongsTo(SubscriptionPlanFeature, 'plan_feature_id')
    }
  }

  id!: number
  name!: string
  tag!: string
  value!: string
  description!: string
  usage!: SubscriptionUsage
  subscription!: Subscription
  feature!: SubscriptionPlanFeature
}
