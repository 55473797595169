






































import { defineComponent, PropType, ref, inject, set, useContext } from '@nuxtjs/composition-api'
import storyblok, { DatasourceEntry } from '@/utils/storyblok'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaSkillTags extends StoryblokComponent<'Skilltags'> {
  type: 'weaknesses' | 'strengths' | 'blindspots'
  tags: string
  custom_classes: string
  max: number
  editable: boolean
  game_name: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaSkillTags>,
      default: () => {}
    }
  },
  setup(props, context) {
    const { concatClasses, parseSkillTags, serializeSkillTags } = helper()
    const { getDatasource } = storyblok()
    const classes = [...concatClasses(props.blok), ...['flex', 'flex-row', 'flex-wrap']]
    const max = props.blok.max || 3

    const placeholder = { dimension_value: 'Add tag', selectable: false }

    const newTag = ref(placeholder)
    const dataModel = inject('dataModel', {})

    const tagsList = ref([])

    const options = ref([])

    const nuxtContext = useContext()

    const dimension = nuxtContext.i18n.locale

    const mapId = (tag) => tag.name

    // get available skill tags from CMS
    props.blok.game_name.split(',').forEach((gN) => {
      getDatasource(`skilltags_${gN}`, dimension).then((skilltags: Array<DatasourceEntry>) => {
        options.value = [
          ...options.value,
          ...skilltags.map((tag) => {
            // set dimension_value to default if no specific dimension
            tag.dimension_value = tag.dimension_value || tag.value
            return tag as DatasourceEntry
          })
        ]

        const data = props.blok.tags || dataModel[props.blok.type]

        // map data model prop to tag objects in options
        if (data && options.value.length > 0) {
          tagsList.value = [...tagsList.value, ...parseSkillTags(data, options.value, true)]
        }
      })
    })

    const handleInput = (data: string) => {
      if (dataModel && props.blok.type) {
        set(dataModel, props.blok.type, serializeSkillTags(tagsList.value))
      }
      context.emit('input', data)
    }

    return {
      classes,
      tagsList,
      newTag,
      max,
      options,
      debug: (msg: any) => {
        console.log(msg)
      },
      removeTag: (idx: number) => {
        if (tagsList.value.length > idx) {
          tagsList.value.splice(idx, 1)
        }
      },
      addTag: (tag: object) => {
        newTag.value = placeholder
        if (tagsList.value.length < max && !tagsList.value.map((t) => mapId(t)).includes(mapId(tag))) {
          tagsList.value.push(tag)
        }
      },
      handleInput
    }
  }
})
