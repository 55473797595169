





























import { defineComponent, computed, inject, useContext, PropType, ref, onMounted } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useStoryblok from '~/utils/storyblok'

export interface GaSteps extends StoryblokComponent<'steps'> {
  custom_classes: string
  steps: string[]
  add_step_id_as_prefix_to_models: boolean
  model_prefix: string
  allow_going_to_previous_step: boolean
  button_next_title: string
  button_previous_title: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaSteps>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify, stringToJson } = helper()
    const nuxtContext = useContext()
    const { getStory } = useStoryblok()
    const form = inject('form', {})
    const classes = [...concatClasses(props.blok), ...['flex', 'flex-col']]
    const activeStepId = ref(-1)
    const hasNextStep = ref(true)
    const activeStep = ref(null)
    const buttonsEnabled = ref(true)
    let lastStepDirection = 1

    const validate = (toggle) => {
      $nuxt.$nextTick(async () => {
        if (form.value) {
          const valid = await form.value.validate()
          buttonsEnabled.value = toggle && valid
        } else {
          buttonsEnabled.value = toggle
        }
      })
    }

    const skipStep = () => {
      loadStep(activeStepId.value, lastStepDirection)
    }

    const addModelPrefix = (content) => {
      if (props.blok.add_step_id_as_prefix_to_models) {
        const contentClone = stringToJson(JSON.stringify(content))
        const modelPrefix = props.blok.model_prefix || ''

        if (contentClone.content) {
          contentClone.content.forEach((value, index) => {
            Object.keys(value).forEach((key) => {
              const keyVar = contentClone.content[index][key]
              if (typeof keyVar === 'string' || keyVar instanceof String) {
                contentClone.content[index][key] = keyVar.replace(
                  '{step_model_path}',
                  modelPrefix.replace('{id}', activeStepId.value.toString())
                )
              }

              if (typeof keyVar === 'object' || keyVar instanceof Object) {
                contentClone.content[index] = addModelPrefix(value)
              }
            })
          })
        }

        return contentClone
      }
      return content
    }

    const loadStep = (stepId, nextStepDirection) => {
      const nextStepId = stepId + nextStepDirection
      lastStepDirection = nextStepDirection

      if (nextStepId < props.blok.steps.length) {
        getStory({
          context: nuxtContext,
          path: props.blok.steps[nextStepId],
          isUuid: true
        }).then((story) => {
          activeStepId.value = nextStepId
          activeStep.value = addModelPrefix(story.content)
          hasNextStep.value = nextStepId + 1 < props.blok.steps.length
          validate(true)
        })
      }
    }

    const nextStep = () => {
      if (!buttonsEnabled.value) {
        return
      }

      loadStep(activeStepId.value, 1)
    }

    const previousStep = () => {
      if (!buttonsEnabled.value) {
        return
      }

      loadStep(activeStepId.value, -1)
    }

    onMounted(() => {
      loadStep(activeStepId.value, 1)
    })

    nuxtContext.$bus.on('input', () => {
      validate(true)
    })

    nuxtContext.$bus.off('steps_buttons_toggle')
    nuxtContext.$bus.on('steps_buttons_toggle', (toggle) => {
      validate(toggle)
    })

    const primaryButtonStyle = computed(() => {
      return (
        'select-none inline-flex px-4 py-2 border border-transparent transition-all text-base font-medium rounded-md shadow-sm text-white cursor-pointer flex-grow justify-center ' +
        (buttonsEnabled.value ? 'bg-primary-500 hover:bg-primary-300' : 'bg-gray-500 hover:bg-gray-600')
      )
    })

    return {
      dashify,
      classes,
      activeStep,
      previousStep,
      nextStep,
      skipStep,
      hasNextStep,
      activeStepId,
      stepCount: props.blok.steps.length,
      buttonsEnabled,
      primaryButtonStyle
    }
  }
})
