


























































































































import {
  defineComponent,
  PropType,
  ref,
  useContext,
  computed,
  reactive,
  onMounted,
  inject
} from '@nuxtjs/composition-api'
import { toRefs } from '@vueuse/core'
import { formatDate } from '@/utils/date'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface Schedule extends StoryblokComponent<'schedule'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
}

export default defineComponent({
  components: {},
  props: {
    blok: {
      type: Object as PropType<Schedule>,
      default: () => {}
    }
  },
  setup() {
    const { $dayjs } = useContext()
    const userData = inject('userData')
    const recurring = ref(false)
    const form = reactive({
      recurring: false,
      oneOnone: false,
      group: false,
      topic: null,
      skill: null
    })
    const selectedTimezone = ref('')
    const date = ref(new Date())
    const selectedDay = ref(null)
    const timezoneIndex = ref(0)
    const timezones = [
      'Pacific/Niue', // -11
      'US/Hawaii', // -10
      'America/Anchorage', // -9
      'America/Los_Angeles', // -8
      'America/Boise', // -7
      'America/Chicago', // -6
      'America/New_York', // -5
      'America/Aruba', // -4
      'America/Buenos_Aires', // -3
      'Brazil/DeNoronha', // -2,
      'Atlantic/Azores', // -1
      'UTC', // 0
      'Europe/Berlin', // +1
      'Europe/Athens', // +2
      'Europe/Moscow', // +3
      'Indian/Mahe', // +4
      'Asia/Ashgabat', // +5
      'Asia/Dhaka', // +6
      'Asia/Bangkok', // +7
      'Asia/Hong_Kong', // +8
      'Asia/Pyongyang', // +9
      'Australia/Sydney', // +10
      'Asia/Magadan' // +11
    ]

    const timezone = computed(() => timezones[timezoneIndex.value])
    const isGroupCoaching = computed(() => coaching.value.includes('group'))
    const coaching = ref([])

    function dayClicked(event) {
      date.value = event.date
      selectedDay.value = $dayjs(event.date).format('YYYY-MM-DD')
      /*
      const day = {
        minDate: selectedDay.value,
        maxDate: $dayjs(event.date).add(1, 'd').format('YYYY-MM-DD')
      }
      */
    }
    function selectTimeZone(index) {
      timezoneIndex.value = index
      selectedTimezone.value = timezones[index]
    }
    function setCoaching(e) {
      coaching.value = [e.target.value]
    }
    onMounted(() => {
      const index = timezones.indexOf(userData.value.timezone)
      selectTimeZone(index)
    })
    return {
      dayClicked,
      selectedDay,
      formatDate,
      timezone,
      timezones,
      timezoneIndex,
      selectTimeZone,
      date,
      recurring,
      selectedTimezone,
      isGroupCoaching,
      setCoaching,
      coaching,
      ...toRefs(form)
    }
  }
})
