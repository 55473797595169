




















import { defineComponent, inject, PropType, ref } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaTable extends StoryblokComponent<'table-item'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaTable>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const table = ref(props.blok.content)

    const tableContent = inject('tableContent', table)

    const getEvenOddClass = (index) => {
      if (!(props.blok.tbody_tr_even_style && props.blok.tbody_tr_odd_style)) {
        return ''
      }
      if (index % 2 === 0) {
        return props.blok.tbody_tr_odd_style
      } else {
        return props.blok.tbody_tr_even_style
      }
    }

    return {
      classes,
      dashify,
      getEvenOddClass,
      tableContent
    }
  }
})
