











import { defineComponent, PropType } from '@nuxtjs/composition-api'
import helper from '~/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaListItem extends StoryblokComponent<'list-item'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaListItem>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)

    return {
      classes,
      dashify
    }
  }
})
