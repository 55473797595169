import { Model } from '@vuex-orm/core'
import User from '~/models/User'
import Product from '~/models/Product'

export default class StoreCredit extends Model {
  static entity = 'store_credits'

  static fields() {
    return {
      id: this.uid(),
      order_token: this.string(''),
      product_id: this.number(0),
      product: this.belongsTo(Product, 'product_id'),
      user: this.belongsTo(User, 'user_id'),
      usage: this.boolean(false),
      product_tag: this.string('')
    }
  }

  id!: number
  order_token!: string
  product!: Product
  user!: User
  usage!: boolean
  product_tag!: string
}
