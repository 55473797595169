







import { defineComponent } from '@nuxtjs/composition-api'
import FormLabel from '~/components/FormLabel/FormLabel'

export default defineComponent({
  components: {
    FormLabel
  },
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  }
})
