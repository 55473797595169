import { Model } from '@vuex-orm/core';
import GameRankProgress from '~/models/GameRankProgress';

export default class GameAccount extends Model {
  static entity = 'gameAccounts'

  static fields() {
    return {
      id: this.uid(),
      updated_at: this.attr(null),
      user_id: this.attr(null),
      game_id: this.attr(null),
      title: this.attr(null),
      squad_link: this.attr(null),
      weaknesses: this.attr(null),
      strengths: this.attr(null),
      blindspots: this.attr(null),
      recommendation_text: this.attr(null),
      recommendation_last_modified: this.attr(null),
      coach_notes: this.attr(null),
      game_rank_progress: this.hasMany(GameRankProgress, 'game_account_id'),
      game_name: this.attr(null),
    }
  }

  id!:number
  updated_at!: string | null
  user_id!: number | null
  game_id!: number | null
  title!: string | null
  squad_link!: string | null
  weaknesses!: string | null
  strengths!: string | null
  blindspots!: string | null
  recommendation_text!: string | null
  recommendation_last_modified!: string | null
  coach_notes!: string | null
  game_name!: string | null

}
