var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"\n    bg-transperent\n    flex\n    text-sm\n    rounded-full\n    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-500 focus:ring-white\n  ",on:{"click":function($event){return _vm.switchColor()}}},[_c('blok-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentColor === 'dark'),expression:"currentColor === 'dark'"}],attrs:{"blok":{
      custom_classes: 'fa-2x text-gray-500 hover:text-gray-200',
      icon_type: 'fas',
      icon_name: 'moon'
    }}}),_vm._v(" "),_c('blok-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentColor === 'light'),expression:"currentColor === 'light'"}],attrs:{"blok":{
      custom_classes: 'fa-2x text-gray-500 hover:text-gray-200',
      icon_type: 'fas',
      icon_name: 'sun'
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }