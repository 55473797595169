import { useContext } from '@nuxtjs/composition-api'

export function useTracking() {
  const nuxtContext = useContext()
  const triggerEvent = (events = []) => {
    if (events) {
      events.forEach((event: any) => {
        switch (event.component) {
          case 'track-event': {
            if (event.system === 'matomo') {
              console.log(['trackEvent', event.category, event.action, event.name])
              if (event.value) {
                window._paq.push(['trackEvent', event.category, event.action, event.name, event.value])
              } else {
                window._paq.push(['trackEvent', event.category, event.action, event.name])
              }
            }
            if (event.system === 'google' && typeof window.ga === 'function') {
              if (event.value) {
                window.ga(['send', 'event', event.category, event.action, event.name, event.value])
              } else {
                window.ga(['send', 'event', event.category, event.action, event.name])
              }
            }
            break
          }

          case 'track-facebook-event': {
            if (event.options) {
              nuxtContext.$fb.query('track', event.category, event.options)
            } else {
              nuxtContext.$fb.query('track', event.category)
            }
            break
          }
        }
      })
    }
  }

  return {
    triggerEvent
  }
}
