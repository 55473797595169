



















































































































import { defineComponent, useMeta, useContext, ref, watch, computed } from '@nuxtjs/composition-api'
import { useDebounce } from '@vueuse/core'
import useStoryblok from '@/utils/storyblok'
import useHelper from '@/utils/helper'
import VideoItem from '~/components/video/VideoItem.vue'

export default defineComponent({
  name: 'GaVideoCategoryPage',
  components: { VideoItem },
  props: {
    blok: {
      type: Object,
      default: () => {}
    },
    uuid: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { dashify, concatClasses } = useHelper()
    const { getPagedStories } = useStoryblok()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const lang = nuxtContext.i18n.locale
    const isLoading = ref(false)
    const searchPlaceholder = ref(lang === 'en' ? 'Search tutorials by title' : 'Tutorials nach Titel suchen')

    const { title } = useMeta()
    if (props.blok.seo && props.blok.seo.title) {
      title.value = props.blok.seo.title
    }

    const searchTerm = ref('')
    const perPage = ref(12)
    const currentPage = ref(1)
    const totalVideos = ref(0)
    const showPager = ref(false)

    const videosFilter = ref({
      per_page: perPage.value,
      page: currentPage.value,
      by_slugs: `${nuxtContext.i18n.locale}/games/fifa/videos/*`,
      resolve_relations: 'video-page.categories',
      filter_query: {
        categories: {
          in_array: props.uuid
        }
      }
    })
    const debouncedFilter = useDebounce(videosFilter, 200)
    watch(debouncedFilter, () => {
      videos.value = []

      getBlogs().then((result) => {
        videos.value = result
      })
    })

    const getBlogs = async () => {
      isLoading.value = true

      if (process.client) {
        return await getPagedStories(nuxtContext, videosFilter.value).then((result) => {
          isLoading.value = false
          totalVideos.value = result.total

          return result.stories
        })
      }
      return []
    }

    const videos = ref([])
    getBlogs().then((result) => {
      videos.value = result
    })

    const updateFilter = () => {
      currentPage.value = 1

      if (searchTerm.length === '') {
        videosFilter.value = {
          ...videosFilter.value,
          page: currentPage.value,
          filter_query: {
            categories: {
              ...videosFilter.value.filter_query.category
            }
          }
        }
      } else {
        videosFilter.value = {
          ...videosFilter.value,
          page: currentPage.value,
          filter_query: {
            ...videosFilter.value.filter_query,
            title: {
              like: `*${searchTerm.value}*`
            }
          }
        }
      }
    }

    const setPage = (pageNumber) => {
      currentPage.value = pageNumber

      videosFilter.value = {
        ...videosFilter.value,
        page: currentPage.value
      }
    }

    const isCurrentPage = (linkPage) => {
      return linkPage === currentPage.value
    }

    watch(totalVideos, () => {
      showPager.value = totalVideos.value > perPage.value
    })

    const getPages = computed(() => {
      const numberOfPages =
        totalVideos.value % perPage.value === 0
          ? totalVideos.value / perPage.value
          : totalVideos.value / perPage.value + 1
      return Array.from({ length: numberOfPages }, (_, i) => i + 1)
    })

    return {
      videos,
      classes,
      dashify,
      isLoading,
      showPager,
      getPages,
      searchPlaceholder,
      searchTerm,
      setPage,
      isCurrentPage,
      updateFilter
    }
  },
  head: {}
})
