var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],ref:"textarea",attrs:{"slim":"","name":_vm.blok.name,"rules":_vm.blok.validation_rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('div',{class:_vm.classes},[_c('FormLabelWrapper',{class:['relative' ].concat( _vm.classes),attrs:{"blok":{
        validated: !_vm.blok.disable_validation_display && validated,
        invalid: !_vm.blok.disable_validation_display && invalid,
        errors: errors,
        text: _vm.blok.label,
        icon: _vm.blok.extra_field_icon,
        isFocused: _vm.isFocused
      }}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue),expression:"internalValue"}],staticClass:"\n          relative\n          block\n          px-4\n          pt-3\n          pb-1\n          w-full\n          text-lg\n          appearance-none\n          focus:outline-none\n          rounded\n          bg-white\n          leading-4\n        ",class:[
          {
            'border-error': !_vm.blok.disable_validation_display && validated && invalid,
            'border-success': !_vm.blok.disable_validation_display && validated && !invalid,
            'border-secondary-500': !validated && _vm.isFocused,
            'shadow-outline-error': !_vm.blok.disable_validation_display && validated && invalid && _vm.isFocused,
            'shadow-outline-success': !_vm.blok.disable_validation_display && validated && !invalid && _vm.isFocused
          },
          _vm.classes
        ],attrs:{"id":_vm.blok.name + '-' + _vm.blok._uid,"name":_vm.blok.name,"placeholder":_vm.blok.placeholder,"rows":_vm.blok.rows,"cols":_vm.blok.cols},domProps:{"value":(_vm.internalValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.internalValue=$event.target.value},function($event){return _vm.handleInput('textarea', $event)}],"focus":_vm.focus,"blur":_vm.blur}})])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }