












import { defineComponent, inject, PropType } from '@nuxtjs/composition-api'
import helper from '~/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface SimpleText extends StoryblokComponent<'simple-text'> {
  content: StoryblokComponent<any>[]
  text: string
  id: string
  custom_classes: string
  type: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'hr' | 'span'
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<SimpleText>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)
    const ariaLabelId = inject('ariaLabelId', null)

    const attr = {
      is: props.blok.type,
      ...(classes && { class: classes }),
      ...(ariaLabelId && props.blok.type === 'h2' && { id: ariaLabelId }),
      ...(props.blok.id && (props.blok.type === 'h2' || props.blok.type === 'h3') && { id: props.blok.id })
    }

    return {
      classes,
      dashify,
      attr
    }
  }
})
