


















import { defineComponent, useContext } from '@nuxtjs/composition-api'
import DropdownSelect from '~/components/calendar/Filter/Dropdown.vue'

export default defineComponent({
  components: { DropdownSelect },
  setup() {
    const nuxtContext = useContext()
    const lang = nuxtContext.i18n.locale

    return {
      lang
    }
  }
})
