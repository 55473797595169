































import { defineComponent, watch, ref, PropType, computed, useContext, provide } from '@nuxtjs/composition-api'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useHelper from '~/utils/helper'

export interface GaRadioItem extends StoryblokComponent<'radio-item'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  name: string
  label: string
  validation_rules: string
  value: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaRadioItem>,
      default: () => {}
    },
    value: {
      type: [String, Number],
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, context) {
    const { dashify, concatClasses } = useHelper()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const internalValue = ref(props.value)
    const isFocused = ref(false)
    const active = computed(() => internalValue.value === props.blok.value)

    const focus = () => {
      isFocused.value = true
    }
    const blur = () => {
      isFocused.value = false
    }
    const handleInput = (value) => {
      context.emit('input', value)
      nuxtContext.$bus.emit('input')
    }
    if (props.item) {
      provide('item', props.item)
    }

    watch(
      () => props.value,
      (newValue) => {
        internalValue.value = newValue
      }
    )

    return {
      dashify,
      classes,
      internalValue,
      handleInput,
      isFocused,
      focus,
      blur,
      active
    }
  }
})
