


































































import { defineComponent, ref, useContext, useAsync } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import vimeoJson from '@/vimeo-videos.json'

export default defineComponent({
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses } = helper()
    const classes = concatClasses(props.blok)
    const isOpen = ref(false)
    const isPlay = ref(false)
    const player = ref(null)
    const nuxtContext = useContext()
    // const isStoryblokEdit = inject('storyblokEdit', ref(false))
    // const videoJson = ref(false)
    const toggleModal = () => {
      if (props.blok.no_full_screen) {
        isPlay.value = !isPlay.value
        player.value.play()
      } else isOpen.value = !isOpen.value
    }
    const videoJson = useAsync(async () => {
      if (props.blok.vimeo && props.blok.vimeo.vimeo_raw) {
        if (nuxtContext.payload && nuxtContext.payload.videoJsons) {
          const video = nuxtContext.payload.videoJsons.find(
            (video: any) => props.blok.vimeo.vimeo_raw === video.key
          )?.value
          if (video) {
            return video
          }
        }
        const meta = vimeoJson.find((item: any) => item.key === props.blok.vimeo.vimeo_raw)?.value
        if (meta) {
          return meta
        }

        return props.blok.vimeo.vimeo_oembed
          ? await nuxtContext.$axios.$get(props.blok.vimeo.vimeo_oembed.url).catch((error) => {
              console.error(props.blok, error)
            })
          : {
              thumbnail_url: '',
              thumbnail_width: 640,
              thumbnail_height: 320,
              title: 'GA'
            }
      }
      return {
        thumbnail_url: '',
        thumbnail_width: 640,
        thumbnail_height: 320,
        title: 'GA'
      }
    }, props.blok.vimeo?.vimeo_raw.replace('https://', '').replace(/\//g, '') || 'emptyVimeo')

    const videoJsonBackup = props.blok.vimeo
      ? vimeoJson.find((item: any) => item.key === props.blok.vimeo.vimeo_raw)?.value
      : {
          thumbnail_url: '',
          thumbnail_width: 640,
          thumbnail_height: 320,
          title: 'GA'
        }

    // useFetch(async () => {
    //   if (nuxtContext.payload && nuxtContext.payload.videoJsons) {
    //     const video = nuxtContext.payload.videoJsons.find(
    //       (video: any) => props.blok.vimeo.vimeo_raw === video.key
    //     )?.value
    //     if (video) {
    //       videoJson.value = video
    //     }
    //   }
    //   if (props.blok.vimeo && props.blok.vimeo.vimeo_oembed && props.blok.vimeo.vimeo_oembed.url) {
    //     videoJson.value = await nuxtContext.$axios.$get(props.blok.vimeo.vimeo_oembed.url)
    //   }
    // })

    return {
      classes,
      isOpen,
      toggleModal,
      videoJson,
      videoJsonBackup,
      player,
      isPlay
    }
  }
})
