import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { useCookies } from '@vueuse/integrations'

export default defineNuxtPlugin((ctx) => {
  if (!ctx.isDev && ctx.route.fullPath === '/') {
    const cookies = useCookies(['i18n_redirected'])
    const cookieLocale = cookies.get('i18n_redirected')
    if (!cookieLocale) {
      cookies.set('i18n_redirected', ctx.i18n.getBrowserLocale() || 'en')
    }
    ctx.i18n.setLocale(cookieLocale || ctx.i18n.getBrowserLocale())
  }
  ctx.$dayjs.locale(ctx.i18n.locale)
  ctx.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
    ctx.$dayjs.locale(newLocale)
    if (ctx.$auth.loggedIn) {
      // ctx.$axios.$put('api/user/language', { language: newLocale })
    }
  }
})
