






import { defineComponent, inject, ref } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'

export default defineComponent({
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const { dashify, replacePlaceholder, concatClasses, stringToJson } = useHelper()
    const injectItemsName = props.blok.inject_items_name
    const dropdownItems = inject(injectItemsName || 'dropdownItems', ref([]))
    const classes = concatClasses(props.blok)
    const link_style = inject('linkStyle', props.blok.link_style)

    const generateItemAttributes = (item) => {
      const isLinkTemplate = link_style && link_style[0].component === 'link'
      const linkTemplateClone = stringToJson(JSON.stringify(link_style[0]))
      const placeholder = [
        {
          key: /%link_text%/,
          value: item.label
        },
        {
          key: /%icon_type%/,
          value: item.icon_type
        },
        {
          key: /%icon_name%/,
          value: item.icon_name
        }
      ]
      return {
        is: isLinkTemplate ? dashify(linkTemplateClone.component) : 'a',
        ...(isLinkTemplate && {
          blok: { ...replacePlaceholder(linkTemplateClone, placeholder), link: item.link, action: item.action }
        }),
        ...(!isLinkTemplate && { href: '#' })
      }
    }

    return {
      classes,
      generateItemAttributes,
      dropdownItems
    }
  }
})
