import { Model } from '@vuex-orm/core'

export default class SubscriptionUsage extends Model {
  static entity = 'subscriptionUsages'

  static fields() {
    return {
      id: this.uid(),
      plan_subscription_feature_id: this.attr(null),
      subscription_id: this.attr(null),
      used: this.number(0)
    }
  }

  used!: number
}
