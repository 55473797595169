import { defineNuxtMiddleware } from '@nuxtjs/composition-api'

export default defineNuxtMiddleware(ctx => {
  // Fix trailing slash issue with root locale pages
  const locales = ctx.i18n.locales.map((locale: any) => '/' + locale.code) || []

  if (locales.includes(ctx.route.fullPath)) {
    ctx.redirect(ctx.route.fullPath + '/')
  }
  if (process.server) {
    ctx.i18n.locale = 'en'
    // ctx.i18n.setLocale('en')
    if (/^\/de/.test(ctx.route.fullPath)) {
      ctx.i18n.locale = 'de'
      // ctx.i18n.setLocale('de')
    }
  }
})
