var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"dark:bg-gray-800 bg-gray-50"},[_c('div',{staticClass:"max-w-7xl mx-auto sm:px-6 lg:px-8 py-4"},[_c('div',{staticClass:"shadow-xl sm:rounded-2xl"},[_c('div',{staticClass:"relative sm:rounded-t-2xl sm:overflow-hidden"},[(_vm.blok.cover_image && _vm.blok.cover_image.filename)?_c('div',{staticClass:"absolute inset-0"},[_c('blok-image',{attrs:{"blok":{
              source: _vm.blok.cover_image,
              type: 'nuxt-img',
              custom_classes: 'h-full w-full object-cover',
              smart: true,
              width: 1216,
              height: 536
            }}}),_vm._v(" "),_c('div',{staticClass:"absolute inset-0 bg-gray-800 opacity-50 mix-blend-multiply"})],1):_vm._e(),_vm._v(" "),_c('h1',{staticClass:"\n            relative\n            pt-40\n            pb-5\n            text-center text-4xl\n            font-extrabold\n            tracking-tight\n            sm:text-5xl\n            lg:text-6xl\n            text-white\n          "},[_vm._v("\n          "+_vm._s(_vm.blok.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"relative pb-24 flex justify-center"},_vm._l((_vm.blok.categories),function(category){return _c('blok-link',{key:category._uid,attrs:{"blok":{
              link: {
                linktype: 'url',
                cached_url: _vm.findBySlug(category.full_slug)
              },
              content: [
                {
                  component: 'simple-text',
                  custom_classes:
                    'inline-flex items-center rounded-full py-1 px-3 text-md font-medium text-gray-200 mr-2 border border-white hover:border-secondary-500 hover:bg-secondary-500 hover:text-white',
                  text: category.name,
                  type: 'span'
                }
              ]
            }}})}),1)]),_vm._v(" "),_vm._l((_vm.blok.playlists),function(blokChild){return _c(_vm.dashify(blokChild.component),{key:blokChild._uid,tag:"component",attrs:{"blok":blokChild}})})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }