



























































































































import { defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent, StoryblokLink } from '~/types/storyblok-vue'
import { GaImage } from '~/components/image/Image.vue'

export interface GaBlog extends StoryblokComponent<'blog'> {
  blok_style: StoryblokComponent<any>[]
  image: GaImage
  image_link: StoryblokLink
  custom_classes: string
  title: string
  short_text: string
  categories: [id: string] | StoryblokComponent<any>[]
  author: string | StoryblokComponent<any>
  published_date: string
  game: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaBlog>,
      default: () => {}
    }
  },
  setup(props) {
    const { dashify, concatClasses } = useHelper()
    const { $dayjs, i18n } = useContext()
    const classes = concatClasses(props.blok)

    const infoByGameAndLang = () => {
      const lang = i18n.locale
      const game = props.blok.game
      let coachInfo = {}

      if (game === 'fortnite')
        coachInfo = {
          coach:
            lang === 'de'
              ? {
                  img: 'https://a.storyblok.com/f/112666/481x385/69d26bba0c/coachbox_fortnite.png',
                  name: 'Malte',
                  description: '$70k+ Earnings',
                  get_url:
                    'https://www.gamers-academy.com/de/games/fortnite/pricing-credits?pk_campaign=blog&pk_source=fortnite&pk_medium=media'
                }
              : {
                  img: 'https://a.storyblok.com/f/112666/481x385/69d26bba0c/coachbox_fortnite.png',
                  name: 'Malte',
                  description: '$70k+ Earnings',
                  get_url:
                    'https://www.gamers-academy.com/games/fortnite/pricing-credits?pk_campaign=blog&pk_source=fortnite&pk_medium=media'
                }
        }
      else if (game === 'lol')
        coachInfo = {
          coach:
            lang === 'de'
              ? {
                  img: 'https://a.storyblok.com/f/112666/481x385/69d26bba0c/coachbox_fortnite.png',
                  name: 'Outlandisch',
                  description: 'Analyst für Summoners-Inn',
                  get_url:
                    'https://www.gamers-academy.com/de/games/leagueoflegends/pricing-credits?pk_campaign=blog&pk_source=lol&pk_medium=media'
                }
              : {
                  img: 'https://a.storyblok.com/f/112666/481x385/69d26bba0c/coachbox_fortnite.png',
                  name: 'Ikkine',
                  description: 'Prime League 2021',
                  get_url:
                    'https://www.gamers-academy.com/games/leagueoflegends/pricing-credits?pk_campaign=blog&pk_source=lol&pk_medium=media'
                }
        }
      else
        coachInfo = {
          coach:
            lang === 'de'
              ? {
                  img: 'https://a.storyblok.com/f/112666/481x385/99ffd4382c/coachbox_umut.png',
                  name: 'Umut',
                  description: 'Amtierender Deutscher Meister',
                  get_url:
                    'https://www.gamers-academy.com/de/games/fifa/pricing-credits?pk_campaign=blog&pk_source=fifa&pk_medium=media'
                }
              : {
                  img: 'https://a.storyblok.com/f/112666/481x385/31cb71d920/labelle_gray.png',
                  name: 'Mike Labelle',
                  description: 'Multinational FIFA Champion',
                  get_url:
                    'https://www.gamers-academy.com/games/fifa/pricing-credits?pk_campaign=blog&pk_source=fifa&pk_medium=media'
                }
        }
      coachInfo.coach.get = i18n.locale === 'de' ? 'STARTE DEIN COACHING!' : 'GET YOUR COACHING!'
      return {
        ...coachInfo,
        about:
          lang === 'de'
            ? [
                'ÜBER GAMERS-ACADEMY',
                'Wir haben eine ganz klare Mission – jeden Gamer weltweit schnellstmöglich besser zu machen! Egal, wann und wo. Ob ihr einfach nur mehr Siege einfahren wollt, vor euren Freunden flexen oder sogar Pro-Gamer werden wollt. Dafür entwickeln wir einen KI-basierten Gaming Coach. Wir sind ein Team aus Creatorn, (Pro-)Gamern und Techies. Game On.'
              ]
            : [
                'ABOUT GAMERS-ACADEMY',
                "We have a very clear mission - to make every gamer around the world better as fast as possible! No matter when or where. Whether you just want to get more wins, flex in front of your friends, or even become a pro gamer. For that, we're developing an AI-based gaming coach. We are a team of creators, (pro-)gamers and techies. Game On.'"
              ],
        published: props.blok.published_date ? $dayjs(props.blok.published_date).format('DD.MM.YYYY, H:m') : false,
        author: [
          i18n.locale === 'de' ? 'Autor' : 'Author',
          props.blok.author ? props.blok.author.content.prename + ' ' + props.blok.author.content.lastname : false
        ],
        lang: i18n.locale
      }
    }

    const blok = {
      type: 'blok',
      attrs: {
        id: 'ff6f665b-36fb-40cf-8667-35e6c2d899bc',
        body: [
          {
            _uid: 'i-a5c3081a-4a52-4ea5-8962-7d67a9c0587f',
            content: [
              {
                id: '',
                _uid: '8d80bbf0-04d1-4ecf-96d0-ffb5c9711562',
                text: infoByGameAndLang().about[0],
                type: 'h5',
                content: [],
                component: 'simple-text',
                custom_classes: '!font-extrabold tracking-tight text-primary-500 italic text-center'
              },
              {
                id: '',
                _uid: '643f9795-6ffa-40fd-8dc8-b629b9c5b3a2',
                text: infoByGameAndLang().about[1],
                type: 'p',
                content: [],
                component: 'simple-text',
                custom_classes: 'my-2 sm:text-xl md:!text-sm text-white text-justify'
              }
            ],
            component: 'container',
            active_style: '',
            custom_classes: 'lg:hidden bg-[#262b32] p-3 my-3'
          }
        ]
      }
    }

    const apndObj = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)]
    const textBlok = apndObj(props.blok.text?.content, 4, blok)

    return {
      classes,
      dashify,
      infoByGameAndLang,
      textBlok
    }
  }
})
