











import { defineComponent, PropType, ref, useAsync, useContext } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useStoryblok from '~/utils/storyblok'

export interface TemplateLoaderEffect extends StoryblokComponent<'template-loader-effect'> {
  story_id: string
  custom_classes: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<TemplateLoaderEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const { getStory } = useStoryblok()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()

    const story = props.blok.story_id
      ? useAsync(getStory({ path: props.blok.menu_user, context: nuxtContext, isUuid: true }), props.blok.story_id)
      : ref([])

    return {
      classes,
      dashify,
      story
    }
  }
})
