









import { defineComponent, PropType } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent, StoryblokPluginFontawesomeSelector } from '~/types/storyblok-vue'

export interface Icon extends StoryblokComponent<'BlokIcon'> {
  custom_classes: string
  icon: StoryblokPluginFontawesomeSelector
  icon_type: string
  icon_name: string
}

export default defineComponent({
  name: 'GaIcon',
  props: {
    blok: {
      type: Object as PropType<Icon>,
      classes: String,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, filterFaString } = useHelper()
    const { blok } = props
    const classes = concatClasses(blok)
    const validateIconType = (iconType) => ['fal', 'fas', 'far', 'fab'].includes(iconType)
    const iconArray = (type, name) => [type, filterFaString(name)]
    const attributes = {
      ...(classes && { class: classes }),
      ...(blok.icon && blok.icon.icon && { icon: iconArray(blok.icon.type, blok.icon.icon) }),
      ...(blok.icon_type && validateIconType(blok.icon_type) && { icon: iconArray(blok.icon_type, blok.icon_name) })
    }
    return {
      classes,
      filterFaString,
      validateIconType,
      attributes
    }
  }
})
